html {
    height: 100%;
}

.promotions-page {
    scroll-behavior: smooth;
}

*:focus,
*:active {
    outline: none;
    outline: 0;
}

textarea:focus,
input:focus,
select:focus {
    outline: none;
}

a:active,
a:focus,
a:hover {
    outline: 0;
    border: none;
    -moz-outline-style: none;
}

a,
a:hover {
    border: none;
}

a {
    color: $msv-black;
    outline: 0;
}

.ms-cookie-compliance__accept-button {
    @include primary-btn();
}

.show-reg-btn {
    display: block;
}

.hide-reg-btn {
    display: none;
}

.ms-content-block__cta_hide {
    display: none;
}

.support-sticker-close_btn,
.support-sticker-arrow_btn {
    display: none !important;
}

.back-to-top {
    border: 1px solid $alto !important;
    background-color: $alto !important;
}

.back-to-top:hover {
    border: 0px solid $msv-supernova !important;
    background-color: $msv-supernova !important;
}

.back-to-top .fa-arrow-up:hover:before {
    color: $msv-white !important;
}

.back-to-top .fa-arrow-up:before {
    color: $msv-white;
}

.home_webshop-section,
.home_video-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.home_video-container p,
.home_webshop-section p {
    font-size: 18px !important;
    padding: 10px 0;
}

.hero-banner-box-heading h5 {
    padding-top: 50px;
}

.msc-carousel__control__prev__icon {
    margin-left: 0px;
    margin-top: 12px;
}

.msc-carousel__control__next__icon {
    margin-right: 0px;
    margin-top: 12px;
}

.hero .ms-content-block__details .ms-content-block__text {
    min-width: 300px !important;
    color: $msv-white !important;
}

.hero .ms-content-block__details .msc-cta__primary1 {
    background-color: $msv-supernova !important;
    border: 1px solid $msv-supernova !important;
    color: $msv-white !important;
}

.hero .ms-content-block__details .ms-content-block__text {
    min-width: 350px !important;
}

.ms-quickView__add-to-wishlist-container {
    display: none;
}

.ms-header__signin-button {
    padding-right: 20px !important;
}

.returns-n-refunds,
.ms-nav .ms-nav__list__item .ms-nav__list__item__button::after {
    display: none !important;
}

.ms-header__desktop-view_sticky .desktop-vp {
    max-height: 55px;
    transition: all 0.3s;
}

// sticky header
body {
    & > div {
        & > header {
            position: sticky;
            top: -59px;
            z-index: 100;
            background: $msv-white;
        }
    }
}

.ms-header__desktop-view_sticky {
    display: block !important;
    top: 0;
    left: 0;
    background-color: $msv-white !important;
    z-index: 100;
    transition-timing-function: ease-in-out;
    width: 100%;

    .ms-header {
        .ms-header__logo {
            transition: all 0.5s ease-in-out;
        }
    }
}

.ms-header__desktop-view_sticky {
    .ms-header {
        padding-top: 0px;
    }
}

.ms-search__icon {
    padding-right: 10px !important;
}

.ms-search__form-cancelSearch {
    height: 35px !important;
}

/* Nav */
.level-2 li a {
    font-weight: 600 !important;
}

.ms-nav__list__item .havesubmenu a {
    font-weight: 600 !important;
}

.ms-nav__list.level-2 .ms-nav__list {
    top: 0px !important;
    background-color: $msv-soft-peach;
    height: 100%;
}

.level-2 {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: scroll;
    padding: 20px;
}

.level-2 .ms-nav__list__item {
    max-width: 120px !important;
}

.ms-nav__list .level-2 .ms-nav__list__item__link {
    font-size: 16px !important;
    padding-left: 30px !important;
    padding-right: 10px !important;
    line-height: 15px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.level-2 li {
    display: contents;
    background-color: rgba(255, 255, 255, 0.9) !important;
    float: left;
}

.level-2 li:hover {
    background-color: $msv-supernova !important;
}

.level-2 li .ms-nav__feature {
    background-color: $msv-soft-peach;

    a {
        background-color: $msv-soft-peach;
        border-bottom: solid 1px $msv-light-grey;

        &:hover,
        &:focus {
            background-color: $concrete;
        }
    }
}

.level-2 li {
    &:hover,
    &:focus,
    &:focus-within {
        .ms-nav__feature {
            @media (min-width: $msv-breakpoint-l) {
                opacity: 1;
                height: 100% !important;
            }

            @media (max-width: $msv-breakpoint-l) {
                display: block !important;
            }
        }
    }
}

.level-2 li:hover .ms-nav__feature {
    @media (max-width: $msv-breakpoint-l) {
        display: none !important;
    }
}

.level-2 .ms-nav__list__item__link {
    padding-top: 20px;
    vertical-align: top;
    max-width: 300px;
    padding-bottom: 31px;
    margin-top: 0px !important;
}

.level-2 .ms-nav__list__item__link:hover {
    text-decoration: none;
}

.havesubmenu .ms-nav__feature .ms-nav__list__item__link:after {
    display: none !important;
    content: '' !important;
}

.have-menu,
.submenu-wrapper {
    position: relative;
    .dropdown-arrow {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            @media (max-width: $msv-breakpoint-l) {
                @include msv-icon();
                content: '\f054';
                color: $msv-black;
                cursor: pointer;
                font-size: 16px;
                float: right;
            }
        }
    }
}

.havesubmenu {
    .ms-nav__feature {
        height: 0;
        opacity: 0;
        position: absolute;
        margin-left: 300px;
        background-color: $msv-white;
        margin-top: 0px;
        width: 300px;

        @media (max-width: $msv-breakpoint-l) {
            display: block !important;
        }
    }
    .ms-nav__list__item__link {
        &::after {
            @media (min-width: $msv-breakpoint-l) {
                @include msv-icon();
                content: '\f054';
                color: $msv-black;
                cursor: pointer;
                font-size: 16px;
                float: right;
            }
        }
    }
}

.nav_sub_bak {
    background-color: rgba(255, 255, 255, 0.9) !important;
}

.ms-nav__list__item__button:hover::before,
.ms-nav__list__item__link:hover::before {
    background-color: $msv-supernova !important;
    height: 4px !important;
}

.ms-header__desktop-view_sticky .ms-nav__list__item__button,
.ms-header__desktop-view_sticky .ms-nav__list__item__link {
    font-size: 16px !important;
    word-spacing: 0px;
    transition: all 0.3s;
}

.home_video-textblock {
    text-align: center;
    margin-bottom: 20px;
}

.footer-social-icons {
    display: flex;
    padding-top: 20px;
}

li.footer-social-icons {
    margin: 5px;
}

.social_footer-item {
    display: inline-block;
    padding-right: 40px;
}

.social_footer-item:not(:first-child) {
    padding-left: 40px;
    border-left: 1px solid $msv-supernova;
}

.sticker:hover {
    margin-right: 0px;
    transition: all 0.3s;
}

.temp_hide {
    display: none !important;
}

.winterCollection {
    max-height: 1170px !important;
    overflow: hidden !important;
}

.business_solution_container .ms-content-block__title {
    max-width: 626px !important;
}

.business_solution_container .col-md-4 {
    margin-right: 250px !important;
}

.msc-add-to-wishlist {
    display: none !important;
}

.msc-add-to-wishlist::after {
    content: '' !important;
}

.pdp_buybox .ms-container-background-image {
    background-position: 240px 10px !important;
}

#CardDetailsPanel,
#BillingAddressPanel {
    display: none !important;
}

.Order-template-icon {
    padding-right: 20px;
}

.Order-template-icon:hover {
    text-decoration: none !important;
}

.Order-template-icon:before {
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    content: '\f022';
    color: $msv-smalt-blue;
    cursor: pointer;
    font-size: 18px;
}

.Order-history-icon:before {
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    content: '\f328';
    color: $msv-smalt-blue;
    cursor: pointer;
    font-size: 18px;
}

.ms-header__divider {
    display: none;
}

.ms-header__signin-button-text {
    color: $msv-supernova;
}

a.ms-header__signin-button.msc-btn:before {
    font-family: 'Font Awesome 5 Free';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    content: '\f007';
    color: $msv-supernova;
    cursor: pointer;
    font-size: 18px;
    margin-right: 5px;
}

.krannichtextblock_title {
    margin-bottom: 25px;
}

.msc-active-image-canvas {
    max-height: 700px !important;
}

li.ms-nav__list__item {
    &:hover,
    &:focus {
        .nav-holder {
            transform: unset !important;
        }
    }

    .nav-holder {
        @media (max-width: $msv-breakpoint-l) {
            display: none !important;
        }

        &:focus,
        &:focus-within {
            @media (min-width: $msv-breakpoint-l) {
                transform: unset !important;
            }

            @media (max-width: $msv-breakpoint-l) {
                display: none !important;
            }
        }
    }
}

.nav-holder {
    @media (min-width: $msv-breakpoint-l) {
        transform: translateX(-1000px) !important;
    }
}

.ms-nav__feature {
    display: block !important;
    background-color: $msv-white;
}

.ms-cart-icon__flyout-container .ms-cart-icon__flyout-title {
    margin-bottom: 0px;
    display: block;
}

.ms-search-result-container__Sort-by-category {
    background: transparent !important;
    border: none !important;
    bottom: auto !important;
    position: static;
    display: inline-flex;
    justify-content: flex-end;
    width: 50%;

    @media (max-width: $msv-breakpoint-l) {
        position: unset;
        margin-top: 25px;
        width: 100%;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: $msv-breakpoint-l) {
    .ms-account-management-address .msc-address-list__primary {
        overflow: auto !important;
    }

    .ms-business-organization-list__action__requestStatement__toggleDown {
        margin-left: 0px !important;
    }

    .msc-wishlist-icon__text,
    .ms-header__signin-button-text {
        display: none;
    }

    .ms-header__container .ms-header__collapsible-hamburger .ms-nav.mobile-vp {
        padding: 0px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .msc-carousel__indicators {
        position: absolute;
        bottom: -15px;
    }
}

@media screen and (max-width: 768px) {
    .ms-account-management-address .msc-address-list__primary {
        overflow: auto !important;
    }

    .Register-now-btn {
        white-space: nowrap !important;
    }

    .ms-business-organization-list__action__requestStatement__toggleDown {
        margin-left: 0px !important;
    }

    .msc-choice-summary {
        width: 100% !important;
    }

    .ms-search__searchForm {
        margin-top: 5px;
    }

    footer .ms-footer__item {
        padding-right: 0px;
    }

    footer .ms-footer__heading__title {
        margin: 15px 0 0 0;
    }

    footer,
    .ms-footer {
        text-align: center;
    }

    .ms-header__container .ms-header__collapsible-hamburger .ms-nav.mobile-vp {
        padding: 0px !important;
    }

    .ms-search-result-container__refiner-section .slider .slider__labels-item {
        margin-top: 12px !important;
        padding-bottom: 12px !important;
    }

    .ms-search-result-container__refiner-section .slider .slider__thumb {
        background-color: $msv-shiraz !important;
    }

    .ms-search-result-container__refiner-section .list-group-item {
        padding: 5px 12px 0 12px !important;
    }

    a.ms-nav__list__item__link {
        font-size: 16px !important;
    }

    .ms-search-result-container__refine-menu .Category .show ul {
        max-height: unset !important;
    }

    .ms-search-result-container__refine-menu .Producer .show ul {
        max-height: unset !important;
    }

    .ms-nav__list__item__link {
        font-size: 16px;
        text-transform: uppercase !important;
    }

    .ms-header__desktop-view_sticky .ms-nav__list__item__button,
    .ms-header__desktop-view_sticky .ms-nav__list__item__link {
        font-size: 16px !important;
        word-spacing: 0px;
        transition: all 0.3s;
    }

    .ms-search__icon-text {
        border-right: none !important;
    }

    .ms-search-result-container__refiner-section {
        width: auto !important;
    }

    .ms-search-result-container__title h2 {
        font-size: 0px !important;
    }

    .ms-search-result-container__title h2 span:nth-child(3) {
        display: block !important;
    }

    .msc-search-result-modal .msc-modal__header {
        margin: 0px !important;
    }

    .webshop-link a {
        left: 10px !important;
    }

    .ms-nav {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    }

    .ms-header .msc-wishlist-icon:before {
        padding-top: 4px;
        font-size: 16px;
    }

    .ms-nav__list__item__link,
    .ms-nav__list__item__button {
        border-bottom: 1px solid $msv-light-grey !important;
        color: $msv-black !important;
        font-size: 22px !important;
        line-height: 2rem !important;
        padding: 1rem !important;
        text-transform: uppercase !important;
    }

    .ms-nav .drawer__button {
        padding-left: 15px;
        text-align: left;
        background-color: $msv-white !important;
        color: $msv-black !important;
        border-bottom: 1px solid $msv-light-grey !important;
        border: none;
        font-size: 16px;

        .drawer__glyph {
            position: absolute;
            right: 16px;
        }

        .ms-nav__drawer-open {
            @include add-icon($msv-ChevronUp, after);
        }

        .ms-nav__drawer-close {
            @include add-icon($msv-ChevronDown, after);
        }
    }

    .ms-nav .drawer__buttontext {
        font-weight: 600;
    }

    .account-mobile .drawer__glyph:after,
    .account-mobile .drawer__button:before,
    .account-mobile .drawer__buttontext {
        color: $msv-supernova !important;
        font-weight: 400;
    }

    .ms-nav .ms-nav__drawer .ms-nav__list__item__link {
        transition: all 0.3s;
        font-weight: normal !important;
    }

    .ms-nav .ms-nav__drawer:focus {
        border: 0px !important;
    }

    .ms-nav .drawer__button:focus {
        background-color: $msv-supernova !important;
        border: 0px !important;
        box-shadow: none;
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: none !important;
    }

    .collapse,
    .collapsing {
        transition: all 0.3s;
    }

    .ms-header.ms-header-open {
        height: 100vh;
    }
}

.ms-product-search-result__item a:hover .msc-product__details .msc-product__title {
    text-decoration: none !important;
}

.ms-search-result-container__refiner-section p {
    font-weight: 700;
    margin-bottom: 20px;
}

.ks-products .ms-search-result-container__category-nav-section {
    display: none !important;
}

.ms-search-result-container__product-section {
    padding-left: 30px !important;
}

.ms-refine-submenu__toggle_collapsed:after {
    right: -10px;
    font-size: 12px;
    line-height: 20px;
}

.ms-refine-submenu__toggle_collapsed:focus-visible {
    border: none !important;
}

.ms-search-result-container .ms-product-search-result__item .msc-product .msc_image {
    width: auto;
    height: 160px;
    margin: auto;
}

.list-group-item {
    background-color: transparent !important;
}

.ms-refine-submenu-item:hover,
.ms-refine-submenu-item a:hover {
    color: $msv-black !important;
}

.ms-refine-submenu-item.multi-select:before,
.ms-refine-submenu-item.multi-select-checked:before {
    font-size: 16px !important;
    margin-left: 1px;
    color: $msv-storm-dust;
    margin-top: 2px;
}

.ms-search-result-container__refiner-section .ms-refine-submenu.list-group .ms-refine-submenu-item {
    a {
        display: flex;
        padding: 8px 0;

        a {
            display: flex;

            &:hover {
                text-decoration: none;
            }

            &:focus {
                outline: 1px dashed;
            }
        }
    }
}

.home_video-txt-area {
    background-color: $msv-supernova;
    padding: 10px 35px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 15px;

    @media (max-width: $msv-breakpoint-m) {
        margin: 0px;
    }
}

.webshop-link {
    background-color: $msv-shiraz !important;
    vertical-align: middle;
    align-items: center;
    width: 100%;
    margin: 70px 0px;

    @media (max-width: $msv-breakpoint-m) {
        margin: 0px;
    }
}

.webshop-txt {
    padding: 25px 35px;
    margin: 0px;
    color: $msv-black;
    background-color: $msv-soft-peach;

    @media (max-width: $msv-breakpoint-m) {
        margin: 0px;
    }
}

.webshop-txt a {
    @include primary-btn();
    background-color: $msv-shiraz;
    color: $msv-white;
    border: solid 1px $msv-shiraz;
    padding: 10px 50px;
}

.home_webshop-section .col-lg-6 {
    display: flex;
}

.webshop-link a {
    position: relative;
    top: -107px;
    font-size: 28px;
    font-weight: 600;
    left: 75px;
    border-bottom: solid 3px $msv-supernova !important;
    padding-bottom: 5px;

    @media screen and (max-width: $msv-breakpoint-m) {
        font-size: 23px;
    }
}

.webshop-link a:hover {
    text-decoration: none;
}

.webshop-link img {
    width: 100%;
}

.home_contact-section-txt {
    padding: 20px 40px;
    color: $msv-smalt-blue;
    margin: 0px;
    background-color: $msv-soft-peach;

    @media (max-width: $msv-breakpoint-m) {
        min-height: auto;
        padding-bottom: 0px;
    }
}

.ms-accordion-header-section-toggle-button-container .ms-accordion_ExpandAll:hover,
.ms-accordion-header-section-toggle-button-container .ms-accordion_CollapseAll:hover {
    border: none !important;
}

.ms-search-result-container__pagination .active {
    text-decoration: none;
    background-color: $msv-supernova;
    text-align: center;
    padding: 0px 10px !important;
    border-radius: 0px;
}

.ms-search-result-container__pagination .msc-page-item {
    padding: 0px 10px !important;
}

.ms-search-result-container__pagination .msc-page-link {
    font-size: 14px;
    line-height: normal;
}

.ms-search-result-container__pagination .previous a,
.ms-search-result-container__pagination .next a {
    border-radius: 0px;
    background-color: $msv-supernova;
}

.ms-search-result-container__pagination .disabled .msc-page-link {
    border-radius: 0px;
    background-color: $msv-soft-peach;
}

.pull-right .list-view-btn,
.pull-right .grid-view-btn {
    font-size: 0px;
    font-size: 0px;
    border: solid 1px $msv-white;
    margin: 2px;
    color: transparent;
    background-color: transparent;
    line-height: normal;
    padding: 3px 5px !important;

    &:focus {
        border-color: $msv-black;
        border-style: dashed;
    }
}

.pull-right .active {
    text-decoration: none !important;
    border: solid 1px $msv-soft-peach;
}

.pull-right button:nth-child(1)::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f023';
    font-size: 18px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='23' viewBox='0 0 28 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='5' height='5' fill='%23878787'/%3E%3Crect y='9' width='5' height='5' fill='%23878787'/%3E%3Crect y='18' width='5' height='5' fill='%23878787'/%3E%3Crect x='8' width='20' height='5' fill='%23878787'/%3E%3Crect x='8' y='9' width='20' height='5' fill='%23878787'/%3E%3Crect x='8' y='18' width='20' height='5' fill='%23878787'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 20px;
}

.pull-right button:nth-child(2)::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f039';
    font-size: 18px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='5' height='5' fill='%23878787'/%3E%3Crect y='9' width='5' height='5' fill='%23878787'/%3E%3Crect y='18' width='5' height='5' fill='%23878787'/%3E%3Crect x='9' width='5' height='5' fill='%23878787'/%3E%3Crect x='9' y='9' width='5' height='5' fill='%23878787'/%3E%3Crect x='9' y='18' width='5' height='5' fill='%23878787'/%3E%3Crect x='18' width='5' height='5' fill='%23878787'/%3E%3Crect x='18' y='9' width='5' height='5' fill='%23878787'/%3E%3Crect x='18' y='18' width='5' height='5' fill='%23878787'/%3E%3C/svg%3E%0A");

    background-repeat: no-repeat;
    background-size: 17px;
}

.ms-search-result-container__refine-menu .Category .show {
    min-height: auto !important;
    transition: none !important;
}

.ms-search-result-container__refine-menu .Category .collapsing {
    min-height: auto !important;
}

.ms-search-result-container__refine-menu .Category .show ul {
    max-height: 300px;
    overflow-y: scroll;
}

.ms-search-result-container__refine-menu .Producer .show {
    min-height: auto !important;
    transition: none !important;
}

.ms-search-result-container__refine-menu .Producer .collapsing {
    min-height: auto !important;
}

.ms-search-result-container__refine-menu .Producer .show ul {
    max-height: 300px;
    overflow-y: scroll;
}

.ms-refine-submenu__input-range-refiner {
    clear: both;
}

.ms-refine-submenu__input-range-label {
    width: 50%;
}

.ms-refine-submenu__input-range-label input {
    width: 75px;
    border: solid 1px $msv-soft-peach;
    margin-top: 10px;
    padding: 5px 10px;

    display: block;
}

.ms-refine-submenu__input-range-label input:focus-visible {
    border: solid 1px $msv-nobel !important;
}

.ks-products .ms-search-result__collection-title-prefix {
    display: none;
}

.ks-products {
    padding: 24px 0 0 0;

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-top: 0px;
    }
}

.ms-search-result-container__title-count {
    display: flex;
}

.result-count-category h5,
.ms-search-result-container__title h5 {
    padding: 6px 0px 0px 10px;
}

.msc-choice-summary {
    width: 50%;
    display: inline-flex;
    flex-wrap: wrap;

    @media (max-width: $msv-breakpoint-l) {
        width: 100%;
    }
}

.msc-choice-summary .msc-choice-summary__list-item {
    margin-bottom: 5px;
}

.ms-search__icon-text {
    color: $msv-smalt-blue !important;
    border-right: solid 1px $msv-nobel;
    padding-right: 10px;
}

.refine-submenu__input-range-min-error-text {
    flex: 0 0 100%;
    color: $msv-shiraz;
    padding-top: 5px;
    display: block;
}

.msc-wishlist-icon {
    display: none !important;
}

.fa-plus:before,
.fa-minus:before {
    display: none;
}

footer .ms-footer > * > *,
footer .ms-footer > * {
    padding-bottom: 0px;
}

.social-footer {
    margin-top: 35px;
    min-height: auto;
}

.social-footer p {
    font-size: 12px !important;
    margin: 0px !important;
}

.slider__labels-item {
    font-size: 14px !important;
}

.ms-search-result-container__refiner-section .slider .slider__track {
    background-color: $msv-black;
}

.msc-product__item_number {
    display: block;
    padding: 3px 0px;
}

.list-product-actual {
    font-size: 16px !important;
    line-height: normal !important;
    color: $msv-smalt-blue !important;
    font-weight: 600 !important;
    padding-top: 15px;
    position: absolute;
    right: 25px;
    top: 10px;
}

.msc-price__actual {
    color: $msv-black;
    font-size: 16px;
    font-weight: 700;
}

.ms-search-result-container__refine-menu {
    display: flex;
    flex-direction: column;
}

.ms-search-result-container__refine-menu-filter-title {
    order: 1 !important;
}

.ms-search-result-container__refine-menu .Category {
    order: 2 !important;
}

.ms-search-result-container__refine-menu .Manufacturer {
    order: 3 !important;
}

.ms-search-result-container__refine-menu .Price {
    order: 4 !important;
}

.ms-search-result-container__refine-menu div {
    order: 50;
}

.ms-cart-icon__subTotalText {
    border-top: solid 2px $msv-smalt-blue;
    padding: 20px;
    margin-top: 5px;
}

.ms-cart-icon__subTotalPriceText {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

.ms-cart-icon__subTotalPrice {
    padding-left: 17px;
    font-weight: 700;
    color: $msv-smalt-blue;
}

.msc-product__description {
    font-size: 12px;
    line-height: normal;
    text-transform: capitalize;
}

.msc-product-tile-cart-icon {
    text-align: right;
}

.krannich_footer_contianer {
    display: flex;
    margin-top: 5px;

    .krannich_footer_imprint {
        padding-left: 10px;
        padding-top: 4px;
        font-size: 12px;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

h1 {
    font-size: 36px;
    margin: 10px 0px;
    font-weight: 700;
    line-height: normal;
    color: $msv-black;
    text-transform: uppercase;

    @media screen and (max-width: $msv-breakpoint-m) {
        font-size: 28px;
    }
}

h2 {
    font-size: 28px;
    margin: 10px 0px;
    font-weight: 700;
    text-transform: uppercase;
}

h3 {
    font-size: 24px;
    margin: 10px 0px;
    font-weight: 700;
}

h4 {
    font-size: 18px;
    margin: 10px 0px;
    font-weight: 600;
}

h5 {
    font-size: 16px;
    margin: 10px 0px;
    font-weight: 600;
}

h6 {
    font-size: 14px;
    margin: 10px 0px;
    font-weight: 600;
}

.imprint-page-area {
    .ms-content-block {
        min-height: auto;
    }
}

.request-price {
    padding: 0px 0px 10px 0;
}

.specs-area {
    margin-bottom: 50px;
}

.pdp-download {
    padding-top: 30px;

    h2 {
        text-transform: uppercase;
    }
}

.msc-address-list-table {
    width: 100%;
    border: 1px solid $msv-soft-peach;
    box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);
}

.msc-address-list-table tr th {
    padding: 16px 0px;
    border-bottom: solid 5px $msv-supernova;
    font-size: 14px;
    line-height: 19px;
    background-color: $msv-soft-peach;
    font-weight: 600;
    word-wrap: break-word;
    padding-left: 5px;
    text-transform: capitalize;

    @media (max-width: $msv-breakpoint-l) {
        min-width: 150px;
    }

    &:first-child {
        padding-left: 14px;
    }
}

.msc-address-list-table tr td {
    padding: 12px 0px 12px 5px;
    font-size: 14px;
    word-wrap: break-word;
    border-bottom: solid 1px $msv-light-grey;
    max-width: 90px;
    word-wrap: break-word;
    color: $msv-storm-dust;

    .msc-address-detail__item-address-detail_Phone {
        .-label {
            display: none;
        }
    }

    &:first-child {
        padding-left: 14px;
    }

    .msc-address-detail__item-phone {
        &::before {
            display: none;
        }
    }
}

.msc-address-list-table .msc-address-list__button-edit {
    font-size: 0px;
    border: none;
    background-color: transparent;
    @include add-svg-icon($icon-edit, 20px, 20px);
}

.msc-address-list-table .msc-address-list__button-remove {
    font-size: 0px;
    border: none;
    background-color: transparent;
    @include add-svg-icon($icon-trash, 20px, 20px);
}

.msc-address-list-table .msc-address-list__button-primary {
    height: auto;
    padding: 0;
    border: 0;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    background: transparent;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: $msv-smalt-blue;

    &:hover {
        text-decoration: none;
    }
}

.msc-address-list-table tr:nth-child(2) {
    td {
        color: $msv-black;
        font-weight: 500;
    }
}

.msc-address-form .msc-address-form__button-save {
    padding-right: 25px;
}

.b2b-sign-up-btn {
    a {
        font-weight: 600;
        color: $msv-supernova;
        text-transform: uppercase;
    }
}

select {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg data-name='Layer 1' id='Layer_1' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle/%3E%3Cpath d='M32,51.5,0,18.83,6.62,12.5,32,38.76,57.38,12.5,64,18.83Z' data-name='&lt;Compound Path&gt;' id='_Compound_Path_'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: 100% !important;
    background-size: 13px !important;
    background-position: bottom 12px right 15px !important;
}

.ms-refine-submenu__input-range .refine-submenu__input-range-min {
    display: block !important;
}

.ms-refine-submenu__input-range-label input {
    display: block;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: $msv-black !important;
    background-color: $msv-supernova !important;
    border-color: $msv-supernova !important;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: none !important;
}

.alert-modal-body-container {
    .msc-modal__body {
        margin-bottom: 0px;

        .alert-btn-group {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 20px 0px 20px;

            button {
                width: 150px;
            }

            .cancel-btn {
                margin-left: 10px;
                @include secondary-btn();
            }
        }
    }
}

.voucer-code {
    margin-right: 5px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 5px;
}

.add-to-cart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include primary-btn();
    width: auto;
    height: auto;
}

.plp-list-kran {
    .add-to-cart-container {
        width: 160px;
        height: 38.8px;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

main {
    min-height: calc(100vh - 315px);
}

.applied-line-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .ms-checkout-customer-account__customer-since {
        padding-bottom: 0px;
    }
}

.total-limit-container {
    margin-top: 10px;
}

.amount-applied-contianer {
    display: flex;
    align-items: center;

    .ms-checkout-customer-account__applied-line {
        margin-top: 0px;
        margin-left: 4px !important;
    }
}

.credit-limit-input-container {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    margin: 0 12px 5px 0px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    .ms-checkout-customer-account__input-label {
        margin: 0px;
    }

    .ms-checkout-customer-account__input-amount {
        margin-bottom: 0px;
    }

    .amount-label {
        margin-right: 5px;
    }

    .amount-value {
        color: $msv-smalt-blue;
    }
}

.ms-checkout-customer-account__applied-line {
    .ms-checkout-customer-account__remove {
        display: none;
    }
}

.ms-order-confirmation__sales-line {
    .msc-cart-line__error-message {
        display: none !important;
    }
}

.msc-price__strikethrough {
    color: $msv-shiraz;
    font-weight: 500;
}

.order-template-modal-contianer {
    display: flex;
    flex-direction: column;

    .image-contianer {
        height: 200px;

        img {
            height: 200px;
            margin: auto;
            pointer-events: none;
        }

        .msc-empty_image {
            height: 200px;
        }
    }

    .information-container {
        display: flex;

        .detail-contianer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            flex: 3;
            padding-right: 25px;

            ._uom {
                display: flex;
            }
        }

        .quantity-container {
            display: flex;
            justify-content: right;
            align-items: center;
        }

        .no-price-wrapper {
            .label {
                min-width: auto;
            }
        }
    }
}

.ctx-disabled-view-more {
    pointer-events: none;
    background-color: $msv-light-grey;
    border-color: $msv-light-grey;
    color: $msv-gunsmoke;
}

.order-discount-container {
    padding: 16px 26px;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border-bottom: solid 1px $msv-soft-peach;
    display: flex;
    justify-content: space-between;
}

.news-letter-sub-area {
    padding: 0px !important;

    @media (max-width: $msv-breakpoint-l) {
        .row .col-md-6:nth-child(2) {
            display: none;
        }

        .row .col-md-6:nth-child(1) {
            width: 100%;
            max-width: 100%;
            flex: 100%;
        }
    }

    line-height: normal;

    h2 {
        margin-top: 50px;
    }

    .subs-subheading {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .subs-input-container {
        div {
            width: 100%;
        }

        font-size: 16px;
        display: flex;
        flex-wrap: wrap;

        p {
            @include msc-alert-error();
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        height: 40px;
        padding: 6px 8px;
        border: 1px solid $msv-light-grey;
        font-size: 16px;
        color: $msv-black;
    }

    .sub-form-label {
        font-size: 16px;
        color: $stone;
        padding-top: 5px;
        padding-bottom: 5px;
        text-transform: capitalize;
    }

    .sub-form-btn {
        @include primary-btn();
        cursor: pointer;
    }

    .phoneNo {
        position: relative;

        &::before {
            content: '+';
            position: absolute;
            top: 36px;
            font-size: 18px;
            left: 10px;
        }

        input {
            padding-left: 22px !important;
        }
    }
}

.hideContact {
    display: none;
}

.contact-info-label {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    text-transform: none !important;
    font-size: 18px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    display: flex !important;
    margin: 0 !important;
}

.closed-custom-info {
    display: none;
}

.expanded-custom-info {
    display: block;
}

.news-letter-sub-area {
    .sub-form-label {
        margin-right: 0px !important;
    }

    .newsletter-checkbox-label {
        width: auto !important;
        margin-right: 2px !important;
    }

    line-height: normal;

    h2 {
        margin-top: 50px;
    }

    .subs-subheading {
        font-size: 18px;
    }

    .subs-input-container {
        font-size: 16px;

        :nth-child(1) {
            @media (max-width: 1259px) {
                width: 100%;
                margin-right: 0px;
            }

            width: 47%;
            margin-right: 33px;
        }

        :nth-child(2) {
            @media (max-width: 1259px) {
                width: 100%;
            }

            width: 47%;
        }

        div {
            input[type='checkbox'] {
                width: 15px !important;
            }

            label {
                width: 100% !important;
            }

            input {
                width: 100% !important;

                &:focus {
                    outline: 1px dashed;
                }
            }
        }

        .agree {
            display: flex;
            align-items: baseline;

            p {
                background: transparent;
                margin: 0px;
                padding: 0px;
            }

            label {
                order: 2;
                text-transform: none;

                a {
                    @include tertiary-btn();
                    padding: 0;
                    cursor: pointer;

                    &:focus,
                    &:active {
                        padding: 0;
                        margin: 0;
                    }
                }
            }

            input {
                margin-top: -30px;
                margin-right: 10px;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        height: 40px;
        padding: 6px 8px;
        border: 1px solid $msv-light-grey;
        font-size: 16px;
    }

    .sub-form-label {
        font-size: 16px;
        color: $stone;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .sub-form-btn {
        @include primary-btn();
        text-align: center;
        margin-top: 30px;
        cursor: pointer;
        &:focus {
            border: 1px dashed;
        }
    }

    .news-letter-img-area {
        padding: 56px 25px 0px 25px;
    }

    .subs-form-container {
        margin-right: 50px;

        @media (max-width: $msv-breakpoint-l) {
            margin-right: 0px;
        }

        .news-letter-success-message {
            color: $msv-black;
            margin-top: $quickOrder-desc-margin-top;
            font-size: 14px;
            border-radius: 0px;
            background-color: $down;
            border: solid 1px $geyser;
            font-weight: normal;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='black'/%3E%3Cpath d='M8.40594 5.20586L6.10293 7.5159L5.19986 6.60592C5.02231 6.42838 4.76344 6.359 4.52098 6.42405C4.2784 6.48897 4.08891 6.67846 4.02399 6.92103C3.95895 7.1635 4.02833 7.42237 4.20587 7.59991L5.60593 8.99997C5.7373 9.13251 5.91625 9.20705 6.10291 9.20705C6.28948 9.20705 6.46844 9.13251 6.5999 8.99997L9.3999 6.19997C9.57744 6.02243 9.64682 5.76356 9.58178 5.52098C9.51685 5.2784 9.32736 5.08903 9.08479 5.02399C8.84233 4.95895 8.58348 5.02832 8.40594 5.20586Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 15px 14px;
            padding: 12px 45px;
            margin-bottom: 15px;
        }
    }
}

.brand-heading-container {
    margin-bottom: 29px;

    h2 {
        margin-top: 0px;
    }

    .ms-text-block {
        margin-top: 0px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        color: $msv-storm-dust;
    }
}

.brand-tile-container {
    img {
        margin: auto;
    }

    min-height: 100px;
    margin: 10px;
    background: $msv-white;
    border: 1px solid $alto;
    box-shadow: 0px 1px 10px rgba(151, 151, 151, 0.1);
    padding: 16px 15px;
    position: relative;

    ._img-container {
        width: 177px;
        height: 47px;
    }

    ._link {
        display: block;
        position: absolute;
        right: 0px;
        background: $msv-black;
        width: 32px;
        height: 32px;
        bottom: 0px;
        font-size: 0;
        color: $msv-white;

        @media (max-width: $msv-breakpoint-m) {
            right: 0px;
        }

        &::before {
            @include msv-icon();
            content: '\f061';
            padding: 9px;
            font-size: 16px;
        }

        &:hover,
        &:focus {
            background-color: $msv-supernova;
            color: $msv-black;

            ::before {
                color: $msv-black;
                font-size: 16px;
            }
        }
    }
}

.brands-items-area {
    .brand-item {
        ul {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: $msv-breakpoint-m) {
                justify-content: center;
            }
        }

        a {
            min-height: 100px;
            width: 190px;
            margin: 10px;
            background: $msv-white;
            border: 1px solid $msv-light-grey;
            box-shadow: 0px 0.978134px 9.78134px rgba(151, 151, 151, 0.1);
            padding: 16px 15px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                margin: auto;
                max-height: 48px;
                width: auto;
            }

            ::after {
                @include msv-icon();
                content: '';
                padding: 9px;
                display: block;
                position: absolute;
                right: 0px;
                background: $msv-black;
                width: 32px;
                height: 32px;
                bottom: 0px;
                font-size: 14px;
                color: $msv-white;
                background-image: url($icon-arrow);
                background-repeat: no-repeat;
                background-position: 7px 8px;
                background-size: 16px;

                &:hover {
                    background-color: $msv-supernova;
                    color: $msv-black;
                }
            }

            &:hover {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                transition: width 0.1s;

                ::after {
                    @include msv-icon();
                    content: '';
                    padding: 9px;
                    display: block;
                    position: absolute;
                    right: 0px;
                    background-color: $msv-supernova;
                    width: 32px;
                    height: 32px;
                    bottom: 0px;
                    font-size: 14px;

                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6004 1.09598C10.105 0.615143 9.33103 0.615143 8.83562 1.09598C8.34021 1.57681 8.34021 2.32804 8.83562 2.80888L13.7584 7.58664H1.23828C0.557164 7.58664 0 8.12742 0 8.7885C0 9.44958 0.557164 9.99036 1.23828 9.99036H13.7893L8.83589 14.7683C8.34049 15.2492 8.34049 16.0004 8.83589 16.4812C9.0836 16.7217 9.39306 16.8418 9.70277 16.8418C10.0125 16.8418 10.3219 16.7217 10.5696 16.4812L17.6284 9.63003C18.1239 9.1492 18.1239 8.39797 17.6284 7.91713L10.6004 1.09598Z' fill='black'/%3E%3C/svg%3E");

                    background-repeat: no-repeat;
                    background-position: 7px 8px;
                    background-size: 16px;
                    color: $msv-black;
                }
            }
        }
    }
}

.under-construction {
    img {
        margin: auto;
        width: 350px;
        height: 350px;

        @media (max-width: $msv-breakpoint-m) {
            max-width: 100%;
            height: auto;
        }
    }

    h2 {
        font-weight: 700;
        margin-top: 50px;
        text-align: center;
        font-size: 48px;
        width: 100%;
        margin-bottom: 60px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 38px;
        font-weight: 700;
        color: $msv-smalt-blue;
        width: 100%;
        text-align: center;
        margin-top: 45px;
        margin-bottom: 60px;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 28px;
        }
    }

    a {
        @include primary-btn();
        width: 250px;
    }

    .ms-content-block__cta {
        text-align: center;
        margin-bottom: 50px;
    }
}

.page-error-404 {
    img {
        @media (max-width: $msv-breakpoint-m) {
            max-width: 100%;
            height: auto;
        }
    }

    h2 {
        font-weight: 700;
        margin-top: 50px;
        text-align: center;
        font-size: 48px;
        width: 100%;
        margin-bottom: 60px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 38px;
        font-weight: 700;
        color: $msv-smalt-blue;
        width: 100%;
        text-align: center;
        margin-top: 45px;
        margin-bottom: 60px;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 28px;
        }
    }

    a {
        @include primary-btn();
        width: 250px;
    }

    .ms-content-block__cta {
        text-align: center;
    }
}

.promo-code-contianer {
    display: flex;
}

.promo-code-info-icon {
    color: $msv-shiraz;
    font-size: 0px;
    font-weight: normal;
    background-color: transparent;
    margin: 5px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    padding-left: 20px;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &::before {
        content: attr(title);
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        width: 200px;
        color: $msv-black;
        background: $alto;
        height: fit-content;
        z-index: 1;
        font-size: 10px;
        line-height: 11px;
        padding: 6px;
        display: none;
    }

    &:hover {
        &::before {
            display: block;
        }
    }
}

// tooltip global style
.tooltip-wrapper {
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: 16px;
    height: 16px;

    .tooltip-icon {
        @include add-svg-icon($icon-info, 16px, 16px);
    }

    .tooltip-text {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        width: 200px;
        color: $msv-black;
        background: $alto;
        height: fit-content;
        z-index: 1;
        font-size: 12px;
        line-height: 14px;
        padding: 6px;
        text-align: left;
        text-transform: initial;
        font-weight: 400;
        display: none !important;

        &.tooltip-left {
            right: 0;
        }

        &.tooltip-center {
            transform: translate(-50%, -100%);
        }

        &.tooltip-sm-center {
            @media (max-width: $msv-breakpoint-m) {
                transform: translate(-50%, -100%);
                right: unset;
            }
        }
    }

    &:hover,
    &:focus {
        .tooltip-text {
            display: block !important;
        }
    }
}

.request-price_plp-border {
    border-top: 1px solid $msv-light-grey;
}

.news-letter-danger-message {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
    color: $msv-shiraz;
    margin-top: 12px;
    font-size: 14px;
    border-radius: 0px;
    background-color: rgba($msv-shiraz, 0.4);
    border: solid 1px $geyser;
    font-weight: normal;
    background-repeat: no-repeat;
    background-position: 15px 14px;
    padding: 12px 45px;
    margin-bottom: 15px;
}

.ms-no_filters_text {
    padding: 25px;
}

.checkbox-input-disabled + .checkmark {
    opacity: 0.3;
    cursor: default;
}

.discontinued-tag {
    color: $msv-shiraz;
    font-weight: 600;
}

// Price styling

.krannich-price {
    display: inline-flex;
    flex-direction: column;
    text-transform: initial;

    .inc {
        color: $msv-smalt-blue;
        font-size: 14px;
        font-weight: 600;
    }
}

.to-be-calculated {
    display: inline-flex;
    align-items: center;

    .tooltip-wrapper {
        margin-left: 4px;
    }
}

.checkout-summary-freight {
    font-size: 14px;
    font-weight: 600;
    color: $msv-smalt-blue;
    text-align: left;
}

.restrict-user-message {
    font-size: 16px;
    font-weight: 600;
    color: $msv-shiraz;
}

// badges styling

.badge {
    padding: 9px;
    color: $msv-white;
    background-color: $msv-shiraz;
    font-size: 12px;
    font-weight: 700;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 1;
}
a,
button {
    &:focus {
        outline: 1px dashed;
    }
}
