$msv-password-reset-verification-width: 100%;
$msv-password-reset-verification-width-m: 80%;
$msv-password-reset-verification-message-margin-top: 20px;
$msv-password-reset-verification-message-padding: 20px;
$msv-password-reset-verification-account-item-margin-top: 20px;
$msv-password-reset-verification-input-margin-top: 4px;
$msv-password-reset-verification-email-verification-buttons-margin-top: 12px;
$msv-password-reset-verification-button-group-spacing: 32px;
$msv-password-reset-verification-button-margin-top: 20px;

//style presets
:root {
    --msv-password-reset-verification-font-size: var(--msv-body-font-size-m);
    --msv-password-reset-verification-font-color: var(--msv-font-primary-color);

    // heading
    --msv-password-reset-verification-heading-font-size: var(--msv-body-font-size-xl);
    --msv-password-reset-verification-heading-font-color: var(--msv-font-primary-color);

    //error
    --msv-password-reset-verification-error-font-color: #{$msv-red};
    --msv-password-reset-verification-error-font-size: var(--msv-body-font-size-m);

    //form
    --msv-password-reset-verification-form-input-bg: #{$msv-white};
    --msv-password-reset-verification-form-input-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-form-input-border: #{$msv-gray-500};

    // alert
    --msv-password-reset-verification-success-alert-bg: #{$msv-gray-300};
    --msv-password-reset-verification-success-alert-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-success-alert-border: #{$msv-gray-900};
    --msv-password-reset-verification-error-alert-bg: var(--msv-error-color);
    --msv-password-reset-verification-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-error-alert-border: var(--msv-error-color);

    // primary button
    --msv-password-reset-verification-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-password-reset-verification-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-password-reset-verification-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-password-reset-verification-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-password-reset-verification {
    @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-font-size), $msv-line-height-m);
    color: var(--msv-password-reset-verification-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: 100%;
    }

    &__heading {
        // @include font-content(var(--msv-font-weight-normal),
        // var(--msv-password-reset-verification-heading-font-size), $msv-line-height-xl);
        // color: var(--msv-password-reset-verification-heading-font-color);
        display: none;
    }

    &__account-item {
        margin-bottom: $msv-password-reset-verification-account-item-margin-top;
        label {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }
        .ms-sign-in__item-error-text {
            margin: 0;
        }

        &-input {
            @include form-input-el();
            font-size: 14px;
            // margin-top: $msv-sign-in-input-margin-top;
            width: 100%;
            border: 1px solid $msv-supernova;
            height: 40px;
            background: transparent;
            border-radius: 2px;

            &:focus {
                border: 1px dashed $msv-gray-500;
                outline: none;
            }
        }
    }

    &__email-verification-buttons {
        margin-top: $msv-password-reset-verification-email-verification-buttons-margin-top;
    }

    &__account-item-email_ver_input {
        .ms-password-reset-verification {
            &__account-item-label {
                display: none;
            }

            &__account-item-email_ver_input {
                display: none;
            }
        }
    }

    &__verify-email-edit {
        display: none;
    }

    /* Override inline style inject by AAD so we need important */
    &__page-success[aria-hidden='false'],
    &__page-error[aria-hidden='false'] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    &__page-success {
        @include validation-success(
            var(--msv-password-reset-verification-success-alert-bg),
            var(--msv-password-reset-verification-success-alert-border),
            var(--msv-password-reset-verification-success-alert-font-color)
        );
        display: none;

        margin-top: 10px;
        font-size: 14px;
        color: green;
        padding: 0px;
        background: transparent;
    }

    &__page-error {
        @include validation-error(
            var(--msv-password-reset-verification-error-alert-bg),
            var(--msv-password-reset-verification-error-alert-border),
            var(--msv-password-reset-verification-error-alert-font-color)
        );
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        padding: $msv-password-reset-verification-message-padding;
    }

    &__item-error {
        @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-error-font-size), $msv-line-height-m);
        color: var(--msv-password-reset-verification-error-font-color);
        font-size: 14px;
    }

    &__email-verification-button {
        @include advisery-btn();
        width: 100%;
    }
    &__continue-button {
        @include primary-btn();
        margin-bottom: 5px;
        // margin-top: $msv-sign-in-button-margin-top;
        width: 100%;
    }

    &__cancel-button {
        @include primary-btn();
        // margin-top: $msv-sign-up-button-margin-top;
        background: $msv-soft-peach;
        border-color: $msv-soft-peach;
        width: 100%;
        &:hover {
            border-color: transparent;
            background: rgba($msv-soft-peach, 0.8);
        }
    }

    &__verify-email-verify,
    &__verify-email-resend,
    &__continue-button:disabled {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-verification-width-m;
        }
    }
}

.ms-sign-up__verify-email-resend-button {
    @include tertiary-btn();
    text-align: right; 


    background-color: transparent !important;
    border-radius: 0px !important;
    color: $msv-smalt-blue !important;
    border: none !important;
    font-size: 14px;
    text-decoration: underline !important;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 15px;
    padding-right: 0px !important;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:active {
        background-color: transparent;
        border-radius: 0px;
        color: $msv-smalt-blue;
        border: none;
        font-size: 14px;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: 600;
        height: auto;
        line-height: 24px;
        padding: 7px 15px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        color: $msv-supernova;
        text-decoration: underline;
        box-shadow: none;
        background-color: transparent;
        border: none;
    }
    &:disabled,
    &.disabled {
        background-color: transparent;

        color: $msv-light-grey;
        pointer-events: none;
        text-decoration: none;
    }

}
