.promotions-page {
    .home_hero-main {
        .ms-content-block__details {
            @media (max-width: $msv-breakpoint-m) {
                min-height: auto;
            }
        }

        h3 {
            @media (max-width: $msv-breakpoint-m) {
                font-size: 24px;
            }
        }

        p {
            @media (max-width: $msv-breakpoint-m) {
                padding-top: 0;
                font-size: 16px;
            }
        }

        .login-user {
            .msc-carousel__item {
                height: 500px;

                @media (max-width: $msv-breakpoint-ml) {
                    height: auto;
                }
            }
        }
    }

    .service-info {
        margin-top: 50px;

        .ms-text-block {
            max-width: 100%;
            margin-top: 0;
        }
    }

    .services-content {
        .ms-content-block {
            h2 {
                font-size: 32px;
                line-height: 1.2;
                color: $dove-grey;
                font-weight: 700;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 24px;
                    margin-bottom: 16px;
                    margin-top: 16px;
                }
            }

            p {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 16px;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 16px;
                }
            }
        }

        .ms-content-block__cta {
            margin-top: 16px;
        }

        .alert-text {
            color: $msv-shiraz;
            font-weight: 700;
        }

        ul {
            padding: 0;
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            color: $msv-storm-dust;
            list-style-type: none;
            margin-bottom: 16px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 16px;
            }

            li {
                margin-bottom: 4px;

                &::before {
                    content: '';
                    height: 10px;
                    width: 10px;
                    background: $msv-smalt-blue;
                    display: inline-block;
                    margin-right: 12px;
                    border-radius: 50%;
                }
            }
        }

        .youtube-playlist {
            display: flex;
            height: 100%;
            align-items: center;
        }

        .ms-content-block__cta {
            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        .msc-cta__primary {
            @media (max-width: $msv-breakpoint-m) {
                min-width: 100%;
            }
        }
        .yt-content-block {
            @media (max-width: $msv-breakpoint-m) {
                padding: 0;
                margin-bottom: 16px;
            }
        }
    }

    .youtube-playlist {
        max-width: 100%;
        padding-top: 100px;

        @media (max-width: $msv-breakpoint-l) {
            margin-bottom: 32px;
        }
        iframe {
            max-width: 100%;
            width: 100%;
        }
    }
}

.columns-space {
    .row {
        margin: -15px;
    }

    .col-12 {
        padding: 0 15px;
    }
}

.full-width-banner {
    margin-bottom: 50px;

    .ms-content-block__link {
        display: block;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.yt-content-block {
    @media (max-width: $msv-breakpoint-m) {
        padding: 0;
    }

    .ms-content-block__image {
        display: none;
    }

    .ms-content-block__details {
        width: 100%;
    }
}

.buisness-signup-content {
    .ms-content-block__details {
        display: flex;
        flex-direction: column;
        background: $msv-supernova;
        padding: 24px 16px;
        margin-top: 100px;
        justify-content: center;
        align-items: center;

        .ms-content-block__title {
            font-size: 24px;
            margin: 0 0 16px;
            font-weight: 700;
        }

        .msc-cta__primary {
            @include primary-btn();
            min-width: 180px;

            @media (max-width: $msv-breakpoint-m) {
                min-width: 100%;
            }

            &:hover,
            &:focus,
            &:active {
                border-color: $msv-black;
            }
        }
    }
}

.sections-list {
    .list-wrapper {
        display: flex;
        justify-content: space-between;
        width: 80%;
        font-size: 16px;
        color: $msv-smalt-blue;
        margin-top: 32px;
        font-weight: 600;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column;
            width: 100%;
        }

        .list-col {
            display: flex;
            flex-direction: column;
            padding-right: 16px;
        }
    }

    a {
        color: $msv-smalt-blue;
        font-weight: 600;
        font-size: 16px;
        margin-left: 4px;
        margin-bottom: 16px;

        &:hover {
            text-decoration: none;
        }
    }
}

.cta-content {
    margin-top: 25px;
    .msc-cta__primary,
    .msc-cta__secondary {
        @include primary-btn();
        width: calc(50% - 45px);
        margin-bottom: 16px;
        min-width: 265px;

        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }

        &::after {
            @include msv-icon();
            content: $msv-ChevronDown;
            transform: rotate(-90deg);
            margin-left: 4px;
        }
    }

    .ms-content-block__cta {
        padding-top: 32px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;
    }

    &.vertical {
        .ms-content-block__cta {
            align-items: center;
            flex-direction: column;
        }

    }
}

.youtube-playlist {
    min-height: 315px;
}
