$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 794px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-qr-code-block-bg-color: $msv-white;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-300;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 50px;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 50px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 12px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;
$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;
$msv-main-container-margin: 0 auto;
$msv-font-weight-600: 600;
$msv-font-weight-355: 355;

.ms-order-confirmation {
    h4 {
        font-weight: normal;
    }

    padding-top: 36px;
    width: 100%;
    margin: auto;
    position: relative;

    .msc-price__actual {
        font-size: 16px;
    }

    &.ms-text-block {
        margin-bottom: 0;
        padding-top: 0;
        margin-top: 0;

        h1 {
            color: $msv-black;
            font-weight: 700;
            font-size: 28px;
            line-height: 37px;
            text-transform: uppercase;
            margin-bottom: 7px;
            margin-top: 0;
        }

        h4 {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: $msv-storm-dust;
        }
    }

    .heading-backtoshop {
        margin-bottom: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $msv-breakpoint-m) {
            margin-bottom: 24px;
        }
    }

    .address-order-request-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;

        @media (max-width: $msv-breakpoint-m) {
            display: block;
        }

        .Shipping-address-section,
        .order-request-details-section {
            width: calc(50% - 22px);
            border: 1px solid $msv-soft-peach;
            padding: 18px 32px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
                padding: 18px;
            }

            h2 {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: $msv-black;
                margin-bottom: 12px;
                text-transform: capitalize;
            }

            span,
            label,
            .order-request-details-date {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: $msv-black;
                margin-bottom: 9px;
                margin-right: 5px;
            }

            label {
                font-weight: 500;

                @media (max-width: $msv-breakpoint-l) {
                    display: block;
                    margin: 0;
                }

                &+span {
                    color: $msv-storm-dust;

                    @media (max-width: $msv-breakpoint-l) {
                        margin-bottom: 8px;
                        display: block;
                    }
                }
            }
        }

        .Shipping-address-section {
            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 16px;
            }
        }

        .Address-name {
            span {
                margin-bottom: 0;
            }

            .ms-order-confirmation__address-name {
                margin-bottom: 9px;
            }
        }

        .ms-order-confirmation__order-information-created-date {
            &::first-line {
                font-weight: 500;
            }
        }
    }

    &__heading {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $msv-black;
        margin-bottom: 0;
        margin-top: 0;
        text-transform: capitalize;
    }

    &__payment-methods-customer-account {
        margin-top: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-transform: none;
        margin-bottom: 12px;

        .ms-order-confirmation__order-summary-label {
            font-size: 14px;
            font-weight: 600;
        }
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;

        &-qr-code {
            align-items: center;
            margin-right: 7px;

            display: flex;
            height: $msv-details-order-information-qr-code-block-height;
            justify-content: right;
            width: $msv-details-order-information-qr-code-block-width;
            float: right;

            @media (max-width: $msv-breakpoint-m) {
                justify-content: left;
            }

            .qrcode-screenreader-text {
                clip: rect(1px, 1px, 1px, 1px);
                clip-path: inset(50%);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            @include font-content-m();
            display: block;
            font-size: 14px;

            span {
                font-weight: $msv-font-weight-600;
            }
        }

        &-created-date {
            @include font-content-m();
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $msv-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-keep-shopping {
        color: $msv-smalt-blue;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        height: auto;
        line-height: normal;
        text-decoration: underline;
        padding: 0px;
        border: 0;

        &:hover {
            padding: 0px;
            text-decoration: none;
        }
    }

    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;

        &-shipping-items-label,
        &-picking-items-label,
        &-emailing-items-label {
            display: block;
            font-weight: $msv-font-weight-600;
        }

        &:first-child {
            border-top: none;
        }
    }

    &__groups {
        @include font-content-m();
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__group {

        &-deliveryType-pickUp,
        &-deliveryType-ship,
        &-deliveryType-email {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-delivery {
            &-heading {
                @include font-content-m(var(--msv-font-weight-heavy));
                margin-right: $msv-details-text-margin-right;
                display: block;
                border-top: solid 2px $msv-light-grey;
                padding-top: 15px;
                text-transform: uppercase;
            }

            &-total-items {
                @include font-content-xl();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        font-weight: normal;
        font-size: 14px;
        align-items: center;
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .msc-cart-line {
            display: flex;
            align-items: center;

            &__product {}

            &__product-image {
                margin: 0 25px 0 0;
                display: none;
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
                font-weight: normal;
            }

            &__quantity {
                order: 2;

                .quantity-label {
                    font-size: $msv-font-size-s;
                    line-height: $msv-text-line-height;
                    margin: 3px 0px;
                }

                .quantity-value {
                    color: $msv-gray-500;
                    display: inline;
                    font-size: $msv-font-size-s;
                    margin-left: $msv-details-text-margin-right;
                }
            }

            &__quantity.single-quantity {
                visibility: hidden;
            }

            &-item-product-discount {
                display: none;
                color: var(--msv-success-color);
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-btn();
        }
    }

    .summary-container {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column-reverse;
            padding-top: 20px;
        }

        justify-content: space-between;

        .summary-wrapper,
        .order-details-bannerImage {
            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
            }

            width: calc(50% - 22px);
        }

        .order-details-bannerImage {
            padding-top: 46px;
        }

        .summary-wrapper {
            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                margin: 0 0 14px;
                text-transform: capitalize;
            }

            .ms-order-confirmation__order-summary {
                padding: 0;
                border: 1px solid $msv-soft-peach;
                box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);

                p {
                    padding: 15px 24px;
                    width: 100%;
                    border-bottom: 1px solid $msv-soft-peach;
                    font-size: 16px;
                    line-height: 21px;
                    color: $msv-black;
                    font-weight: 400;

                    .msc-price__actual {
                        font-weight: normal;
                    }

                    &.ms-order-confirmation__order-summary-line-total-amount {
                        border-bottom: 0;
                    }

                    &:nth-last-child(2) {
                        border-bottom: 0;

                        .ms-order-confirmation__order-summary-label,
                        .msc-price__actual {
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }
                }

                .checkout-summary-freight {
                    padding: 0 24px;
                    margin-top: -11px;
                    padding-bottom: 9px;
                }
            }
        }

        .msc_image {
            width: 100%;
            height: auto;
        }
    }

    table {
        width: 100%;
        border: 1px solid $msv-soft-peach;
        box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);
        margin-bottom: 30px;

        th {
            padding: 16px 0px;
            border-bottom: solid 5px $msv-supernova;
            font-size: 14px;
            line-height: 19px;
            background-color: $msv-soft-peach;
            font-weight: 700;
            word-wrap: break-word;
            padding-left: 5px;

            &:nth-child(3) {
                padding-right: 5px;
                text-align: right;
            }

            &:nth-child(4) {
                padding-right: 5px;
                text-align: center;
            }

            .qty {
                text-align: center;
                display: block;
            }

            @media (max-width: $msv-breakpoint-l) {
                min-width: 150px;
            }

            &:first-child {
                padding-left: 21px;
            }

            &:last-child {
                padding-right: 16px;
                text-align: right;
            }
        }

        td {
            padding: 12px 0px 12px 5px;
            font-size: 16px;
            line-height: 21px;
            word-wrap: break-word;
            color: $msv-black;

            .qty {
                text-align: center;
                display: block;
            }

            &:first-child {
                padding-left: 21px;
            }

            &:nth-child(3) {
                padding-right: 5px;
                text-align: right;
            }

            &:nth-child(4) {
                padding-right: 5px;
                text-align: center;
            }

            &:last-child {
                padding-right: 16px;
                text-align: right;
            }
        }
    }

    &__order-summary {
        @include font-content-m();
        padding: $msv-details-default-padding;

        &::after {
            clear: both;
        }

        &-heading {
            display: none;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            padding: 15px 24px;
            width: 100%;
            border-bottom: 1px solid $msv-soft-peach;
            font-size: 16px;
            line-height: 21px;
            color: $msv-black;
            font-weight: 400;
        }

        &-line-subtotal {

            .ms-order-confirmation__order-summary-label,
            .ms-order-confirmation__order-summary-price {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
            }

            .msc-price__actual {
                font-size: 18px;
                font-weight: bold !important;
            }
        }

        &-line-shipping,
        &-line-tax-amount {
            .msc-price__actual {
                font-weight: normal;
            }
        }

        &-line-total-amount {
            padding: 19px 24px;
            width: 100%;

            .ms-order-details__order-summary-label {
                font-weight: 600;
                font-size: 18px;
            }

            .msc-price__actual {
                font-size: 18px;
            }

            .ms-order-confirmation__order-summary-label {
                font-weight: 600;
                font-size: 18px;
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        @include font-content-m();
        padding: $msv-details-default-padding;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        @include font-content-m();
        padding: $msv-details-default-padding;
        width: 50%;
        float: left;
        margin-top: 15px;
        border-top: solid 2px $msv-light-grey;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-space;
        }

        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: 4px;
        }
    }

    &__address {
        font-weight: $msv-font-weight-355;
        margin-top: 6px;

        &-header {
            display: none;
        }

        &-county {
            display: none;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__pickup-date-timeslot {
        padding-top: 30px;
        margin-bottom: 6px;

        &-title {
            font-weight: $msv-font-weight-600;
            padding-bottom: 8px;
        }

        &-content {
            font-weight: $msv-font-weight-355;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @media screen and (min-width: $msv-container-min-width-m) {
        margin: $msv-main-container-margin;

        &__sales-lines {

            &-shipping-items-label,
            &-picking-items-label {
                padding-top: 15px;
                padding-bottom: 5px;
                border-top: solid 2px $msv-light-grey;
                text-transform: uppercase;
                margin-top: 5px;
            }

            &-emailing-items-label {
                margin-top: auto;
            }
        }

        &__address {
            &-phone {
                position: absolute;
            }
        }

        &__groups {
            &>div:nth-child(2n)+div::before {
                content: '';
                display: block;
                width: 200%;
                border-top: 1px solid $msv-gray-900;
            }
        }

        &__payment-methods {
            padding: 15px 0 10px 0;
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {
        &__groups {
            display: block;

            &>div {
                border-top: 1px solid $msv-gray-900;

                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
            }

            &>div:first-of-type {
                border: none;
            }

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;

            &-shipping-items-label,
            &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            padding-right: 0;
            width: 100%;
            position: relative;
            margin-left: 0;
        }

        &__help,
        &__order-summary {
            border-top: 1px solid $msv-gray-900;
        }

        &__order-summary {

            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: 100%;
            }
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;

            @media (max-width: $msv-breakpoint-l) {
                text-align: left;
                margin-top: 20px;
            }
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    @include image($msv-order-empty-image-width);

    .msc-empty_image {
        background-size: 100%;
    }

    .ms-order-confirmation__payment-methods-customer-account-amount {
        margin-left: 5px;
        font-weight: normal;
    }

    .order-confirmation-discount-container {
        padding: 15px 24px;
        width: 100%;
        border-bottom: 1px solid $msv-soft-peach;
        font-size: 16px;
        line-height: 21px;
        color: $msv-shiraz;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
    }

    .order-confirmation_mobile-contianer {
        border: 1px solid $msv-soft-peach !important;
        box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);
        padding: 18px;
        margin-bottom: 16px;

        .item {
            display: flex;
            margin-bottom: 4px;
            font-size: 14px;
        }

        .name {
            font-weight: 600;
            margin-right: 5px;
        }

        .value {
            white-space: normal;
            color: $msv-storm-dust;
        }
    }
}

.ms-order-confirmation .ms-order-confirmation__payment-methods:nth-child(6) {
    border-left: solid 2px $msv-light-grey;
    padding-left: 15px;
    padding-bottom: 0px;
}

.ms-order-confirmation .ms-order-confirmation__payment-methods:nth-child(6) .ms-order-confirmation__payment-methods-customer-account {
    font-weight: 600;
    font-size: 16px;
}

.ms-order-confirmation__sales-line .msc-cart-line__quantity.single-quantity {
    visibility: visible;
}