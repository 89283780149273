@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

.msc-modal {
    .msc-duplicate-items-when-adding-to-order-template-dialog {
        &__dialog {
            &__content {
                display: flex;
                flex-wrap: wrap;
                padding-left: 15px;

                @media screen and (max-width: $msv-breakpoint-m) {
                    padding-left: 0;
                }
            }

            &__description {
                font-weight: 300;
                font-size: 16px;
                line-height: 21px;
            }

            &__footer {
                margin-top: 20px;
                padding: 0 0 25px 0;
            }

            &__update-quantity-button {
                @include primary-btn();
            }

            &__cancel-button {
                @include secondary-btn();
                margin: 0 20px;
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                &__footer {
                    button {
                        width: 100%;
                        margin-left: 0;
                        margin-right: 0;
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .msc-modal__header {
            @media (max-width: $msv-breakpoint-m) {
                padding: 16px 0;
            }

            .msc-modal__title {
                @media (max-width: $msv-breakpoint-m) {
                    padding: 0;
                    font-size: 18px;
                }
            }
        }

        .msc-modal__body {
            @media (max-width: $msv-breakpoint-m) {
                padding: 0;
            }
        }
    }
}