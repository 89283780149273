@import '../00-settings/index.scss';
@import '../01-tools/index.scss';
@import '../02-generic/index.scss';

$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 0;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-add-to-cart {
    @include primary-button();
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}

.msc-modal {

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-add-to-cart {
        &__dialog {
            max-width: $msv-dialog-width;
            margin: 0 auto !important;

            @media (max-width: $msv-breakpoint-mobile) {
                width: calc(100% - 32px);
            }

            .msc-modal__content {
                padding: $msv-dialog-modal-content-padding;
            }

            .msc-modal__footer {
                flex-direction: column;
                padding: 0 16px 16px;
            }

            &__header {
                .msc-modal__title {
                    @include font-content-l();
                    text-transform: uppercase;
                    font-weight: 700;
                    padding-left: 0;
                }

                .msc-modal__close-button {
                    right: 16px;
                    top: 20px;
                }
            }

            &__body {
                @include font-content-l();
                text-align: center;
                padding: 0 16px;
                margin-bottom: 0;

                .msc_image {
                    max-height: 240px;
                    margin-bottom: 16px;
                    height: 240px;
                    pointer-events: none;
                }

                .msc-empty_image {
                    padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
                }
            }

            @media screen and (max-width: $msv-breakpoint-xl) {
                &__body {
                    max-height: 450px;
                }
            }

            &__image {
                margin-right: auto;
                margin-left: auto;
            }

            &__product-title {
                padding: $msv-dialog-modal-content-body-text-padding;
                font-weight: 500;
            }

            &__product-variants {
                @include font-content-m();
                padding: $msv-dialog-modal-content-body-text-padding;
            }

            &__product-price {
                padding: $msv-dialog-modal-content-body-text-padding;
                & ._discounted-wp-price{
                    & .krannich-price{
                        font-weight: normal;
                    }
                }
            }

            &__go-to-cart {
                @include primary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__back-to-shopping {
                @include secondary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            .msc-modal__content {
                @media (max-width: $msv-breakpoint-m) {
                    padding: 0 !important;
                }
            }

            .msc-price__actual {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                line-height: 17px;

                .krannich-price {
                    .inc {
                        font-size: 12px;
                    }
                }

                .wp-price-container {
                    display: flex;

                    ._title {
                        text-transform: uppercase;
                        margin-right: 6px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}