@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $padding in $paddings {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

@mixin basicTruncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin basic-outline-offset($outline-width: 1px, $outline-style: dashed, $outline-color: $black, $outline-offset: -1px) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin vfi {
    &:focus {
        outline: 1px dashed $black;
    }

    @supports (-webkit-touch-callout: none) {
        &:focus {
            border: none;
            outline: 0;
        }
    }
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
    position: $position;

    &:focus {
        outline: 1px dashed $white;

        &:#{$selector} {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            outline: 1px dashed $black;
            outline-offset: $offset;
        }
    }
}

@mixin validation($bg-color, $border, $color) {
    background-color: $bg-color;
    border: 1px solid $border;
    color: $color;
}

@mixin validation-success($bg-color: $msv-gray-300, $border: $msv-gray-900, $color: $msv-gray-900) {
    @include validation($bg-color, $border, $color);
}

@mixin validation-error($bg-color: var(--msv-error-color), $border: var(--msv-error-color), $color: var(--msv-font-secondary-color)) {
    @include validation($bg-color, $border, $color);
}

@mixin divider($direction) {
    border-#{$direction}: 1.5px solid #{$msv-divider-color};
}

@mixin primary-btn() {
    background-color: $msv-black;
    border-radius: 0px;
    border: solid 1px $msv-black;
    color: $msv-white;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 20px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova !important;
        border: solid 1px $msv-supernova;
        color: $msv-dark-junglel;
        text-decoration: none;
    }
    &:disabled,
    &.disabled {
        background-color: $msv-light-grey;
        border-color: $msv-light-grey;
        color: $msv-gunsmoke;
        pointer-events: none;
    }
}

@mixin primary-btn-black() {
    background-color: $msv-black;
    border: solid 1px $msv-black;
    border-radius: 0px;
    color: $msv-white;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 28px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-dark-junglel;
        text-decoration: none;
    }
    &:disabled,
    &.disabled {
        background-color: $msv-light-grey;
        border-color: $msv-light-grey;
        color: $msv-gunsmoke;
        pointer-events: none;
    }
}

@mixin hero-primary-btn() {
    background-color: $msv-white;
    border: solid 1px $msv-white;
    color: $msv-black;
    border-radius: 0px;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    line-height: normal;
    padding: 10px 25px;
    margin: 3px;
    // transition: transform 250ms;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-black;
        text-decoration: none;
        // transform: translate(-10px, -10px);
    }
    &:disabled,
    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

@mixin hero-secondary-btn() {
    background-color: transparent;
    border: solid 1px $msv-white;
    color: $msv-white;
    border-radius: 0px;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    line-height: normal;
    padding: 10px 25px;
    margin: 3px 15px;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-black;
        text-decoration: none;
    }
    &:disabled,
    &.disabled {
        opacity: 0.6;
        pointer-events: none;
        text-decoration: none;
    }
}

@mixin secondary-btn() {
    background-color: $msv-white;
    border: solid 1px $msv-black;
    border-radius: 0px;
    color: $msv-black;
    border-radius: 0px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 15px;
    height: 40px;
    min-width: 150px;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-dark-junglel;
        text-decoration: none;
    }
}

@mixin advisery-btn() {
    background-color: $msv-pale-teal;
    border: solid 1px $msv-pale-teal;
    border-radius: 0px;
    color: $msv-black;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 28px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    min-width: 150px;
    justify-content: center;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-dark-junglel;
        text-decoration: none;
    }
    &:disabled,
    &.disabled {
        background-color: $msv-light-grey;
        border-color: $msv-light-grey;
        color: $msv-gunsmoke;
        pointer-events: none;
        text-decoration: none;
    }
}

@mixin promo-btn() {
    background-color: $msv-supernova;
    border: solid 1px $msv-supernova;
    border-radius: 0px;
    color: $msv-black;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 28px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus,
    &:active {
        background-color: $msv-supernova;
        border: solid 1px $msv-supernova;
        color: $msv-dark-junglel;
        text-decoration: none;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        background-color: $msv-light-grey;
        border-color: $msv-light-grey;
        color: $msv-gunsmoke;
        pointer-events: none;
        text-decoration: none;
    }
}

@mixin msc-alert-error() {
    color: $msv-shiraz;
    font-size: 14px;
    font-weight: normal;
    background-color: transparent;
    margin: 10px 0px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    padding-left: 20px;
}

.alert-text-message {
    @include msc-alert-error();
    margin: 0;
    text-align: left;
}

@mixin tertiary-btn() {
    background-color: transparent;
    border-radius: 0px;
    color: $msv-smalt-blue;
    border: none;
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
    height: auto;
    line-height: 24px;
    padding: 7px 15px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:active {
        background-color: transparent;
        color: $msv-smalt-blue;
        border: none;
    }

    &:hover {
        color: $msv-supernova;
        text-decoration: underline;
        box-shadow: none;
        background-color: transparent;
        border: none;
    }
    &:disabled,
    &.disabled {
        background-color: transparent;

        color: $msv-light-grey;
        pointer-events: none;
        text-decoration: none;
    }
}
