.company-info {
    margin-top: 80px;
    margin-bottom: 50px;
    .ms-content-block__text {
        max-width: 1078px;
    }
    .ms-text-block {
        max-width: 1078px;
    }
    h2 {
        margin: 0 0 50px;
        font-weight: 700;
        font-size: 60px;
        line-height: 80px;
        color: $msv-supernova;
        .grey {
            color: $msv-light-grey;
            @media (max-width: $msv-breakpoint-m) {
                display: block;
            }
        }
        .dark {
            color: $msv-storm-dust;
        }
    }
    p {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
    }
}
.after-sales-services {
    background: $alabaster-2;
    padding: 50px 0;
    .ms-content-block {
        display: flex;
        max-width: 1300px;
        margin: 0 auto;
        .ms-content-block__title {
            font-weight: 600;
            line-height: 24px;
            margin: 0 0 9px;
            color: $msv-storm-dust;
        }
        h2 {
            margin: 0 0 24px;
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;
        }
        p {
            color: $msv-storm-dust;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
        }
    }
}

.company-expertise {
    @media (max-width: $msv-breakpoint-m) {
        display: block;
        padding: 50px 0px 70px 0px;
    }
    padding: 115px 0px 70px 0px;
    .ms-content-block__title {
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 9px;
        color: $msv-storm-dust;
    }
    h2 {
        margin: 0 0 24px;
        font-weight: 700;
        font-size: 40px;
        line-height: 53px;
    }
    p {
        color: $msv-storm-dust;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
        padding-right: 50px;
    }
    display: flex;
    align-items: center;

    .ms-content-block__image {
        width: 55%;
        order: 2;
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
        img {
            height: auto;
            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 50px;
            }
        }
    }
    .ms-content-block__details {
        width: 45%;
        order: 1;
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }
}

.company-energy {
    background: $alabaster-2;
    padding: 50px 0;
    margin-bottom: 70px;
    .ms-content-block {
        display: flex;
        align-items: center;
        max-width: 1300px;
        margin: 0 auto;
        @media (max-width: $msv-breakpoint-m) {
            display: block;
        }

        .ms-content-block__title {
            font-weight: 600;
            line-height: 24px;
            margin: 0 0 9px;
            color: $msv-storm-dust;
        }
        h2 {
            margin: 0 0 24px;
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;
        }
        p {
            color: $msv-storm-dust;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
        }
    }

    .ms-content-block__details {
        width: 45%;
        padding-left: 40px;
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .ms-content-block__image {
        width: 55%;
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
        img {
            height: auto;
            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 50px;
            }
        }
    }
    .msc-cta__primary {
        @include primary-btn();
    }
}

.newsroom-box {
    @media (max-width: $msv-breakpoint-m) {
        padding: 20px;
        margin-right: 0px;
    }
    background: $alabaster-2;
    padding: 40px;
    margin-right: 20px;
    .msc-cta__primary {
        @include primary-btn();
        min-width: 275px;
    }

    .ms-content-block__title {
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 9px;
        color: $msv-storm-dust;
    }
    h2 {
        margin: 0 0 24px;
        font-weight: 700;
        font-size: 32px;
        line-height: 53px;
    }
    p {
        color: $msv-storm-dust;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
        min-height: 290px;
    }
}

.branch-box {
    margin-left: 20px;
    margin-right: 0px;
    @media (max-width: $msv-breakpoint-m) {
        margin-left: 0px;
        margin-top: 20px;
    }
}

.have-question {
    img {
        height: auto;
    }
    p {
        color: $msv-storm-dust;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
    }
    .have-question-txt {
        @media (max-width: $msv-breakpoint-m) {
            padding: 25px;
        }
        a {
            @include tertiary-btn();
            font-size: 36px;
            font-weight: 700;
            padding: 0px;
            margin: 0px;
            &:focus,
            &:active {
                font-size: 36px;
                font-weight: 700;
                padding: 0px;
                margin: 0px;
            }
        }
        padding-right: 25px;
        .msc-cta__primary {
            @include primary-btn();
        }
    }
    h3 {
        font-size: 36px;
        line-height: 48px;
    }
    h2 {
        position: absolute;
        top: 55%;
        left: 46px;
        font-size: 44px;
        width: 200px;
    }
    margin-top: 70px;
    .row {
        background-color: #f8f8f8;
        align-items: center;
    }

    .ms-content-block__details {
        left: 100px !important;
    }
}
