/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: $msv-primary-font-family;
    background: $msv-white;
    color: var(--msv-font-primary-color);
    font-size: var(--msv-body-font-size-m);

    @include fabrikam-font();
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles and default padding on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
    padding: 0;
}

a {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--msv-font-primary-color);
    display: inline-flex;
    text-decoration: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

a:hover {
    text-decoration: underline;
    color: $msv-black;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
    pointer-events: none;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
    * {
        /* stylelint-disable declaration-no-important -- Auto-suppressed. Please fix if possible. */
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
        /* stylelint-enable declaration-no-important -- Auto-suppressed. Please fix if possible. */
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
// TODO: remove in v5
// stylelint-disable-next-line selector-list-comma-newline-after
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}

// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
}

abbr[title],
abbr[data-original-title] {
    // 1
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
    cursor: help; // 3
    border-bottom: 0; // 4
    text-decoration-skip-ink: none; // 5
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0; // Undo browser default
}

blockquote {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none; // Remove the border on images inside links in IE 10-.
}

svg {
    // Workaround for the SVG overflow bug in IE10/11 is still required.
    // See https://github.com/twbs/bootstrap/issues/26878
    overflow: hidden;
    vertical-align: middle;
}

//
// Tables
//

table {
    border-collapse: collapse; // Prevent double borders
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
    text-align: inherit; // 2
    text-align: -webkit-match-parent; // 3
}

//
// Forms
//

label {
    // Allow labels to use `margin` for spacing.
    display: inline-block;
    margin-bottom: 0.5rem;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093
button {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}

button,
select {
    text-transform: none; // Remove the inheritance of text transform in Firefox
}

// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role="button"] {
    cursor: pointer;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990
select {
    word-wrap: normal;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. Remove the padding in IE 10-
}

textarea {
    overflow: auto; // Remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: none;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
    display: block;
    width: 100%;
    max-width: 100%; // 1
    padding: 0;
    margin-bottom: 0.5rem;
    @include font-size(1.5rem);
    line-height: inherit;
    color: inherit; // 2
    white-space: normal; // 1
}

progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
}

//
// Remove the inner padding in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
}

//
// Correct element displays
//

output {
    display: inline-block;
}

summary {
    display: list-item; // Add the correct display in all browsers
    cursor: pointer;
}

template {
    display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
    display: none !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.back-to-top {
    display: none !important;
    font-size: 0;
    z-index: 10000000;
    &.ms-back-to-top {
        display: none !important;
    }

    .ms-accordion-header-section__heading {
        display: none;
    }
    &::before {
        @include msv-icon();
        content: $msv-ChevronUp;
        font-size: 20px;
        margin-top: 11px;
    }
}

.non-sticky-header {
    .back-to-top {
        display: block !important;
        &.ms-back-to-top {
            display: block !important;
        }
    }

    &>div>header {
        position: static;
    }
}
