$msv-checkout-customer-account-max-flex-basis: 100%;
$msv-checkout-customer-account-padding-bottom: 20px;
$msv-checkout-customer-account-input-label-margin-bottom: 10px;
$msv-checkout-customer-account-button-border-radius: 2px;
$msv-checkout-customer-account-button-margin-left: 5px;
$msv-checkout-customer-account-button-margin-top: 20px;
$msv-checkout-customer-account-input-height: 32px;
$msv-checkout-customer-account-input-border-radius: 2px;
$msv-checkout-customer-account-input-padding: 6px 8px;
$msv-checkout-customer-account-input-width: 40%;
$msv-checkout-customer-account-error-icon-margin-right: 8px;
$msv-checkout-customer-account-input-max-width: 610px;
$msv-checkout-customer-account-input-fields-padding-right: 20px;
$msv-checkout-customer-account-input-fields-min-width: 130px;
$msv-checkout-customer-account-input-fields-width: 25%;
$msv-checkout-customer-account-input-num-fields-width: 50%;
$msv-checkout-customer-account-border-bottom: 1px dashed $msv-divider-color;
$msv-checkout-customer-account-title-margin-bottom: 8px;

//style presets
:root {
    --msv-checkout-customer-account-font-size: var(--msv-body-font-size-l);
    --msv-checkout-customer-account-font-color: var(--msv-font-primary-color);

    // title
    --msv-checkout-customer-account-title-font-size: var(--msv-body-font-size-s);

    // form
    --msv-checkout-customer-account-form-bg: #{$msv-white};
    --msv-checkout-customer-account-form-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-checkout-customer-account-btn-bg: var(--msv-accent-brand-color);
    --msv-checkout-customer-account-btn-font-color: var(--msv-font-secondary-color);
    --msv-checkout-customer-account-btn-border: var(--msv-accent-brand-color);
    --msv-checkout-customer-account-btn-font-size: var(--msv-body-font-size-m);
}

.ms-checkout-customer-account {
    //padding-bottom: $msv-checkout-customer-account-padding-bottom;

    &__form {
        flex-flow: row wrap;
    }

    &__title {
        display: block;
        font-size: var(--msv-checkout-customer-account-title-font-size);
        font-weight: var(--msv-font-weight-bold);
        line-height: $msv-line-height-s;
        margin-bottom: $msv-checkout-customer-account-title-margin-bottom;
    }

    &__input-label {
        line-height: 24px;
        color: var(--msv-checkout-customer-account-font-color);
        display: inline-block;
        flex-basis: $msv-checkout-customer-account-max-flex-basis;
        margin-top: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__input-amount {
        @include form-input-el(var(--msv-checkout-customer-account-form-bg), var(--msv-checkout-customer-account-form-font-color));
        border: 1px solid $msv-supernova;
        border-radius: 2px;
        height: 40px;
        font-size: 14px;
        padding-left: 16px;
        margin-bottom: 16px;
        width: auto;
        display: inline-block;
        border: 0;
        margin: 0;
    }

    &__input-alert-label {
        @include form-input-alert-message();
        display: block;
    }

    &__btn-pay {
        @include primary-btn();
        font-size: 13px;
        // margin-left: $msv-checkout-customer-account-button-margin-left;
        display: inline;
    }

    &__input-error {
        @include form-input-error-message();
        width: 100%;
        display: block;
    }

    &__account-name {
        padding-top: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        display: inline-block;
        margin-right: 8px;
    }
    &__account-number {
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        padding-top: 0;
    }

    &__customer-since {
        padding-bottom: 15px;
        font-size: var(--msv-body-font-size-m);
        font-size: 14px;
        font-weight: 500;
        color: $msv-smalt-blue;
    }

    &__account-credit {
        font-weight: 700;
        padding-bottom: 15px;
        &-label {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            background-color: $concrete;
            padding: 6px 5px 5.5px 10px;
        }
        &-toggle-down,
        &-toggle-up {
            background-color: $concrete;
            padding: 6px 10px 5px 0px;
        }
    }

    &__bottom-border {
        //padding-top: 20px;
        width: 100%;
        //border-bottom: 1px dashed rgb(209, 209, 209);
    }

    &__input-fields {
        display: inline-block;
        flex-grow: 1;
        width: 100%;
    }

    &__btn-apply {
        margin-left: $msv-checkout-customer-account-button-margin-left;
        margin-top: $msv-checkout-customer-account-button-margin-top;
        width: auto;
    }

    &__account-credit-toggle-down {
        @include add-icon($msv-ChevronDown, after);
        font-size: 14px;
    }

    &__account-credit-toggle-up {
        @include add-icon($msv-ChevronUp, after);
        font-size: 14px;
    }

    &__credit-details-section {
        width: 100%;
        border-bottom: 1px solid $msv-gray-300;
        background-color: $concrete;
        width: 100%;
        padding: 10px 50px 20px 10px;
    }

    &__account-credit-current-order-preview {
        margin-bottom: 12px;
    }

    &__account-credit-current-order-icon {
        @include add-icon($msv-ListAlt, after);
        color: $msv-supernova;
        margin-right: 5px;
        display: none;
    }
    &__account-credit-current-order-preview-label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
    }

    &__available-credit {
        float: right;
        font-weight: 700;
        color: $msv-smalt-blue;
        &-label {
            font-size: 14px;
        }
        &-section {
            border-bottom: solid 1px $msv-light-grey;
            padding: 10px 0;
        }
    }

    &__excess-credit {
        float: right;
        font-weight: 700;
        color: $msv-smalt-blue;
        &-label {
            font-size: 14px;
        }
        &-section {
            padding: 10px 0 0 0;
        }
    }

    &__order-total {
        float: right;
        font-weight: 700;
        color: $msv-smalt-blue;
        &-label {
            font-size: 14px;
        }
        &-section {
            border-bottom: solid 1px $msv-light-grey;
            padding: 10px 0;
        }
    }

    &__applied-line {
        margin-top: 15px;
        width: 75%;
    }

    &__applied-amount {
        margin-left: 0;
        color: $msv-smalt-blue;
        font-weight: 600;
    }

    &__remove {
        float: right;
        margin-left: auto;
        color: $msv-shiraz;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &.msc-btn {
            margin-left: 0;
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        &__input-text {
            width: auto;
            flex-grow: 1;
            max-width: $msv-checkout-customer-account-input-max-width;
        }
    }
    .applied-line-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .amount-applied-contianer {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;
        // .applied-amount-label {
        //     margin-right: 5px;
        // }
    }
    .ms-checkout-customer-account__applied-line {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 500;
        .ms-checkout-customer-account__applied-label {
            margin-right: 5px;
        }
    }
    .total-limit-container,
    .apply-btn-info {
        font-size: 14px;
        font-weight: 500;
    }
    .total-limit-container {
        .total-limit-amount {
            color: $msv-smalt-blue;
        }
    }
    .apply-btn-info {
        margin-bottom: 16px;
    }
    #ms-checkout-customer-account__amount {
        margin: 0 8px;
        font-size: 14px;
        font-weight: 500;
    }
}
