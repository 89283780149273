$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 32px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 0px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: var(--msv-body-font-size-l);
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: var(--msv-font-primary-color);
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-order-summary {
    &__label {
        font-size: 16px;
    }

    &-wrapper {
        align-items: center;

        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;
        padding: 30px 30px 0 30px;
        height: fit-content;
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-heavy), var(--msv-order-summary-heading-font-size), $msv-line-height-xl);
        color: $msv-storm-dust;
        font-style: $msv-order-summary-font;
        padding-bottom: $msv-order-summary-heading-padding-bottom;
        width: 100%;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__items {
        color: var(--msv-order-summary-font-color);
        border-radius: $msv-order-summary-items-border-radius;

        @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
        width: 100%;

        .msc-promo-code {
            &__group {
                display: flex;
                padding-bottom: 0px;

                .msc-promo-code__apply-btn {
                    @media (max-width: $msv-breakpoint-m) {
                        width: 100%;
                    }
                }

                @media (max-width: $msv-breakpoint-m) {
                    flex-wrap: wrap;
                }
            }

            &__input-box {
                flex: auto;
                margin-right: 10px;
                padding-left: 16px;
                height: 40px;
                border-color: $msv-light-grey;
                min-width: 210px;
                font-size: 14px;

                @media (max-width: $msv-breakpoint-m) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            &__form-container {
                &+.msc-alert-danger {
                    line-height: 1;
                    display: inherit;
                }
            }
        }

        p {
            display: flex;
            justify-content: space-between;
    
            .msc-order-summary__value {
                float: none;
                flex-grow: 1;
            }
    
            .tooltip-wrapper {
                margin-left: 4px;
                margin-top: 1px;
                margin-right: -20px;
            }

            &:not([class]) {
                padding-top: 10px;
            }
        }

        .price-with-tooltip {
            display: flex;
            align-items: baseline;

            .msc-order-summary__line-sub-total {
                flex-grow: 1;
            }

            .tooltip-wrapper {
                margin-left: 4px;
                margin-top: 1px;
                margin-right: -20px;
            }

        }
    }

    &__line {

        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges {
            padding: 10px 0px;
        }

        &-shipping {
            display: flex;
            justify-content: space-between;

            .msc-order-summary__value {
                float: none;
                flex-grow: 1;
            }

            .tooltip-wrapper {
                margin-left: 4px;
                margin-top: 1px;
                margin-right: -20px;
            }
        }

        &-total-discounts {
            padding: 10px 0px;
            text-transform: capitalize;
            color: $msv-shiraz;

            .msc-price__actual {
                color: $msv-shiraz;
            }
        }

        &-sub-total {
            font-size: 16px;
            padding-top: 20px;
            text-transform: uppercase;

            .msc-order-summary__label {
                font-weight: 600;
            }

            .msc-order-summary__value .msc-price__actual {
                font-weight: 600;
            }

            .tooltip-wrapper {
                margin-left: 4px;
                margin-top: 1px;
                margin-right: -20px;
            }
        }

        &-total {
            @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
            padding: 20px 30px 0 30px;
            margin-bottom: 0px;
            margin-top: 20px;
            border-top: 1px solid $msv-light-grey;
            text-transform: uppercase;
            position: relative;
            left: -30px;
            width: 400px;
            bottom: 15px;

            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
                position: static;
                padding-left: 0;
                padding-right: 0;
            }

            .msc-order-summary__label {
                font-weight: 600;
            }

            .msc-order-summary__value .msc-price__actual {
                font-weight: 600;
            }
        }
    }

    &__value {
        float: right;
        text-align: right;
        font-weight: normal;
        font-size: 16px;
        text-transform: capitalize;

        .msc-price__actual {
            font-weight: normal;
        }
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        margin: 0;
    }
}

.total_before_tax_value {
    float: right;
    text-align: right;
    font-size: 16px;
    text-transform: capitalize;
}

.total_before_tax_label {
    font-size: 16px;
}