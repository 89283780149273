$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 32px;
$msv-checkout-guest-profile-input-label-width: 100%;
$msv-checkout-guest-profile-input-text-max-width: 610px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
    &__add-contact-info {
        width: 100%;
    }

    &__input {
        display: flex;
        flex-wrap: wrap;
    }

    &__selected-email {
        @include font-content-m(500);
        font-size: 14px;
        // display: none;
    }

    &__input-text {
        @include vfi();
        @include form-input-el($msv-white);
        display: block;
        flex-grow: 1;
        height: 40px;
        padding-left: 16px;
        max-width: $msv-checkout-guest-profile-input-text-max-width;
        border: 1px solid $msv-supernova;
        border-radius: 2px;
        font-size: 14px;
    }

    &__input-label {
        @include font-content-l();
        width: $msv-checkout-guest-profile-input-label-width;
        margin-bottom: $msv-checkout-guest-profile-input-margin-top;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__input-error {
        @include form-input-error-message();
        display: block;
        width: 100%;
    }
}
