$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 20px;
$search-result-container-nav-margin-top: 32px;
$search-result-container-nav-title-margin: 0;
$search-result-container-refine-product-margin-bottom: 0px;
$search-result-container-refiner-section-padding-right: 40px;
$search-result-container-refiner-section-width: 25%;
$search-result-container-refiner-button-submenu-height: 64px;
$search-result-container-refiner-button-submenu-padding-left: 0;
$search-result-container-refiner-button-submenu-padding-right: 0;
$search-result-container-refiner-button-submenu-padding-top: 6px;
$search-result-container-refiner-button-submenu-padding-bottom: 6px;
$search-result-container-refiner-submenu-list-group-border-color: $msv-gray-900;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0;
$search-result-container-refiner-submenu-list-group-margin-left: 0;
$search-result-container-refiner-submenu-item-label-padding-left: 12px;
$search-result-container-refiner-submenu-list-group-item-padding-left: 0;
$search-result-container-refiner-list-group-item-border: 1px solid rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 21px 12px 0 12px;
$search-result-container-refiner-slider-horizontal-background-color: $msv-gray-300;
$search-result-container-refiner-slider-horizontal-height: 4px;
$search-result-container-refiner-slider-padding: 0;
$search-result-container-refiner-slider--track-top: 0;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 6px;
$search-result-container-refiner-slider-thumb-height: 12px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 12px;
$search-result-container-refiner-slider-thumb-before-bottom: 0;
$search-result-container-refiner-slider-thumb-before-left: 0;
$search-result-container-refiner-slider-thumb-before-right: 0;
$search-result-container-refiner-slider-thumb-before-top: 0;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;
$search-result-container-pagination-padding-left: 0;
$search-result-container-product-section-width: 75%;
$search-result-container-sort-by-category-padding-left: 8px;
$search-result-container-sort-by-category-right: 0;
$search-result-container-sort-by-category-dropdown-label-margin-right: 8px;
$search-result-container-products-margin-bottom: 80px;
$search-result-container-products-margin-top: 32px;
$search-result-container-product-search-result-item-grid-column-gap: 20px;
$search-result-container-product-search-result-item-width: 220px;
$search-result-container-product-search-result-item-padding: 0 0 33px 0;
$search-result-container-product-search-item-title-margin-bottom: 8px;
$search-result-container-product-search-item-title-margin-top: 20px;
$search-result-container-product-search-item-rating-margin-top: 4px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-page-link-padding: 8px 4px;
$search-result-container-page-item-page-link-line-height: 1.25;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-margin-top-margin-top-mobile: 24px;
$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;
$search-result-container-choice-summary-padding-inline-start: 0;
$search-result-container-choice-summary-margin-left: 8px;
$search-result-container-choice-summary-margin-top: 20px;
$search-result-container-choice-summary-margin-bottom: 16px;
$search-result-container-choice-summary-background-color: $msv-gray-200;
$search-result-container-choice-summary-border: 1px solid $msv-gray-200;
$search-result-container-choice-summary-margin-right: 12px;
$search-result-container-choice-summary-border-radius: 3px;
$search-result-container-choice-summary-padding-left: 8px;
$search-result-container-choice-summary-outline-offset: 2px;
$search-result-container-choice-summary-clear-all-background-color: $msv-gray-100;
$search-result-container-choice-summary-clear-all-border: 0;
$search-result-container-choice-summary-clear-all-outline-offset: 2px;
$search-result-container-choice-summary-glypg-margin-left: 8px;
$search-result-container-choice-summary-glypg-margin-right: 8px;
$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;
$search-result-modal-body-height: 450px;
$search-result-modal-content-padding: 20px 25px;
$search-result-modal-footer-button-background-color: $msv-black;
$search-result-modal-footer-button-border-color: $msv-black;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-modal-refiner-section-padding-right: 0;
$search-result-container-padding-left-mobile: 16px;
$search-result-container-padding-right-mobile: 16px;
$search-result-container-sort-by-category-top-mobile: 0;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset: 2px;
$search-result-prev-next-padding: 0 4px;
$search-result-container-feature-placeholder-img-width: 315px;
$search-result-list-item-width: 250px;

//style presets
:root {
    --msv-search-result-font-color: var(--msv-font-primary-color);
    --msv-search-result-font-size: var(--msv-body-font-size-m);
    --msv-search-result-heading-font-color: var(--msv-font-primary-color);
    --msv-search-result-heading-font-size: var(--msv-body-font-size-xl);
    --msv-search-result-sort-bg: transparent;
    --msv-search-result-sort-font-color: var(--msv-font-primary-color);
    --msv-search-result-sort-font-size: var(--msv-body-font-size-m);
    --msv-search-result-product-color: var(--msv-font-primary-color);
    --msv-search-result-product-size: var(--msv-body-font-size-m);
    --msv-search-result-product-price-color: var(--msv-font-primary-color);
    --msv-search-result-product-price-size: var(--msv-body-font-size-l);
    --msv-search-result-pagination-color: var(--msv-font-primary-color);
    --msv-search-result-pagination-size: var(--msv-body-font-size-m);

    //Refiner heading
    --msv-refiner-heading-font-color: var(--msv-font-primary-color);
    --msv-refiner-heading-font-size: var(--msv-body-font-size-l);

    //Refiner values
    --msv-refiner-values-font-color: var(--msv-font-primary-color);
    --msv-refiner-values-font-size: var(--msv-body-font-size-m);

    //border
    --msv-refiner-border: #{$msv-gray-300};

    // slider
    --msv-refiner-slider: var(--msv-font-primary-color);

    // rating star
    --msv-refiner-rating-star: var(--msv-font-primary-color);
    --msv-refiner-rating-star-size: var(--msv-body-font-size-m);
    --msv-feature-title-color: var(--msv-font-primary-color);
    --msv-feature-title-desktop-size: var(--msv-body-font-size-xxl);
    --msv-feature-title-mobile-size: var(--msv-body-font-size-xl);
    --msv-feature-heading-color: var(--msv-font-primary-color);
    --msv-feature-heading-font-size: var(--msv-body-font-size-l);
}

.ms-search-result-container__category-nav-section {
    display: flex;
    height: $search-result-container-nav-height;
    justify-content: space-between;
    margin-bottom: 35px;
    margin-top: 10px;

    .ms-search-result-container__category-hierarchy {
        @include font-content-s();
    }

    .ms-search-result-container__title h5 {
        margin: $search-result-container-nav-title-margin;

        .ms-search-result__collection-title-count {
            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $msv-line-height-m);
        }
    }
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    margin-bottom: $search-result-container-refine-product-margin-bottom;
    width: 100%;

    // duplicate heading hided
    .ms-search-result-container__title {
        h2 {
            display: none;
        }
    }
}

// refiner section
.ms-search-result-container__refiner-section {
    display: block;
    padding-right: 5px;
    width: $search-result-container-refiner-section-width;

    .collapse:not(.show) {
        display: none;
    }

    button.ms-refine-submenu__toggle_collapsed {
        background: transparent;
        color: $msv-smalt-blue;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 0px;
        text-align: left;
        height: auto;

        &::after {
            color: $msv-supernova;
        }
    }

    .ms-refine-submenu__toggle_expanded {
        background: transparent;
        color: $msv-smalt-blue;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 0px;
        text-align: left;
        height: auto;

        &::after {
            color: $msv-supernova;
        }
    }

    .ms-refine-submenu.list-group {
        border: solid 1px $msv-soft-peach;
        display: flex;
        flex-direction: column;
        margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
        padding: 10px;
        border-radius: 0px;

        .ms-refine-submenu-item {
            padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
            margin-bottom: 0;
            line-height: 1.5;

            >a {
                @include vfi();

                &:focus {
                    outline-offset: -2px;
                }
            }

            .msc-rating {
                display: inline-block;

                &__count {
                    color: var(--msv-refiner-values-font-color);
                }

                &__empty-star,
                &__star,
                &__count {
                    font-size: var(--msv-refiner-rating-star-size);
                }
            }

            .ms-rating-component {
                &__up .msc-rating__star {
                    color: $msv-gray-500;
                }

                &__current .msc-rating__star {
                    color: $msv-gray-900;
                }

                &__down .msc-rating__star {
                    color: $msv-gray-800;
                }
            }
        }

        .refine-submenu-item__rating {
            color: var(--msv-refiner-values-font-color);
            font-size: var(--msv-refiner-rating-star-size);
        }

        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            @include vfi();
            display: block;
            border: 1px solid transparent;

            span.ms-refine-submenu-item__label {
                font-size: 14px;
                padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
                vertical-align: middle;
                font-weight: 500;
                color: $msv-storm-dust;
            }
        }
    }

    .list-group-item {
        border: $search-result-container-refiner-list-group-item-border;
        display: block;
        outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
        padding: $search-result-container-refiner-list-group-item-padding;
        position: relative;
    }

    .slider-horizontal {
        background-color: $search-result-container-refiner-slider-horizontal-background-color;
        height: $search-result-container-refiner-slider-horizontal-height;
    }

    .slider {
        cursor: pointer;
        display: block;
        padding: $search-result-container-refiner-slider-padding;
        position: relative;
        touch-action: none;

        .slider__container {
            height: 100%;
            width: 100%;
        }

        .slider__track {
            background-color: var(--msv-refiner-slider);
            height: 100%;
            position: absolute;
            top: $search-result-container-refiner-slider--track-top;
        }

        .slider__thumb {
            @include vfi();
            background: var(--msv-refiner-slider);
            background-size: $search-result-container-refiner-slider-thumb-background-size;
            border-radius: $search-result-container-refiner-slider-thumb-border-radius;
            display: inline-block;
            height: $search-result-container-refiner-slider-thumb-height;
            outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
            position: absolute;
            top: $search-result-container-refiner-slider-thumb-top;
            transform: translate3d(-50%, -50%, 0);
            width: $search-result-container-refiner-slider-thumb-width;
        }

        .slider__thumb::before {
            bottom: $search-result-container-refiner-slider-thumb-before-bottom;
            content: '';
            display: block;
            left: $search-result-container-refiner-slider-thumb-before-left;
            outline-offset: calc(0px + 0.0625rem);
            position: absolute;
            right: $search-result-container-refiner-slider-thumb-before-right;
            top: $search-result-container-refiner-slider-thumb-before-top;
        }

        .slider__thumb:focus {
            outline: 1px dashed $black;
            border: 1px dashed $white;
            outline-offset: 2px;
            box-shadow: none;
        }

        &__thumb__range:focus {
            +.slider__thumb {
                outline: 1px dashed $black;
                border: 1px dashed $white;
                outline-offset: 2px;
                box-shadow: none;
            }
        }

        .slider__labels-item {
            color: var(--msv-refiner-values-font-color);
            font-size: var(--msv-refiner-values-font-size);
            cursor: pointer;
            display: inline-block;
            margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
            padding-bottom: $search-result-container-refiner-slider-labels-item-padding-bottom;
        }

        .slider__labels-item.start {
            float: left;
        }

        .slider__labels-item.end {
            float: right;
        }
    }
}

// product section
.ms-search-result-container__product-section {
    width: $search-result-container-product-section-width;

    .msc-waiting {
        margin: 108px auto;
        display: block;
    }

    .ms-search-result-wrapper-title-choice-summary {
        flex: 0 0 75%;
        max-width: 75%;
        display: inline-block;
        position: relative;
        width: 100%;

        .ms-search-result-container__title h2 {
            color: var(--msv-search-result-font-color);
            font-size: var(--msv-search-result-font-size);
            .ms-search-result__collection-title-text {
                @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-heading-font-size), $msv-line-height-xl);
                color: var(--msv-search-result-heading-font-color);
            }

            .ms-search-result__collection-title-count {
                @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $msv-line-height-m);
                color: var(--msv-search-result-font-color);
                display: inline-block;
            }
        }
    }

    .ms-search-result-wrapper-sort-by-category {
        flex: 0 0 25%;
        max-width: 25%;
        display: inline-block;
        position: relative;
        width: 100%;
    }

    .ms-Spinner {
        height: 250px;

        .ms-Spinner-circle {
            width: 50px;
            height: 50px;
        }

        .ms-Spinner-label {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.msc-choice-summary {
    @media (max-width: $msv-breakpoint-l) {
        margin-bottom: 15px;
    }

    list-style-type: none;
    padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
    margin-top: 18px;

    .msc-choice-summary__list {
        display: inline-block;

        @media (max-width: $msv-breakpoint-m) {
            display: block;
            margin-bottom: 10px;
        }
    }

    .msc-choice-summary__list-item {
        display: inline-block;
    }

    .msc-choice-summary__item {
        @include vfi();
        @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $msv-line-height-m);

        color: var(--msv-refiner-values-font-color);

        outline-offset: $search-result-container-choice-summary-outline-offset;
        position: relative;
        font-size: 12px;
        background-color: $msv-supernova;
        border-radius: 0px;
        padding: 5px 10px;
        font-weight: 600;
        margin-right: 10px;
        line-height: 20px;

        &:hover {
            text-decoration: none;
        }
    }

    .msc-choice-summary__clear-all {
        display: inline-block;
        position: relative;
        @include secondary-btn();
        padding: 3px 12px;
        font-size: 12px;
        height: 31px;
        min-width: auto;
        background-color: $msv-light-grey;
        border: none !important;
        text-transform: capitalize;
        font-weight: 600;

        &:focus,
        &:active,
        &:hover {
            border: none !important;
        }
    }

    .msc-choice-summary__glyph {
        margin-left: $search-result-container-choice-summary-glypg-margin-left;
        margin-right: $search-result-container-choice-summary-glypg-margin-right;
        vertical-align: text-top;

        &::after {
            @include msv-icon();
            content: $msv-Cancel;
        }
    }

    .msc-choice-summary__clear-all::after {
        display: none;
    }
}

.ms-search-result-container__sort-view {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $msv-breakpoint-m) {
        justify-content: flex-start;
    }
}

.ms-search-result-container__Sort-by-category {
    @include font-content(var(--msv-font-weight-light), var(--msv-search-result-sort-font-size), $msv-line-height-m);
    padding-left: $search-result-container-sort-by-category-padding-left;
    right: $search-result-container-sort-by-category-right;
    bottom: 0;
    padding-top: 12px;

    @media (max-width: $msv-breakpoint-m) {
        position: unset !important;
        padding-left: 0px;
    }

    .msc-l-dropdown__label {
        margin: 8px;
        vertical-align: middle;
        text-transform: uppercase;
        color: $msv-storm-dust;
        font-size: 16px;
        font-weight: 500;

        @media (max-width: $msv-breakpoint-m) {
            margin-left: 0px;
            font-weight: 700;
            font-size: 18px;
        }

    }

    .msc-l-dropdown {
        border: solid 1px $msv-soft-peach;
        border-radius: 0px;
        margin-right: 10px;
        font-weight: normal;
        padding-left: 5px;
        font-size: 14px;
        padding: 0px 30px 0px 10px;
        background-position: bottom 8px right 10px !important;
        height: 29px;
        margin-top: 2px;
        color: $msv-black;
    }
}

.msc-product__text {
    display: none;
}

.manufacturer-title {
    text-transform: uppercase;
    padding-right: 3px;
}

.ms-search-result-container__Products {
    margin-bottom: $search-result-container-products-margin-bottom;
    margin-top: 20px;

    .ms-search-result-container__no-results-message {
        h5.error-text {
            @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $msv-line-height-m);
        }
    }

    .list-unstyled {
        display: grid;
        grid-column-gap: $search-result-container-product-search-result-item-grid-column-gap;
        grid-template-columns: repeat(auto-fill, minmax($search-result-container-product-search-result-item-width, 1fr));

        @media(max-width: $msv-breakpoint-m) {
            grid-template-columns: repeat(1, minmax($search-result-container-product-search-result-item-width, 1fr));
        }

        .product-info__detail-btn {
            display: none;
        }
    }

    .msc-product__image {
        padding: 0 8px;
    }
}

.ms-product-search-result__item {

    .msc-product__details {
        text-align: left;
        padding: 15px 15px 0px 15px;
        min-height: 100px;
        text-transform: uppercase;
        margin: 0;
    }

    .producer__ImageURI {
        min-height: 50px;
    }

    .lights-section {
        padding: 0 15px;
        display: flex;
        align-items: center;
        min-height: 35px;

        ._traffic-light-quantity {
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            text-align: left;
        }

        .traffic-light-container {
            margin-right: 8px;
            padding: 0;
        }

        .ms-buybox__minmax_label_text {
            margin-left: -8px;
            margin-top: 4px;
        }
    }

    .msc-product {
        display: block;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 0px;
            border: none;
            color: $msv-smalt-blue;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding-left: 0px;
            border: none;
            color: $msv-smalt-blue;
        }

        &__title,
        &__details {
            color: var(--msv-search-result-font-color);
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .msc-rating {

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            &__star {
                color: var(--msv-refiner-rating-star);
            }

            &__half-star {
                &::after {
                    color: var(--msv-refiner-rating-star);
                }
            }
        }

        @include image(312px);

        img {
            pointer-events: none;
        }
    }

    .msc-product__image img {
        width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
        .ms-quickView {
            &__button {
                display: block;
            }
        }
    }

    ._price-box {
        text-align: left;
    }
}

li.ms-product-search-result__item {
    position: relative;
    margin: 0 0 20px;
    padding: 15px 0 0 0;
    text-align: center;
    background-color: $msv-white;
    border-radius: 0px;
    border: solid 1px $msv-soft-peach;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        text-align: center;
        background-color: $msv-white;
        border: solid 1px $msv-soft-peach;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.ms-product-search-result__item a {
    .msc-product__title {
        @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-product-size), $msv-line-height-m);
        color: var(--msv-search-result-product-color);
        margin-bottom: 5px;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 5px 0px 5px 0px;
        margin: 0px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 48px;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-rating {
        display: block;
        margin-top: $search-result-container-product-search-item-rating-margin-top;
    }

    .msc-price {
        .msc-price__actual {
            @include font-content(var(--msv-font-weight-heavy), var(--msv-search-result-product-price-size), $msv-line-height-l);
            color: var(--msv-search-result-product-price-color);
            display: inline-block;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &:focus {
        @include basic-outline-offset($product-search-result_link__item-outline-width,
            $product-search-result_link__item-outline-style,
            $msv-black,
            $product-search-result_link__item-outline-offset );

        .msc-product__title {
            text-decoration: underline;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        background-color: transparent;
        border: none;

        .msc-product__details {
            .msc-product__title {
                text-decoration: underline;
            }
        }
    }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.ms-search-result-container__category-hierarchy {
    @include vfi();
}

nav.ms-search-result-container__pagination {
    width: 100%;
}

.msc-pagination {
    @include font-content(var(--msv-font-weight-normal), var(--msv-refiner-values-font-size), $msv-line-height-m);
    display: flex;
    list-style: none;
    padding-left: $search-result-container-pagination-padding-left;
    width: 100%;
}

ul.msc-pagination {
    display: flex;
    justify-content: center;
}

.previous.msc-page-item {
    margin-right: auto;

    .msc-pagination__prev {
        padding-left: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: var(--msv-search-result-pagination-color);
        font-size: var(--msv-search-result-pagination-size);
        display: block;
        padding: $search-result-container-page-item-page-link-padding;
        line-height: $search-result-container-page-item-page-link-line-height;

        &::before {
            @include msv-icon();
            content: '';
        }

        .ms-search-result__pagination-left {
            @include add-icon($msv-ChevronLeft, before);
        }
    }
}

.msc-page-item {
    padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
    margin-left: auto;

    .msc-pagination__next {
        padding-right: 4px;
        display: inline-block;
    }

    .msc-page-link {
        color: var(--msv-search-result-pagination-color);
        font-size: var(--msv-search-result-pagination-size);
        display: block;
        padding: $search-result-container-page-item-page-link-padding;
        line-height: $search-result-container-page-item-page-link-line-height;
        padding-right: 4px;

        &::after {
            @include msv-icon();
            content: '';
        }

        .ms-search-result__pagination-right {
            @include add-icon($msv-ChevronRight, after);
        }
    }
}

.msc-page-item.disabled {
    .msc-page-link {
        color: $msv-secondary;
        cursor: auto;
        pointer-events: none;
    }
}

// plp badges styling 

.product-card-header {
    display: flex;
    justify-content: flex-start;

    .badge {
        height: 30px;
        max-width: 180px;
        margin-right: 4px;
    }

    .producer__ImageURI,
    ._logo-container {
        flex-grow: 1;
    }

    .producer__ImageURI {
        padding-right: 8px;
    }

    ._logo-container {
        padding: 0;

        img {
            max-width: 100px;
        }
    }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
.msc-page-link {
    color: var(--msv-search-result-pagination-color);
    font-size: var(--msv-search-result-pagination-size);
    display: block;
    padding: $search-result-container-page-item-page-link-padding;
    line-height: $search-result-container-page-item-page-link-line-height;

    @include vfi();
}

.active {
    text-decoration: underline;
}

.prev-text,
.next-text {
    padding: $search-result-prev-next-padding;
}

.ms-search-result-container {
    &__refine-menu-filter-title {
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        color: $msv-smalt-blue;
        padding: 24px 0px;
    }

    #search-result-modal {
        @include primary-btn();
        background: transparent;
        margin-bottom: 16px;

        @media (max-width: $msv-breakpoint-m) {
            color: $msv-white;
            background-color: $msv-black;
        }

        &:before {
            @include msv-icon();
            content: $msv-Filter;
            margin-right: 10px;
        }

        @media (max-width: $msv-breakpoint-l) {
            float: none;
            color: $msv-white;
            background-color: $msv-black;
        }
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container {
        margin-top: $search-result-container-margin-top-margin-top-mobile;

        .ms-search-result-container__category-hierarchy {
            display: none;
        }

        .msc-button {
            background: none;
            border-style: none;
            float: right;
            font-weight: 700;
        }

        .msc-button:hover {
            text-decoration: underline;
        }

        .ms-product-search-result__item {
            display: inline-block;
            min-width: $search-result-list-item-width;
            vertical-align: top;
        }
    }

    .ms-search-result-container__pagination {
        margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
    }

    .ms-search-result-container__Products {
        margin-bottom: $search-result-container-products-margin-bottom-mobile;
    }

    .prev-text,
    .next-text {
        display: none;
    }
}

// modal
.msc-search-result-modal {
    .msc-modal__footer {
        padding: 25px;
    }

    .msc-modal__header {
        justify-content: space-around;
    }

    .msc-modal__content {
        padding: 0px !important;
    }

    .msc-review-modal-body {
        height: $search-result-modal-body-height;
        overflow-y: auto;
        padding: 0px 25px;
        max-height: 409px;

        @media (max-width: $msv-breakpoint-m) {
            max-height: calc(100vh - 375px);
        }
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        @include secondary-btn();
    }

    .ms-search-result-container__refiner-section {
        display: block;
        padding-right: $search-result-modal-refiner-section-padding-right;
        width: 100%;
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container__Sort-by-category {
        position: relative;
        top: $search-result-container-sort-by-category-top-mobile;
        right: auto;
        border-top: 1px solid var(--msv-refiner-border);
        border-bottom: 1px solid var(--msv-refiner-border);
        z-index: 1;
        background-color: var(--msv-bg-color);
    }

    .ms-search-result-container {
        padding-left: $search-result-container-padding-left-mobile;
        padding-right: $search-result-container-padding-right-mobile;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        width: 100%;
    }
}

.ms-search-result-container {
    &__title {
        color: var(--msv-search-result-font-color);
        font-weight: 700;
        font-size: 24px;

        /* stylelint-disable no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-search-result__collection-title-text {
            /* stylelint-enable no-descending-specificity -- Auto-suppressed. Please fix if possible. */

            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-heading-font-size), $msv-line-height-xl);
            color: var(--msv-search-result-heading-font-color);
            font-weight: 700;
            font-size: 28px;
            text-transform: uppercase;
        }

        .ms-search-result__collection-title-prefix {
            text-transform: unset;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-search-result__collection-title-count {
            @include font-content(var(--msv-font-weight-normal), var(--msv-search-result-font-size), $msv-line-height-m);
            color: $msv-storm-dust;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 18px;
            margin-left: 12px;
        }
    }

    .msc-button {
        @include font-content(var(--msv-font-weight-bold), var(--msv-search-result-sort-font-size), $msv-line-height-m);
        color: var(--msv-search-result-sort-font-color);
    }

    .ms-feature-search-result {
        .ms-feature-search-result__item {
            display: flex;
            height: auto;
            padding-bottom: 10px;

            @include image($search-result-container-feature-placeholder-img-width);

            .msc-feature-search-result__item__image {
                flex-grow: 1;
                height: auto;
                width: 315px;
            }

            .msc-feature-search-result__item__detail {
                flex-grow: 1;
                height: auto;
                position: relative;
                padding-left: 8%;
                text-align: left;
                width: 100%;
            }

            .msc-feature-search-result__item__detail h1 {
                @include font-content(var(--msv-font-weight-light), var(--msv-feature-title-desktop-size), $msv-line-height-xxl);
                color: var(--msv-feature-title-color);
                margin: 0;
                padding: 0;
                position: relative;
                top: 30%;
                margin-bottom: 8px;
            }

            .msc-feature-search-result__item__detail p {
                @include font-content(var(--msv-font-weight-bold), var(--msv-feature-heading-font-size), $msv-line-height-l);
                color: var(--msv-feature-heading-color);
                margin: 0;
                margin-bottom: 8px;
                padding: 0;
                position: relative;
                top: 30%;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        .ms-feature-search-result {
            .ms-feature-search-result__item {
                .msc-feature-search-result__item__detail {

                    p,
                    h1 {
                        top: 20%;
                    }
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-feature-search-result {
            .ms-feature-search-result__item {
                display: block;

                .msc-feature-search-result__item__image,
                .msc-feature-search-result__item__detail {
                    width: 100%;
                }

                .msc-feature-search-result__item__image {
                    margin-bottom: 10px;
                }

                .msc-feature-search-result__item__detail {
                    padding-left: unset;

                    h1 {
                        @include font-content(var(--msv-font-weight-light), var(--msv-feature-title-mobile-size), $msv-line-height-xl);
                    }
                }
            }
        }
    }
}

@media (min-width: $msv-breakpoint-xl) {
    .ms-search-result-container {
        .ms-product-search-result__item {
            .msc-product {
                @include image(334px);
            }
        }
    }
}

.request-price_plp {
    min-height: 50px;
    padding: 10px 0px 0 0;
    margin-top: 10px;
}

.msc-product__item_wpprice {
    padding-right: 15px;
    min-height: 20px;
    text-align: right;
    font-size: 14px;

    .manufacturer-title {
        font-size: 13px;
    }

    span {
        color: $msv-black;
    }

    .krannich-price {
        .inc {
            font-size: 12px;
        }
    }
}

.plp-header-discription {
    padding: 10px 0;
}

.msc-price__strike {
    text-decoration: line-through;
    text-align: left;
    font-weight: 500;
    color: $msv-shiraz;
}

.manufacturer-name-strike,
._base-strike-wp-price {
    text-decoration: line-through;
}

._price-box {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    padding-left: 15px;
}

._logo-container {
    height: 50px;
    text-align: right;
    padding-right: 15px;

    img {
        display: unset;
        max-height: 25px !important;
    }
}

.breadcrum1 {
    border-bottom: solid 2px $msv-soft-peach;

    .ms-breadcrumb {
        max-width: 1270px;
        margin: auto;
        padding-left: 2px;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 20px;
        }
    }
}

.no-price-wrapper {
    display: flex;
    align-items: center;

    .label {
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 8px;
        width: auto;
    }
}

// PLP list view styles

.grid-unstyled {

    .ms-product-search-result__item {
        margin: 25px 0px;
        padding: 10px;

        .producer__ImageURI {
            min-height: auto;
        }

        .lights-section {
            padding-left: 0;
            min-height: unset;
            margin-top: 8px;
        }
    }

    .ks-plp-tile {
        display: flex;
        position: relative;
    }

    ._price-box {
        position: absolute;
        top: 20px;
        right: 0;
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        align-items: flex-end;

        .msc-product__item_wpprice {
            margin-top: 4px;
        }
    }

    .plp-img-wrapper {
        min-width: 300px;

        ._logo-container {
            padding-top: 12px;
            height: auto;
            margin-bottom: 8px;
        }
    }

    .request-price_plp {
        min-height: auto;
        padding: 0;
        margin-top: 0;
    }

    .list-product-quick-view,
    .request-price {
        display: flex;
        margin-top: 30px;
        padding-bottom: 12px;

        @media (max-width: $msv-breakpoint-ml) {
            margin-top: 16px;
            flex-direction: column;
        }
    }

    .request-price_plp-border {
        border: 0;
    }

    .msc-product__item_wpprice {
        padding-right: 0;
    }

    .Request-Price-btn {
        min-width: 175px;
        display: flex;
        align-items: center;
        margin: 0 16px 0 0;

        @media (max-width: $msv-breakpoint-ml) {
            margin-right: 0;
        }
    }

    .plp-tile-details {
        padding: 0 185px 0 30px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .msc-product__title {
            height: auto;
            font-size: 18px;
            margin-bottom: 15px;
            padding-top: 20px;
        }

        .msc-product__details {
            padding: 0;
        }

        .msc-product {
            outline: none;
        }
    }

    .ms-quickView {
        padding: 0;
        margin-right: 16px;

        @media (max-width: $msv-breakpoint-ml) {
            margin-right: 0;
        }
    }

    a {
        &.product-info__detail-btn {
            min-width: 175px;

            @media (max-width: $msv-breakpoint-ml) {
                margin-top: 16px;
            }

            &:hover,
            &:focus {
                background-color: $msv-supernova;
            }
        }
    }

    .product-card-header {
        .badge {
            position: absolute;
            right: -10px;
            max-width: 185px;
            margin: 0;
            top: 20px;
        }

        ._logo-container {
            img {
                max-width: 100%;
            }
        }
    }

    .ks-plp-tile-promotion {
        ._price-box {
            top: 60px;
        }
    }

    .ms-quickView__button,
    .product-info__detail-btn {
        &:focus {
            border: 1px dashed;
            outline: none;
            outline-offset: 0;
        }
    }
}