@import "../../../00-settings/index.scss";
@import "../../../01-tools/index.scss";
@import "../../../02-generic/index.scss";

.msc-create-order-template-icon {
    @include add-icon($msv-PlusSquare, after);
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
