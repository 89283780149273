@import '../00-settings/index.scss';
@import '../01-tools/index.scss';
@import '../02-generic/index.scss';

$msv-swatch-item-background-color: $msv-white;
$msv-swatch-item-border-color: #ebebeb;
$msv-swatch-selected-item-border-color: $msv-black;
$msv-swatch-selected-colored-item-inner-color: $msv-white;
$msv-swatch-item-size: 40px;
$msv-swatch-selected-colored-item-inner-size: 8px;

:root {
    --msv-swatch-item-background-color: #{$msv-swatch-item-background-color};
    --msv-swatch-item-border-color: #{$msv-swatch-item-border-color};
    --msv-swatch-selected-item-border-color: #{$msv-swatch-selected-item-border-color};
    --msv-swatch-selected-colored-item-inner-color: #{$msv-swatch-selected-colored-item-inner-color};
    --msv-swatch-item-size: #{$msv-swatch-item-size};
    --msv-swatch-selected-colored-item-inner-size: #{$msv-swatch-selected-colored-item-inner-size};
}

.msc-swatch-container {
    display: flex;
    flex-wrap: wrap;

    &__item {
        min-width: 70px;
        height: 30px;
        margin: 2px;
        border: 0;
        box-sizing: border-box;
        cursor: pointer;
        background-color: $msv-white;
        border-radius: 0;
        color: $msv-black;
        font-size: 16px;
        line-height: 21px;
        padding-top: 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 400;

        &__selected,
        &:hover {
            background: $msv-smalt-blue;
            color: $msv-white;
        }

        &__colored {
            &.msc-swatch-container__item__selected {
                position: relative;
            }

            &.msc-swatch-container__item__selected::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: var(--msv-swatch-selected-colored-item-inner-size);
                height: var(--msv-swatch-selected-colored-item-inner-size);
                background-color: var(--msv-swatch-selected-colored-item-inner-color);
            }
        }

        &__image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &.msc-swatch-container__item__selected {
                position: relative;
            }

            &.msc-swatch-container__item__selected::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: var(--msv-swatch-selected-colored-item-inner-size);
                height: var(--msv-swatch-selected-colored-item-inner-size);
                background-color: var(--msv-swatch-selected-colored-item-inner-color);
            }
        }

        &__disabled {
            opacity: 0.5;
        }
    }
}
