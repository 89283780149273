.ms-social-share {
    margin-bottom: 20px;
    margin-top: 20px;
    width: auto;

    &-ul {
        display: flex;
        list-style: none;

        &.caption::before {
            content: attr(data-title);
            display: block;
            font-weight: 700;
            height: 30px;
            padding: 4px;
        }

        &.vertical {
            display: block;

            li {
                margin-bottom: 10px;
            }

            li:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    &-li {
        margin: 0 5px;
        height: 20px;
        width: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}
