.city-selector {
    max-height: 100%;

    .logo-wrapper {
        display: flex;
        justify-content: center;

        img {
            max-width: 200px;
        }
    }

    .msc-modal__content {
        @media (max-width: $msv-breakpoint-m) {
            padding: 0 !important;
        }
    }

    .msc-modal__header {
        border-top: 5px solid $msv-supernova;
        border-bottom: 0;
    }

    .msc-modal__title {
        margin: 0;
    }

    .msc-modal__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2 {
            color: $msv-storm-dust;
            margin-bottom: 32px;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 12px;
        }

        label,
        .info-text {
            font-size: 16px;
            font-weight: 600;
            color: $stone;
            margin-bottom: 0;
        }

        .info-text {
            margin-bottom: 12px;
            font-weight: 400;
        }

        input {
            text-align: center;
            max-width: 450px;

            @media (max-width: $msv-breakpoint-m) {
                max-width: 100%;
                width: 100%;
            }
        }

        .btn {
            max-width: 180px;
            margin: 16px auto;

            @media (max-width: $msv-breakpoint-m) {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    .msc-modal__footer {
        display: flex;
        padding: 36px 16px 28px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $msv-soft-peach;
        text-align: center;

        h3 {
            color: $msv-storm-dust;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            text-transform: uppercase;
            margin-bottom: 16px;
            margin-top: 0;
        }

        b {
            font-weight: 600;
            text-transform: uppercase;
        }

        a {
            color: $msv-black;
            font-weight: 500;
        }

        p {
            font-size: 16px;
            margin-bottom: 8px;
        }
    }

    .error {
        @include msc-alert-error();
        background-position: left center;
        line-height: 1.5;
        margin-bottom: 0;
    }
    .info-text {
        font-size: 16px;
        color: $stone;

        a {
            color: $msv-smalt-blue;
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

// blur background of homepage

.portal-home {
    .msc-modal__backdrop {
        background-color: rgba($msv-black, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        opacity: 1;
    }
}

// handle cookies popup

.modal-open {
    &>div>header {
        position: static;
    }

    .ms-cookie-compliance {
        z-index: 1052;
    }
}