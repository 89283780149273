$search-autoSuggest-max-width: 400px;
$search-autoSuggest-categoryResults-item-height: 40px;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-color: $msv-gray-500;
$search-autoSuggest-categoryResults-title-padding: 16px 24px 8px 12px;
$search-autoSuggest-categoryResults-item-price-color: $msv-gray-500;
$search-autoSuggest-keywordResults-item-height: 48px;
$search-autoSuggest-keywordResults-item-padding: 13px 12px 14px 12px;
$search-autoSuggest-loading-item-height: 40px;
$search-autoSuggest-loading-item-padding: 13px 12px 14px 12px;
$search-autoSuggest-input-text-height: 40px;
$search-autoSuggest-productResults-item-height: 60px;
$search-autoSuggest-productResults-item-grid-columns-width: 52px;
$search-autoSuggest-productResults-item-padding-left: 20px;
$search-autoSuggest-productResults-item-padding-right: 12px;
$search-autoSuggest-productResults-title-color: $msv-gray-500;
$search-autoSuggest-productResults-title-padding: 16px 12px 14px 12px;
$search-searchForm-min-width: 240px;
$search-searchForm-height: 40px;
$search-autoSuggest-border-width: 1px;
$search-autoSuggest-top: $search-searchForm-height;
$search-autoSuggest-min-width: $search-searchForm-min-width;
$search-autoSuggest-hover-color: $msv-gray-200;
$search-form-control-padding: 8px;
$search-form-control-max-width: 512px;
$search-form-cancelBtn-height: $search-searchForm-height;
$search-form-cancelBtn-width: 40px;
$search-form-icon-height: $search-searchForm-height;
$search-form-icon-padding-left: 10px;
$search-form-submitBtn-height: $search-searchForm-height;
$search-form-submitBtn-width: 40px;
$search-form-submitBtn-border-radius: 0;
$search-form-inactive-width-desktop: 94px;
$search-form-inactive-width-mobile: 40px;

//style presets
:root {
    //Search
    --msv-search-font-color: var(--msv-font-primary-color);
    --msv-search-font-size: var(--msv-body-font-size-m);
    --msv-search-font-family: #{$msv-primary-font-family};

    //Search form
    --msv-search-form-bg: #{$msv-gray-100};
    --msv-search-form-border: #{$msv-gray-100};
    --msv-search-form-search-btn-color: var(--msv-accent-brand-color);
    --msv-search-form-cancel-btn-color: #{$msv-gray-100};

    //AutoSuggest
    --msv-autoSuggest-bg: var(--msv-bg-color);
    --msv-autoSuggest-border: #{$msv-gray-300};
}

.ms-search {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;

    .bx-hide {
        display: block;
    }

    .ms-Spinner-label {
        color: var(--msv-search-font-color);
        margin: 0 8px 0 8px;
    }

    .ms-Spinner-circle {
        border-color: $msv-gray-500 $msv-gray-300 $msv-gray-300;
    }

    &__icon {
        align-items: center;
        background: transparent;
        cursor: pointer;
        display: inline-flex;
        font-family: var(--msv-search-font-family);
        font-size: var(--msv-search-font-size);
        color: var(--msv-search-font-color);
        height: $search-form-icon-height;
        justify-content: center;
        line-height: $msv-line-height-m;
        width: $search-form-inactive-width-mobile;

        &::before {
            @include msv-icon();
            content: $msv-magnifying-glass;
            transform: scaleX(-1);
            vertical-align: text-bottom;
        }

        &-text {
            display: none;
        }
    }

    &__form {
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1001;
    }

    &__form-cancelSearch {
        align-items: center;
        background-color: var(--msv-search-form-cancel-btn-color);
        height: $search-form-cancelBtn-height;
        order: 1;
        width: $search-form-cancelBtn-width;
        display: flex;

        &::before {
            @include msv-icon();
            content: $msv-Cancel;
            width: $search-form-cancelBtn-width;
            text-align: center;
        }
    }

    &__form-submitSearch {
        align-items: center;
        background-color: $msv-soft-peach;
        color: $msv-storm-dust;
        height: $search-form-submitBtn-height;
        order: 1;
        width: $search-form-submitBtn-width;
        display: flex;
        padding: 0;
        border-radius: 0;
        border: 1px solid $msv-soft-peach;

        &::before {
            @include msv-icon();
            content: $msv-magnifying-glass;
            width: $search-form-submitBtn-width;
            text-align: center;
            transform: scaleX(-1);
        }

        &:focus {
            border: 1px dashed;
            outline: none;
        }
    }

    &__autoSuggest {
        background: var(--msv-autoSuggest-bg);
        box-sizing: border-box;
        justify-content: flex-end;
        left: 0;
        margin-top: $search-autoSuggest-top;
        width: 100%;
        z-index: 1001;

        .msc-price__actual {
            font-size: 14px;
            font-weight: normal;
        }

        a.msc-autoSuggest__productResults-item {
            display: flex;
            padding-left: $search-autoSuggest-productResults-item-padding-left;
            padding-right: $search-autoSuggest-productResults-item-padding-right;
            width: 100%;
            height: auto;
            align-items: center;
            margin-bottom: 0;
        }

        .search-product-thumb {
            height: 40px;
            width: 40px;
            margin-right: 8px;

            img {
                max-width: 100%;
                max-height: 40px;
                margin: 0 auto;
            }
        }
    }

    &__searchForm {
        display: flex;
        height: $search-searchForm-height;
        justify-content: flex-end;
        min-width: $search-searchForm-min-width;
        padding-top: 20px;
    }

    &__form-control {
        @include vfi();
        background-color: $msv-soft-peach;
        border: none;
        color: $msv-storm-dust;
        font-size: 14px;
        font-weight: 500;
        order: 2;
        padding: 0 15px 0px 0px;
        width: 100%;
        height: $search-autoSuggest-input-text-height;

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            color: $msv-gray-500;
        }

        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        &__form {
            left: unset;
            position: unset;
            top: unset;
            z-index: unset;

            &-control {
                max-width: $search-form-control-max-width;
            }
        }

        &__autoSuggest {
            height: unset;
            left: unset;
            position: absolute;

            max-width: 552px;
            min-width: 240px;
            position: absolute;
            top: 28px;

            background: $msv-white;

            &__keyword,
            &__product,
            &__category {
            }

            &__keyword {
                border-top: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
            }

            &__category {
                border-bottom: $search-autoSuggest-border-width solid var(--msv-autoSuggest-border);
            }
        }

        &__icon {
            width: $search-form-inactive-width-desktop;

            &-text {
                display: unset;
                padding-left: $search-form-icon-padding-left;
            }
        }
    }
}

.msc-autoSuggest {
    &__categoryResults-item {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-categoryResults-item-height;

        a {
            @include vfi();
            color: var(--msv-search-font-color);
            display: flex;
            height: $search-autoSuggest-categoryResults-item-height;
            line-height: $search-autoSuggest-categoryResults-item-height;
            padding: $search-autoSuggest-categoryResults-item-padding;
            width: 100%;
        }

        :hover {
        }
    }

    &__categoryResults-title {
        color: $msv-smalt-blue;
        font-size: var(--msv-body-font-size-s);
        font-weight: 700;
        padding: $search-autoSuggest-categoryResults-title-padding;
        text-transform: none;
    }

    &__keywordResults-item {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-keywordResults-item-height;

        a {
            @include vfi();
            color: var(--msv-search-font-color);
            display: flex;
            height: $search-autoSuggest-keywordResults-item-height;
            padding: $search-autoSuggest-keywordResults-item-padding;
            width: 100%;
        }
    }

    &__productResults-item {
        @include vfi();
        display: flex;
        font-size: var(--msv-search-font-size);
        color: var(--msv-search-font-color);
        margin-bottom: 16px;

        @media (max-width: $msv-breakpoint-l) {
            height: 45px;
            align-items: center;
            font-size: 14px;
            margin-bottom: 8px;
        }

        &:hover {
            text-decoration: none;
            .search-product-txt {
                text-decoration: underline;
            }
            .no-price-wrapper {
                text-decoration: none;
            }
        }

        .no-price-wrapper {
            margin-top: 4px;
            .label {
                font-size: 12px;
                font-weight: 400;
            }
        }

        .search-product-data {
            @media (max-width: $msv-breakpoint-l) {
                margin-left: 8px;
            }
        }

        &-price {
            color: $search-autoSuggest-categoryResults-item-price-color;
            grid-column: 2 / 2;
        }

        &-text {
            grid-column: 2 / 2;
        }

        .krannich-price {
            margin-top: 4px;
        }
    }

    &__productResults-title {
        color: $msv-smalt-blue;
        font-size: var(--msv-body-font-size-s);
        font-weight: var(--msv-font-weight-bold);
        line-height: $msv-line-height-s;
        padding: $search-autoSuggest-productResults-title-padding;
        text-transform: none;
    }

    &__screen-reader {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__loadingResult {
        display: flex;
        font-size: var(--msv-search-font-size);
        height: $search-autoSuggest-loading-item-height;
        justify-content: left;
        padding: $search-autoSuggest-loading-item-padding;
        width: 100%;
    }
}
