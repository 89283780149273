// bootstrap color variables
$msv-white: #fff;
$msv-black: #000;
$msv-blue: #0062af;
$msv-green: #107c10;
$msv-red: #a60039;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;
$msv-yellow: #ffff00;
$msv-grey: #323130;

//ks
$msv-supernova: #ffcc00;
$msv-pale-teal: #8ccaae;
$msv-smalt-blue: #48797e;
$msv-shiraz: #a60039;
$msv-soft-peach: #ededed;
$msv-gunsmoke: #5f5f5f;
$msv-storm-dust: #646464;
$msv-light-grey: #dadada;
$msv-nobel: #b2b2b2;
$msv-dark-junglel: #202020;
$gallery: #eaeaea;
//ks

$msv-gray-100: #f8f7f6;
$msv-gray-200: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-600: #c4c4c4;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #1d1d1d;
$msv-gray-1000: #2d2d2d;
$msv-gray-tint: #495057;

$msv-primary: #4c833a;
$msv-primary-hover: #39632c;
$msv-secondary: #6c757d;
$msv-secondary-hover: #e1efdc;
$msv-gray-30: #2c262d;
$msv-blue-tint: #0094ff;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-shiraz;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-selected-button-background: rgba(0, 0, 0, 0.02);
$msv-selected-hovered-button-background: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #f3f2f1;

// Social button color variables
$facebook-bg-color: #3c3c41;
$facebook-hover-color: #1d1d1d;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #1d1d1d;
$social-bg-color: #3c3c41;
$social-hover-color: #1d1d1d;
$alabaster: #F9F9F9;
$alabaster-2: #F7F7F7;
$dusty-gray: #979797;
$stone: #1A2240;
$concrete: #f3f3f3;
$down: #ddefe7;
$geyser: #cbdbdd;
$alto: #d9d9d9;
$dove-grey: #666;

$msv-unfilled-rating-color: $msv-gray-800;

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-gray-100};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-gray-100};
    --msv-btn-input-border-color: #{$msv-gray-500};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};

    // Buttons
    --msv-selected-button-background: #{$msv-selected-button-background};
    --msv-selected-hovered-button-background: #{$msv-selected-hovered-button-background};
}