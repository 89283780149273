@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

$msv-dialog-width: 446px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;

.msc-modal {
    .msc-lines-added-to-order-template-dialog {
        max-width: 500px;

        &__dialog {
            &__header {
                .msc-modal__title {
                    @include font-content-l();
                    text-transform: uppercase;
                }
            }

            &__products {
                &__image-container {
                    @include order-template-image-container();
                    margin: 0 auto;
                    display: none;
                }
            }

            &__description {
                text-align: center;
            }

            &__order-template-name {
                font-size: 18px;
                padding: 4px;

            }

            &__order-template-items {
                font-weight: 300;
                font-size: 16px;
                padding: 4px;
            }

            &__view-order-template-button {
                @include primary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__continue-shopping {
                @include secondary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
                margin-left: 10px;
            }

            &__footer {
                padding: 25px;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .msc-modal .msc-modal__dialog.msc-lines-added-to-order-template-dialog {
        margin: 0 10px;

        .msc-modal__body.msc-lines-added-to-order-template-dialog__dialog__body {
            max-height: 360px;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-xl) {
    .msc-modal .msc-modal__dialog.msc-lines-added-to-order-template-dialog {
        .msc-modal__body.msc-lines-added-to-order-template-dialog__dialog__body {
            max-height: 360px;
        }
    }
}