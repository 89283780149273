@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

$msv-dialog-width: 500px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-modal {
    .msc-item-added-to-order-template-dialog {
        max-width: $msv-dialog-width;

        .msc-item-added-to-order-template-dialog__dialog__footer {
            flex-wrap: nowrap;
            padding: 25px;

            @media (max-width: $msv-breakpoint-m) {
                padding: 25px 0;
                flex-direction: column;
            }
        }

        &__dialog {
            &__content {
                img {
                    display: none;
                }
            }

            &__header {
                @media (max-width: $msv-breakpoint-m) {
                    padding: 16px 0;
                }

                .msc-modal__title {
                    @include font-content-l();
                    color: $msv-black;
                    font-weight: 700;
                    text-transform: uppercase;

                    @media (max-width: $msv-breakpoint-m) {
                        padding-left: 0;
                    }
                }
            }

            &__body {
                @include font-content-l();
                text-align: center;
                margin: auto;
                @include image($msv-dialog-msc-empty_image-size);

                .msc-image-container {
                    margin-right: auto;
                    margin-left: auto;

                    .msc-empty_image {
                        padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
                    }
                }
            }

            &__product-name {
                padding: $msv-dialog-modal-content-body-text-padding;
            }

            &__product-dimensions {
                @include font-content-m();
                padding: $msv-dialog-modal-content-body-text-padding;
            }

            &__product-price {
                font-weight: var(--msv-font-weight-bold);
                padding: $msv-dialog-modal-content-body-text-padding;
                display: none;
            }

            &__view-order-template-button {
                @include primary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__continue-shopping {
                @include secondary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
                margin-left: 10px;

                @media (max-width: $msv-breakpoint-m) {
                    margin-left: 0;
                }
            }

            &__footer {
                flex-wrap: wrap;
            }
        }
    }
}