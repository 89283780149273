$msv-business-sign-up-width: 100%;
$msv-business-sign-up-input-margin-top: 4px;
$msv-business-sign-up-message-margin-top: 20px;
$msv-business-sign-up-message-padding: 20px;
$msv-business-sign-up-email-verification-buttons-margin-top: 12px;
$msv-business-sign-up-button-group-spacing: 32px;
$msv-business-sign-up-button-margin-top: 20px;
$msv-business-sign-up-button-margin-spacing: 20px;
$msv-business-sign-up-disclaimer-margin-top: 20px;

$msv-business-sign-up-border-radius: 2px;
$msv-business-sign-up-form-item-margin: 20px 0 0;
$msv-business-sign-up-input-size: 48px;
$msv-business-sign-up-input-spacing: 20px;
$msv-business-sign-up-input-padding: 8px 8px;
$msv-business-sign-up-label-margin: 0 0 5px 0;
$msv-business-sign-up-text-area-size: 173px;

//style presets
:root {
    --msv-business-sign-up-font-size: var(--msv-body-font-size-l);
    --msv-business-sign-up-font-color: var(--msv-font-primary-color);
    --msv-business-sign-up-border: #{$msv-gray-300};

    // heading
    --msv-business-sign-up-heading-font-size: var(--msv-body-font-size-xl);
    --msv-business-sign-up-heading-font-color: var(--msv-font-primary-color);

    // alert
    --msv-business-sign-up-success-alert-bg: #{$msv-gray-300};
    --msv-business-sign-up-success-alert-font-color: var(--msv-font-primary-color);
    --msv-business-sign-up-success-alert-border: #{$msv-gray-900};
    --msv-business-sign-up-error-alert-bg: var(--msv-error-color);
    --msv-business-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-business-sign-up-error-alert-border: var(--msv-error-color);
    --msv-business-sign-up-error-color: var(--msv-error-color);

    // primary button
    --msv-business-sign-up-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-business-sign-up-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-business-sign-up-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-business-sign-up-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-business-sign-up-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-business-sign-up-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-business-sign-up {
    @include font-content(var(--msv-font-weight-normal), var(--msv-business-sign-up-font-size), $msv-line-height-m);
    color: var(--msv-business-sign-up-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $msv-breakpoint-m) {
        width: 100%;
    }

    &__container {
        padding-top: 24px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;

        &-item-FirstName,
        &-item-LastName,
        &-item-CompanyName,
        &-item-JobTitle,
        &__item-city {
            padding-right: 16px;
        }
    }

    &__form-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-bottom: 29px;
        padding-right: 16px;

        &__item-error-text {
            padding: 0px;
            margin: 0px;
        }

        .msc-address-form__alert {
            @include msc-alert-error();
            position: absolute;
            top: calc(100% - 26px);
            margin: 0 0 10px 0;
            background-position: 0 2px;
            left: 0;

            &::before {
                display: none;
            }
        }

        &.width-25 {
            flex-basis: calc(25% - #{$msv-business-sign-up-input-spacing});
            width: calc(25% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-50 {
            flex-basis: calc(50% - #{$msv-business-sign-up-input-spacing});
        }

        &.width-100 {
            flex-basis: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &-CompanyEmail,
        &-Department,
        &-CompanySize {
            margin-right: 0;
        }

        &-label {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.2px;
            margin: 0 0 4px;
            color: $stone;
        }

        .msc-address-form__item {
            margin-bottom: $msv-business-sign-up-input-spacing;
            flex-wrap: wrap;

            &-city,
            &-state {
                padding-right: 16px;
            }
        }

        &-input,
        .msc-address-form__input,
        .msc-address-form__dropdown {
            @include vfi();
            border: 1px solid $msv-light-grey;
            box-sizing: border-box;
            display: block;
            padding: $msv-business-sign-up-input-padding;
            font-size: 16px;
            padding-left: 15px;
            height: 40px;
        }

        // country and input fields from the address form need to 50% length according to the design doc
        .msc-address-form__item-threeletterisoregionname,
        .msc-address-form__item-phone {
            display: inline-block;
            margin-bottom: 0;
            width: 50%;
        }

        .msc-address-form__item-threeletterisoregionname {
            width: calc(50% - 0px);
        }

        &-input-area {
            @include font-content-l();
            @include vfi();
            border: 1px solid $msv-light-grey;
            box-sizing: border-box;
            display: block;
            height: 112px;
            padding: $msv-business-sign-up-input-padding;
            width: 100%;
            font-size: 16px;
            padding-left: 15px;
        }
    }

    &__page-error {
        @include validation-error(var(--msv-business-sign-up-error-alert-bg),
            var(--msv-business-sign-up-error-alert-border),
            var(--msv-business-sign-up-error-alert-font-color));
        display: block;
        margin-top: $msv-business-sign-up-message-margin-top;
        padding: $msv-business-sign-up-message-padding;
        width: 100%;
        background-color: $msv-shiraz;
        color: $msv-white;
        padding: 10px;
        border-radius: 2px;
        margin: 10px 0px;

        p {
            padding: 0px;
            margin: 0px;
        }
    }

    &__item-error {
        color: var(--msv-business-sign-up-error-color);
    }

    &__create-button {
        @include primary-btn();
        margin: 44px 18px 0 0;

        min-width: 150px;
    }

    &__cancel-button {
        @include secondary-btn();
        margin-top: 44px;

        min-width: 150px;
    }

    &__disclaimer {
        margin-top: $msv-business-sign-up-disclaimer-margin-top;
        width: 100%;

        a {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        &__container {
            width: $msv-business-sign-up-width;
        }

        .msc-address-form__item-threeletterisoregionname {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }

        .msc-address-form__item-phone {
            display: block;
            width: 100%;
        }

        &__form-item {
            margin-right: 0;

            .msc-address-form {}

            &.width-25,
            &.width-50,
            &.width-100 {
                flex-basis: 100%;
            }
        }

        &__create-button {
            margin-right: 0;
            width: 100%;
        }

        &__cancel-button {
            margin-right: 0;
            width: 100%;

            @media (max-width: $msv-breakpoint-l) {
                margin: 20px 0px 20px 0px;
            }
        }
    }

    .newsletter-sign-up {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 4px;

        label {
            margin: 0;
            display: flex;
            align-items: center;
        }

        input[type='checkbox'] {
            width: 18px;
            height: 18px;
            min-width: 18px;
            margin-right: 8px;
            border-radius: 0;

            &:focus {
                outline: 1px dashed;
            }
        }
    }

    .b2b-sign-up-btn {
        display: flex;
        align-items: flex-end;
        padding-bottom: 5px;
        margin-left: 26px;

        @media (max-width: $msv-breakpoint-l) {
            margin-left: 0px;
        }

        a {
            color: $msv-smalt-blue;
            margin-left: 5px;
            font-weight: 500;
            text-decoration: underline;
        }
    }

    .msc-address-form__item,
    .ms-business-sign-up__form-item {
        position: relative;

        @media (max-width: $msv-breakpoint-l) {
            width: auto;
        }
    }

    .msc-address-form__item-phone {
        &.address-form__item-invalid {
            margin-left: 16px;
        }
    }
}

.mandatory-note {
    font-size: 11px;
    display: block;
    padding-top: 10px;
}

.signup-conformation {
    .ms-content-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .ms-content-block__details {
        width: calc(100% - 447px);
        order: -1;

        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }
    }

    .ms-content-block__image {
        max-width: 425px;
        padding-top: 150px;

        @media (max-width: $msv-breakpoint-l) {
            padding-top: 40px;
        }
    }

    h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: $msv-black;
        margin: 42px 0 16px;
    }

    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 24px;
    }

    p {
        font-size: 16px;
        line-height: 21px;
    }

    .msc-cta__primary {
        @include advisery-btn();
        margin-top: 69px;
    }
}

.ms-business-account-address {
    .msc-address-form {
        margin: 0;
    }
}

.business_partner_container {
    h1 {
        margin: 42px 0 16px;
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: $msv-black;
    }

    .ms-text-block {
        margin-top: 0;

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .msc-address-form {
        justify-content: space-between;

        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
        }

        .msc-address-form__item {
            width: calc(50% - 8px);
            padding-right: 0;
            padding-left: 0;
            margin-bottom: 0;
            padding-bottom: 29px;

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            input,
            select,
            label {
                @media (max-width: $msv-breakpoint-m) {
                    order: -1;
                }
            }

            .msc-address-form__alert {
                @media (max-width: $msv-breakpoint-m) {
                    position: static;
                    margin: 4px 0 0;
                }
            }

            &.msc-address-form__item-phone {
                &::before {
                    left: 10px;
                }

                input {
                    padding-left: 22px;
                }

                &.us-phone-format {
                    &::before {
                        font-size: 16px;
                        top: 35px;
                    }

                    input {
                        padding-left: 28px !important;
                    }
                }
            }
        }
    }

    .ms-business-sign-up__form-item-CompanyAddress {
        padding-bottom: 0;
    }
}

.partner-signup-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .business-sign-up-form {
        width: calc(100% - 441px);

        @media (max-width: $msv-breakpoint-l) {
            width: auto;
        }
    }

    .order-details-bannerImage {
        max-width: 425px;
        padding-top: 48px;
    }

    .abn-info-text {
        font-weight: 400;
        margin-top: 8px;
    }
}

.prospect-status-message {
    width: 100%;
    padding-top: 10px;

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $msv-shiraz;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        padding-left: 20px;
        background-position: 0 2px;
    }
}

// terms and conditions generic styling
.terms-and-conditions {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    font-weight: 400;
    color: $msv-black;

    input[type='checkbox'] {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-width: 18px;
        margin-right: 8px;
        border-radius: 0;
        margin-top: 3px;

        &:focus {
            outline: 1px dashed;
        }
    }

    a {
        color: $msv-smalt-blue;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: underline;
        margin-right: 4px;
    }
}