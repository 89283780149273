$exo-url-path:'../../../krannich/exo/';
@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Black.eot');
    src: url('#{$exo-url-path}ExoSoft-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Black.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Black.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Black.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Black.svg#ExoSoft-Black') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Bold.eot');
    src: url('#{$exo-url-path}ExoSoft-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Bold.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Bold.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Bold.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Bold.svg#ExoSoft-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Italic.eot');
    src: url('#{$exo-url-path}ExoSoft-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Italic.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Italic.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Italic.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Italic.svg#ExoSoft-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Light.eot');
    src: url('#{$exo-url-path}ExoSoft-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Light.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Light.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Light.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Light.svg#ExoSoft-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Medium.eot');
    src: url('#{$exo-url-path}ExoSoft-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Medium.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Medium.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Medium.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Medium.svg#ExoSoft-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-Regular.eot');
    src: url('#{$exo-url-path}ExoSoft-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-Regular.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-Regular.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-Regular.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-Regular.svg#ExoSoft-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ExoSoft';
    src: url('#{$exo-url-path}ExoSoft-SemiBold.eot');
    src: url('#{$exo-url-path}ExoSoft-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('#{$exo-url-path}ExoSoft-SemiBold.woff2') format('woff2'),
        url('#{$exo-url-path}ExoSoft-SemiBold.woff') format('woff'),
        url('#{$exo-url-path}ExoSoft-SemiBold.ttf') format('truetype'),
        url('#{$exo-url-path}ExoSoft-SemiBold.svg#ExoSoft-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
