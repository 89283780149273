$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-primary-list-address-detail-item-newline-margin-right: 0;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 48px;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-font-color: var(--msv-font-primary-color);
    --msv-address-border: #{$msv-gray-300};
    --msv-address-font-family: #{$msv-primary-font-family};
    --msv-checkout-shipping-address-font-color: var(--msv-font-primary-color);

    //heading
    --msv-address-heading-font-size: var(--msv-body-font-size-xl);
    --msv-address-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-heading-font-size: var(--msv-body-font-size-m);
    --msv-address-primary-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-text-font-size: var(--msv-body-font-size-s);
    --msv-address-primary-text-font-color: var(--msv-font-primary-color);

    // error
    --msv-address-error-bg: var(--msv-error-color);
    --msv-address-error-border: var(--msv-error-color);
    --msv-address-error-font-color: var(--msv-font-secondary-color);
    --msv-address-error-font-size: var(--msv-body-font-size-m);

    // link
    --msv-address-link: var(--msv-font-primary-color);
    --msv-address-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-address-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-address-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-address-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.msc-address-form {
    margin: 0 -8px;
    clear: both;
    display: flex;
    flex-wrap: wrap;

    &__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.2px;
        margin-bottom: 3px;
        color: $stone;
        text-transform: capitalize;
    }

    &__item {
        margin-bottom: $msv-address-form-item-margin-bottom;
        width: 100%;
        padding-right: 8px;
        padding-left: 8px;

        @media (max-width: $msv-breakpoint-m) {
            padding: 0;
        }

        &-city,
        &-state,
        &-zipcode,
        &-threeletterisoregionname {
            width: 50%;

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        &-phone {
            position: relative;

            &::before {
                content: '+';
                position: absolute;
                top: 35px;
                font-size: 18px;
                left: 18px;
            }

            input {
                padding-left: 18px;
            }

            &.us-phone-format {
                &::before {
                    content: '+1';
                    font-size: 14px;
                    top: 36px;
                }

                input {
                    padding-left: 24px !important;
                }
            }
        }
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__item-county {
        display: none;
    }

    &__input-text {
        padding: 6px 8px;
    }

    .MicrosoftMap {
        position: relative;
        width: 100%;

        .as_container_search {
            width: 100%;
        }
    }

    &__input,
    &__dropdown {
        @include vfi();
        color: var(--msv-checkout-shipping-address-font-color);
        display: block;
        width: 100%;
        border: none;
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        box-sizing: border-box !important;
        border-radius: 2px;
        font-size: var(--msv-address-font-size);
        line-height: $msv-line-height-l;
        font-weight: var(--msv-font-weight-normal);
        height: $msv-account-management-address-textbox-height;
        border-radius: 2px;
        border: 1px solid $msv-light-grey;
        font-size: 14px;
        padding-left: 15px;
        height: 40px;
    }

    &__alert {
        display: block;
        font-size: 10px;
        color: $msv-shiraz;

        &::before {
            margin-right: $msv-alert-icon-margin;
            @include msv-icon();
        }
    }

    &__button-save {
        @include primary-btn();
        margin-right: $msv-address-button-save-margin-right;
        margin: 16px;
        margin-left: 0;

        @media (max-width: $msv-breakpoint-m) {
            margin-left: 0;
        }
    }

    &__button-cancel {
        @include secondary-btn();
        margin: 16px 0;
    }

    .address-form__item-invalid &__alert {
        font-size: 12px;
        color: $msv-shiraz;
    }

    .button-wrapper {
        width: 100%;
    }
}

.msc-address-detail {
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
    }

    &__item-newline {
        margin-right: 0;

        &::after {
            content: '\A';
            white-space: pre;
        }
    }

    &__item-address-detail_Phone {
        display: block;
        width: 100%;
    }

    &__main-item-empty {
        display: none;
    }

    &__item-phone {
        @include add-icon($msv-Phone);

        &-label {
            @include visually-hidden();
        }

        &::before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
            color: $msv-shiraz;
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    clear: both;

    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include vfi();
        @include form-input-checkbox();
    }

    &__button-add {
        @include vfi();
        @include primary-button(var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border));
    }
}

// mixin

@mixin address-button {
    cursor: pointer;
}

.ms-account-management-address {
    .address-heading-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 36px;

        h2 {
            margin: 0;
        }
    }

    margin-bottom: $msv-account-management-address-margin-bottom;

    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;
        min-height: 420px;
    }

    .msc-address-list__heading {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        text-transform: uppercase;
        color: $msv-black;
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
    }

    .msc-address-list__button-add {
        @include primary-btn();

        @media (max-width: $msv-breakpoint-mobile) {
            width: 100%;
            margin-top: 32px;
        }
    }

    .msc-address-list__primary {
        margin-top: $msv-account-management-address-list-primary-margin-top;
        padding: 0 0 20px 0;

        .msc-address-list__primary-heading {
            @include font-content(var(--msv-font-weight-bold), var(--msv-address-primary-heading-font-size), $msv-line-height-m);
            color: var(--msv-address-primary-heading-font-color);
            margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
        }

        .msc-address-list__primary-list {
            padding: 20px 0;
            border-bottom: 1px dashed var(--msv-address-border);

            &::nth-child(2) {
                padding-top: 0;
            }

            &::last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            .msc-address-detail {
                clear: both;

                @include font-content(var(--msv-font-weight-normal), var(--msv-address-primary-text-font-size), $msv-line-height-s);
                color: var(--msv-address-primary-text-font-color);

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }

                .msc-address-detail__item-newline {
                    margin-right: $msv-primary-list-address-detail-item-newline-margin-right;

                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }

            .msc-address-list__button-primary {
                @include secondary-btn();
                display: block;
                margin-top: $msv-account-management-address-list-button-primary-margin-top;
            }

            .msc-address-list__button-edit {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
            }

            .msc-address-list__button-remove {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
            }
        }

        .msc-address-list__primary-list:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    .msc-address-form {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;

        @media (min-width: $msv-breakpoint-l) {
            max-width: 70%;
            padding: 50px 0;
        }
    }

    .account-address-table-wrapper {
        margin-bottom: 32px;

        @media (max-width: $msv-breakpoint-ml) {
            max-width: 100%;
            overflow: auto;
        }
    }

    .Phone-container {
        .-label {
            @media (max-width: $msv-breakpoint-l) {
                display: none;
            }
        }
    }
}

.remove-address-screen-reader {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.current_primary {
    color: $msv-supernova;
    text-transform: uppercase;
    font-size: 12px;
}

// edit address modal
.add-address-popup-container {
    .modal-dialog {
        @media (min-width: $msv-breakpoint-m) {
            max-width: 800px;
        }
    }

    .msc-address-form {
        margin: 0;
    }

    .modal-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        padding: 0 12px;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 0;
        }
    }

    .modal-header {
        .close {
            font-size: 32px;
            color: $msv-smalt-blue;
            opacity: 1;
        }
    }

    .info-text-address-popup {
        display: block;
        margin-top: 5px;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        padding-left: 12px;
        line-height: 21px;
        color: $msv-storm-dust;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 0;
        }
    }

    .msc-address-form__item {
        width: 50%;
        padding: 0 12px 16px;
        display: flex;
        margin-bottom: 0;
        flex-direction: column;
        position: relative;

        &.msc-address-form__item-phone {
            &::before {
                @media (max-width: $msv-breakpoint-m) {
                    left: 7px;
                }
            }
        }

        label {
            order: 1;
        }

        input,
        select {
            order: 2;
        }

        .msc-address-form__alert {
            order: 3;
            position: absolute;
            bottom: 0;
        }

        .add_address_error-msg {
            position: unset;
            padding-top: 10px;
            font-size: 12px;

            &+.add_address_error-msg {
                padding-top: 0;
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            padding: 0;
        }
    }

    .msc-address-form .msc-address-form__item-city {
        padding-left: 12px;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 0;
        }
    }

    .button-wrapper {
        padding: 12px 12px 0;
        text-align: center;

        @media (max-width: $msv-breakpoint-m) {
            padding: 12px 0 0;
        }

        button {
            width: 150px;

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
                margin: 12px 0 0;
            }
        }
    }

    &.delete-popup {
        .modal-dialog {
            max-width: 580px;
            margin-top: 120px;
        }

        .modal-title {
            margin: 0;
            padding-left: 8px;
        }

        .info-text-address-popup {
            padding-left: 8px;
            margin-bottom: 0;
            color: $stone;
        }

        .modal-footer {
            padding: 8px 24px 24px;
            justify-content: center;
            border: 0;
            text-align: center;

            .msc-btn {
                min-width: 150px;
                margin: 0 16px 0 0;

                &:first-child {
                    @media (max-width: $msv-breakpoint-m) {
                        margin-bottom: 16px;
                    }
                }

                @media (max-width: $msv-breakpoint-m) {
                    margin: 0;
                    min-width: 100%;
                }
            }
        }
    }
}

.msc-address-detail :nth-child(1) {
    width: 100%;
}

.info-text-address-popup {
    display: none;
}

.address-container {
    .ms-mobile-order-history__btn-more {
        @include primary-btn();
    }
}

.address-inside-container {
    td {
        padding-top: 10px;
        text-align: center;
    }

    padding-bottom: 15px;
    border-bottom: solid 1px $msv-light-grey;
    margin-bottom: 15px;

    span {
        display: block;
        padding: 1px 0px;
        font-weight: 700;

        span {
            font-weight: normal;
            display: inline;
        }
    }

    .edit-delete-buttons {
        .msc-address-list__button-edit {
            font-size: 0px;
            border: none;
            background-color: transparent;
            @include add-svg-icon($icon-edit, 20px, 20px);
        }

        .msc-address-list__button-remove {
            font-size: 0px;
            border: none;
            background-color: transparent;
            @include add-svg-icon($icon-trash, 20px, 20px);
        }
    }

    .mob_current_primary {
        .msc-address-list__button-primary {
            @include tertiary-btn();
        }
    }
}