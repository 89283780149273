@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

$msv-create-order-template-image-color: #bfbfbf;

.msc-modal {
    .msc-add-to-order-template-dialog {
        &__empty {
            &__dialog {
                &__content {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;

                    @media screen and (min-width: $msv-breakpoint-m) {
                        padding: 100px 0;
                    }
                }

                &__create-order-template-image-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                &__create-order-template-image {
                    font-size: 100px;
                    color: $msv-create-order-template-image-color;
                    pointer-events: none;
                }

                &__no-order-templates {
                    font-size: 24px;
                    line-height: 31px;
                    padding: 28px;
                    text-align: center;
                }

                &__no-order-templates-description {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                }

                &__create-order-template-button {
                    @include primary-btn();
                }

                &__cancel-button {
                    @include secondary-btn();
                    margin: 0 20px;
                }
            }
        }

        &__existing {
            max-width: 850px;
            max-height: calc(100vh - 60px);

            .msc-modal__body {
                max-height: calc(100vh - 200px);
            }

            &__dialog {
                &__order-templates-description {
                    font-size: 14px;
                    line-height: 18px;
                    margin: 10px 0;
                }

                &__order-templates-container {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    @media screen and (max-width: 793px) {
                        justify-content: left;
                    }
                }

                &__order-templates-line {
                    &__item_header {
                        padding: 8px 0;
                        display: flex;
                        align-items: center;
                        width: 65%;
                        justify-content: space-between !important;

                        @media (max-width: $msv-breakpoint-m) {
                            padding: 0;
                            flex-grow: 1;
                        }
                    }

                    &__container {
                        display: flex;
                        position: relative;
                        background: $msv-white;
                        border-bottom: 1px solid var(--msv-cart-border);
                        font-size: 14px;
                        line-height: 21px;
                        justify-content: space-between;
                        width: 100%;

                        &__create-new-line {
                            @media screen and (max-width: $msv-breakpoint-m) {}

                            &__image {
                                display: none;
                                margin-left: auto;
                                margin-right: auto;

                                @media screen and (max-width: $msv-breakpoint-m) {
                                    margin-top: 40px;
                                }
                            }

                            &__button {
                                @include primary-btn();
                                text-align: center;
                                margin: 0 auto 50px;
                                height: 40px;
                            }
                        }
                    }

                    &__title {
                        font-weight: var(--msv-font-weight-bold);
                        padding-left: 12px;
                        padding-right: 12px;
                        border: 0px;

                        @media (max-width: $msv-breakpoint-m) {
                            padding: 0;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &__count {
                        padding-left: 12px;
                        padding-right: 12px;
                        padding-top: 4px;

                        @media (max-width: $msv-breakpoint-m) {
                            white-space: nowrap;
                        }
                    }

                    &__action-button {
                        @include tertiary-btn();

                        @media (max-width: $msv-breakpoint-m) {
                            padding: 0;
                            font-size: 0;
                            @include add-icon($msv-file, after, $msv-outline-icon-weight);
                            @include vfi();

                            &:after {
                                font-size: 20px;
                                color: $msv-black;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            text-align: right;
                            justify-content: end;

                            @media (max-width: $msv-breakpoint-m) {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.msc-modal__header {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
}

.msc-modal__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding: 0 12px;
    margin: 0;
}

.msc-modal__dialog.msc-modal-input-required .msc-modal__content .msc-modal__header .msc-modal__title {
    @media screen and (max-width: $msv-breakpoint-m) {
        border: none;
        text-align: left !important;
        padding-left: 0px;
    }
}

.msc-add-to-order-template-dialog__empty__dialog__footer {
    padding: 25px;
}

.msc-add-to-order-template-dialog__existing__dialog__header {
    @media (max-width: $msv-breakpoint-l) {
        text-align: left;
    }
}