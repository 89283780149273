$quickOrder-margin-top: 20px;
$quickOrder-padding-bottom: 20px;
$quickOrder-margin-bottom: 20px;
$quickOrder-margin-left: 20px;
$quickOrder-user-msg-padding: 20px;
$quickOrder-desc-margin-top: 12px;
$quickOrder-desc-padding-right: 375px;
$quickOrder-margin-left: 20px;
$quickOrder-drawer-button-padding-left: 0;
$quickOrder-drawer-button-padding-right: 0;
$quickOrder-drawer-expand-padding-right: 20px;
$quickOrder-addtocart-button-margin-top: 24px;
$quickOrder-main-height: 48px;
$quickOrder-main-width: 148px;
$msv-quickOrder-border: 1px solid $msv-gray-300;
$msv-quickOrder-alert-icon-margin: 8px;
$msv-quickOrder-alert-success-color: $msv-grey;
$msv-quickOrder-alert-success-background-color: $msv-gray-300;
$msv-quickOrder-mobile-title-font-weight: 700;
$msv-quickOrder-action-button-color: $msv-green;

//style presets
:root {
    --msv-quickOrder-btn-bg: #{$msv-white};
    --msv-quickOrder-btn-font-color: var(--msv-font-primary-color);
    --msv-quickOrder-btn-border-color: var(--msv-border-color);
    --msv-quickOrder-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-quickOrder-title-font-size: var(--msv-body-font-size-xl);
    --msv-quickOrder-title-font-color: var(--msv-font-primary-color);

    //description text
    --msv-quickOrder-desc-text-font-size: var(--msv-body-font-size-s);

    //text
    --msv-quickOrder-text-font-size: var(--msv-body-font-size-m);
    --msv-quickOrder-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-quickOrder-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-quickOrder-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-quickOrder-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-quick-order {
    .msc-alert-danger {
        display: block;
        margin-left: 0px;
    }

    .show {
        min-height: 125px !important;
    }

    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: $msv-breakpoint-l) {
        width: 100%;
    }

    &__heading {
        @include font-content($msv-font-weight-bold, 20px, $msv-line-height-xl);
        color: $msv-storm-dust;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 2px;
        margin-top: 0px;
    }

    &__description {
        color: var(--msv-quickOrder-text-font-color);
        margin-left: $quickOrder-margin-left;
        margin-top: $quickOrder-desc-margin-top;
        font-size: 16px;
        display: none;
        // padding-right: $quickOrder-desc-padding-right;
    }

    &__drawer {
        margin-top: 5px;
        background-color: var(--msv-quickOrder-btn-bg);

        .drawer__button {
            background: var(--msv-quickOrder-btn-bg);
            height: auto;
            padding-left: $quickOrder-drawer-button-padding-left;
            padding-right: $quickOrder-drawer-button-padding-right;
            padding-bottom: $quickOrder-padding-bottom;
            width: 100%;
            text-align: left;
            cursor: pointer;
            padding-bottom: 0;
            border: 0;
            position: relative;
            padding-top: 0;
            max-width: 162px;

            &:hover,
            &:focus,
            &:active {
                background: none !important;
                border: 0 !important;
                box-shadow: none !important;

                &::after {
                    outline: 1px dashed;
                }
            }

            &[aria-expanded='true'] {
                @include add-icon($msv-Remove, after);
                padding-right: $quickOrder-drawer-expand-padding-right;

                &::after {
                    line-height: 24px;
                    text-align: right;
                    color: $msv-black;
                    position: absolute;
                    right: 0;
                    transform: translateY(-50%);
                    top: 43%;
                    font-size: 16px;
                    color: $msv-shiraz;
                    border: 2px solid;
                    padding: 5px;
                    padding-top: 5px;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
            }

            &[aria-expanded='false'] {
                @include add-icon($msv-Add, after);
                padding-right: $quickOrder-drawer-expand-padding-right;

                &::after {
                    line-height: 24px;
                    text-align: right;
                    color: $msv-black;
                    position: absolute;
                    right: 0;
                    transform: translateY(-50%);
                    top: 43%;
                    font-size: 16px;
                    color: $msv-shiraz;
                    border: 2px solid;
                    padding: 5px;
                    padding-top: 5px;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
            }

            .drawer__glyph {
                text-align: center;
            }

            &:focus {
                border: none;
                outline: none;
            }
        }

        .collapse {
            // display: block !important;
        }
    }

    &__content {
        background-color: var(--msv-quickOrder-btn-bg);

        &-form-main {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 36px;
            border: solid 1px $msv-light-grey;
            padding: 15px 20px 30px 20px;
            margin: 25px 0px 40px 0px;
            width: 830px;
            min-height: 125px;
            align-items: end;
            max-width: 100%;

            &-product {
                width: 300px;
                order: 1;
                margin-right: 16px;

                @include font-content(var(--msv-font-weight-light), var(--msv-quickOrder-text-font-size), $msv-line-height-l);
                color: var(--msv-quickOrder-text-font-color);

                &-lable {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: normal;
                    color: var(--msv-quickOrder-text-font-color);
                }

                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                    margin-bottom: 16px;
                    margin-right: 0;
                }
            }

            &-quantity {
                width: 310px;
                order: 2;
                padding-right: 10px;

                @include font-content(var(--msv-font-weight-light), var(--msv-quickOrder-text-font-size), $msv-line-height-l);
                color: var(--msv-quickOrder-text-font-color);

                &-lable {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: normal;
                    color: var(--msv-quickOrder-text-font-color);
                }

                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                }
            }

            &-addtocart {
                margin: 10px 0px 0px 0px;
                min-width: 150px;
                order: 3;

                @include font-content(var(--msv-font-weight-light), var(--msv-quickOrder-text-font-size), $msv-line-height-s);
                color: var(--msv-quickOrder-text-font-color);

                &-button {
                    @include primary-btn();
                    line-height: $msv-line-height-l;
                    flex-grow: 1;
                    width: 100%;
                    font-weight: 600;
                    max-width: 300px;
                    line-height: 20px;
                    height: 40px;

                    @media (max-width: $msv-breakpoint-m) {
                        max-width: 100%;
                    }
                }
            }

            input[type='text'] {
                width: 400px;
            }

            input[type='number'] {
                width: 100%;
            }

            input[type='text'],
            input[type='number'] {
                height: 40px;
                box-sizing: border-box;
                padding: 5px 16px;
                border: 1px solid $msv-light-grey;
                max-width: 100%;

                &:focus {
                    outline: none;
                }
            }

            &__configure {
                display: flex;
                margin-right: 8px;
                width: 100%;
                margin: 16px 0;
                order: 3;
                align-items: flex-start;
            }

            &__dropdown {
                margin-right: 16px;
                width: 300px;
                display: flex;
                flex-wrap: wrap;

                &-quantity-label {
                    font-size: 14px;
                    font-weight: normal;
                    text-transform: uppercase;
                    order: 1;
                }
            }

            .msc-dropdown__select {
                height: 40px;
                padding: 5px 8px;
                background: transparent;
                width: 100%;
                border: 1px solid $msv-light-grey;
                outline: none;
                order: 2;

                &:focus {
                    border: $msv-quickOrder-border;
                    outline: none;
                }
            }

            .msc-alert-danger {
                @include font-content(var(--msv-font-weight-light), var(--msv-quickOrder-desc-text-font-size), $msv-line-height-s);
                margin-left: 8px;
                order: 3;
            }
        }

        &-form-msg {
            padding-bottom: 0;
            order: 3;

            .show {
                min-height: auto !important;
            }

            .fa-exclamation-triangle {
                margin-right: $msv-alert-icon-margin;
                margin-top: $msv-alert-line-margin-top;
                padding-left: 4px;
                display: none;
            }

            .msc-alert-success {
                color: $msv-black;
                margin-top: $quickOrder-desc-margin-top;
                font-size: 14px;
                border-radius: 0px;
                background-color: $down;
                border: solid 1px $geyser;
                font-weight: normal;
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='black'/%3E%3Cpath d='M8.40594 5.20586L6.10293 7.5159L5.19986 6.60592C5.02231 6.42838 4.76344 6.359 4.52098 6.42405C4.2784 6.48897 4.08891 6.67846 4.02399 6.92103C3.95895 7.1635 4.02833 7.42237 4.20587 7.59991L5.60593 8.99997C5.7373 9.13251 5.91625 9.20705 6.10291 9.20705C6.28948 9.20705 6.46844 9.13251 6.5999 8.99997L9.3999 6.19997C9.57744 6.02243 9.64682 5.76356 9.58178 5.52098C9.51685 5.2784 9.32736 5.08903 9.08479 5.02399C8.84233 4.95895 8.58348 5.02832 8.40594 5.20586Z' fill='white'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: 15px 14px;
                padding-left: 45px;
                max-width: 838px;
            }

            .msc-alert-danger {
                margin: 15px 0 0;
                padding: 0px 10px 0 20px;
                background-position-y: 2px;
            }
        }
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            @include divider(bottom);

            &__row {
                height: 50px;

                th {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                }

                &__column-with-number {
                    text-align: right;
                }
            }
        }

        &__row {
            height: 54px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;

            @include divider(bottom);

            &__column-with-number {
                text-align: right;
            }

            &__product-info {
                display: grid;
            }

            &__product-name {
                font-weight: 700;
            }

            &__product-remove {
                @include add-icon($msv-x-shape);
                background: none;
                color: $msv-quickOrder-action-button-color;
            }

            &__product-quantity {
                flex: 0 0 192px;

                &-label {
                    margin: 0 0 10px;
                }

                .quantity {
                    display: flex;

                    &__controls {
                        border: 0.5px solid $msv-grey;
                        border-radius: 2px;
                        width: 48px;
                        height: 50px;
                        background-color: $msv-white;

                        &:disabled {
                            color: $msv-gray-500;
                        }
                    }

                    .decrement {
                        @include add-icon($msv-Remove, after);
                        margin-right: 7px;

                        &.disabled {
                            color: $msv-gray-500;
                            background-color: $msv-gray-300;
                        }

                        &:not(:disabled):not(.disabled):active,
                        &:not(:disabled):not(.disabled):active:focus {
                            color: $msv-gray-500;
                        }

                        &::after {
                            vertical-align: middle;
                        }
                    }

                    .increment {
                        @include add-icon($msv-Add, after);
                        margin-left: 7px;

                        &.disabled {
                            color: $msv-gray-500;
                            background-color: $msv-gray-300;
                        }

                        &:not(:disabled):not(.disabled):active,
                        &:not(:disabled):not(.disabled):active:focus {
                            color: $msv-gray-500;
                        }

                        &::after {
                            vertical-align: middle;
                        }
                    }

                    &-input {
                        @include form-control();
                        text-align: center;
                        border: 0.5px solid $msv-grey;
                        border-radius: 2px;
                        width: 64px;
                        height: 50px;

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                        }
                    }
                }
            }

            &__product-image-container {
                @include image(64px);
            }

            &__extra-actions-cell {
                &__toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: 82px;
                    color: $msv-quickOrder-action-button-color;
                }

                &__actions-container {
                    align-items: flex-start;
                    background: $msv-white;
                    border-radius: 2px;
                    box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);
                    flex-direction: column;
                    height: auto;
                    padding-left: 0;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    z-index: 10;

                    &__view-details {
                        @include add-icon($msv-List);
                    }

                    &__buy-again {
                        @include add-icon($msv-Arrow-Right-In-Circle, before, $msv-outline-icon-weight);
                    }

                    button {
                        background: none;
                        height: 60px;
                        padding: 0 8px;
                        text-align: left;
                        width: 100%;

                        &::before {
                            margin-right: 12px;
                            width: 16px;
                            color: var(--msv-accent-brand-color);
                        }
                    }
                }
            }
        }

        th,
        td {
            padding: 20px;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding: 5px;
            }

            &.ms-quick-order__table-list__header__row__mobile-cell,
            &.ms-quick-order__table-list__row__mobile-cell {
                padding: unset;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-quick-order {
        &__heading {
            margin-left: 0;

            @include font-content-heading($msv-quickOrder-mobile-title-font-weight);
        }

        &__description {
            padding-right: 0;
            margin-left: 0;
        }

        &__drawer {
            margin-top: 5px;

            .collapse {
                padding-bottom: 1px;
            }

            .drawer__button {
                padding: 0;
                width: 100%;
                max-width: 100%;
                margin-bottom: 18px;
            }
        }

        &__extra-actions-cell {
            &__toggle {
                @include add-icon($msv-Ellipses-V);
                background: none;
                height: 82px;
                color: $msv-quickOrder-action-button-color;
            }

            &__actions-container {
                align-items: flex-start;
                background: $msv-white;
                border-radius: 2px;
                box-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);
                flex-direction: column;
                height: auto;
                padding-left: 0;
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 10;

                &__view-details {
                    @include add-icon($msv-List);
                }

                &__buy-again {
                    @include add-icon($msv-Arrow-Right-In-Circle, before, $msv-outline-icon-weight);
                }

                button {
                    background: none;
                    height: 60px;
                    padding: 0 8px;
                    text-align: left;
                    width: 100%;

                    &::before {
                        margin-right: 12px;
                        width: 16px;
                        color: var(--msv-accent-brand-color);
                    }
                }
            }
        }

        &__content {
            &-form-main {
                display: block;
                padding-bottom: 0;
                padding-top: $quickOrder-margin-top;

                &-product {
                    width: auto;
                    height: auto;
                    margin-bottom: $quickOrder-margin-bottom;
                }

                input[type='text'] {
                    width: 100%;
                }

                &__configure {
                    display: block;
                }

                &-addtocart {
                    margin-left: 0;
                    margin-top: 16px;
                    margin-bottom: 20px;

                    &-button {
                        margin-top: 0;
                    }
                }

                &-quantity {
                    margin-left: 0;
                }

                &__dropdown {
                    margin-left: 0;
                    margin-bottom: $quickOrder-margin-bottom;
                    width: 100%;
                }

                .msc-dropdown__select {
                    width: 100%;
                }
            }

            &-form-msg {
                padding-bottom: 0;
                padding-top: 8px;

                .msc-alert-success {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
