$msv-business-organization-list-width: 100%;
$msv-business-organization-list-primary-btn-font-color: $msv-white;
$msv-business-organization-list-margin-right: 20px;
$msv-business-organization-list-border-radius: 0px;
$msv-business-organization-list-form-item-margin: 20px 0 0;
$msv-business-organization-list-form-item-margin-right: 10px;
$msv-business-organization-list-input-border: 1px solid $msv-gray-500;
$msv-business-organization-list-input-padding: 6px 8px;
$msv-business-organization-list-input-size: 48px;
$msv-business-organization-list-input-spacing: 20px;
$msv-business-organization-list-label-margin: 0 0 5px 0;
$msv-business-organization-list-error-border: 1px solid $msv-white;
$msv-business-organization-list-error-bg-color: $msv-error-color;
$msv-business-organization-list-message-margin-top: 20px;
$msv-business-organization-list-message-padding: 20px;
$msv-business-organization-list-list-item-spacing: 8px;
$msv-business-organization-list-table-margin-top: 20px;
$msv-business-organization-list-table-data-row-height: 82px;
$msv-business-organization-list-modal-padding: 2 0;
$msv-business-organization-list-pagination-padding-left: 0;
$msv-business-organization-list-page-item-page-link-padding: 8px 4px;
$msv-business-organization-list-page-item-page-link-line-height: 1.25;
$msv-business-organization-list-page-item-padding-right: 10px;
$msv-business-organization-list-page-prev-next-padding: 0 4px;
//TODO: add to style presets
$msv-business-organization-list-table-border: 1px solid $msv-light-grey;
$msv-business-organization-list-action-link-padding: 0 10px;
$msv-business-organization-list-actions-shadow: 0 3.2px 7.2px rgba(0, 0, 0, 0.132), 0 0.6px 1.8px rgba(0, 0, 0, 0.108);

:root {
    //title
    --msv-business-organization-list-title-font-size: var(--msv-body-font-size-xl);
    --msv-business-organization-list-title-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-business-organization-list-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-business-organization-list-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-business-organization-list-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-business-organization-list-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-business-organization-list-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-business-organization-list-secondary-btn-border: var(--msv-accent-brand-color);

    // actions
    --msv-business-organization-list-actions-icon-color: var(--msv-accent-brand-color);
    --msv-business-organization-list-actions-text-color: var(--msv-font-primary-color);

    // text
    --msv-business-organization-list-pagination-text-font-size: var(--msv-body-font-size-m);
    --msv-business-organization-list-text-font-color: var(--msv-font-primary-color);
    --msv-business-organization-list-text-font-size: var(--msv-body-font-size-m);
    --msv-business-organization-list-pagination-color: var(--msv-font-primary-color);
    --msv-business-organization-list-pagination-size: var(--msv-body-font-size-m);
}

.ms-business-organization-list {
    padding: 0 0 40px 0;

    @media (min-width: $msv-breakpoint-l) {
        padding: 0;
    }

    .ms-table__container {
        margin-bottom: 32px;
    }

    &__container {
        width: $msv-business-organization-list-width;
    }

    &__heading {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: $msv-black;
        display: inline-flex;
        width: 50%;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 16px;
        }
    }

    &__add-user-button {
        @include primary-btn();
        margin: 20px 0;
    }

    &__table {
        border-collapse: collapse;
        margin-top: 36px;
        table-layout: auto;
        width: 100%;
        border: 1px solid $msv-soft-peach;
        box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }

        .ms-input-radio {
            margin-left: 15px;
        }

        .ms-table__row :nth-child(4) {
            display: none;
        }

        .ms-table {

            &__heading-row,
            &__heading-row-data {
                @include font-content-l();
                border-bottom: $msv-business-organization-list-table-border;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 600;
                font-size: 14px;

                border-bottom: $msv-business-organization-list-table-border;

                :nth-child(4) {
                    display: none;
                }

                &.num-type {
                    text-align: left;
                }
            }

            &__row,
            &__row-data,
            &__row-links {
                @include font-content-l();
                border-bottom: $msv-business-organization-list-table-border;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 400px;

                font-size: 14px;

                &.num-type {
                    text-align: left;
                }

                &.action-links {
                    padding-left: 20px;
                }
            }

            &__row-data {
                padding: 12px 5px;
                line-height: 28px;

                &:first-child {
                    padding-left: 44px;
                    width: 300px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    border-bottom: none;
                }

                &:last-child {
                    padding-right: 44px;
                    text-align: right;

                    @media (max-width: $msv-breakpoint-l) {
                        padding-right: 0;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            &__heading-row,
            &__heading-row-data {
                border-bottom: $msv-business-organization-list-table-border;
                padding: 5px 0px;
                border-bottom: $msv-business-organization-list-table-border;
                font-size: 14px;
                background-color: $msv-soft-peach;
                font-weight: 700;
                word-wrap: break-word;
                padding: 15px 5px;
                line-height: 19px;

                @media (max-width: $msv-breakpoint-m) {
                    padding: 4px 5px;
                }

                &:first-child {
                    padding-left: 44px;
                }

                &:last-child {
                    padding-right: 44px;
                    text-align: right;
                }

                &__checkbox {
                    width: 60px;
                }
            }

            &__heading-row-data {
                border-bottom: 5px solid $msv-supernova;

                &[data-sort-name='Email'] {
                    @media (min-width: 1200px) {
                        width: 50%;
                    }
                }
            }

            &__heading-sorting {
                cursor: pointer;

                // &.asc {
                //     @include add-icon($msv-Arrow-Down, after);
                // }

                // &.dsc {
                //     @include add-icon($msv-Arrow-Up, after);
                // }

                &::after {
                    color: $msv-gray-500;
                    padding-left: 8px;
                }
            }

            &__row-links {
                align-items: center;
                border-bottom: none;
                display: flex;
                padding-left: 20px;

                &-edit,
                &-view {
                    background: none;
                    padding: 0;
                    height: auto;

                    &::before {
                        background-position: center;
                    }
                }

                &-delete {
                    background: none;
                    padding: 0;
                    margin-left: 12px;
                    height: auto;

                    @media (max-width: $msv-breakpoint-m) {
                        margin-left: 0;
                    }
                }

                &-edit {
                    @include add-svg-icon($icon-edit, 20px, 20px);

                }

                &-delete {
                    @include add-svg-icon($icon-trash, 20px, 20px);
                }

                &-view {
                    @include add-icon($msv-List);
                }
            }
        }

        .ms-table__heading-row-data {
            pointer-events: none;

            &:focus {
                outline: 1px dashed;
            }
        }
    }

    &__modal {
        padding: 16px 24px;

        .msc-modal__header {
            margin: 0 -24px 20px;
            padding: 0 24px 14px;
            border-bottom: 1px solid $msv-soft-peach;

            .msc-modal__title {
                border: 0;
                padding: 0;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                color: $msv-black;
                margin: 0;
            }
        }

        .msc-modal__dialog {
            input:disabled {
                background-color: transparent;
            }

            input {
                border-color: $msv-light-grey;
                height: 43px;
            }

            .msc-modal__content {
                padding: 24px;
            }
        }

        .msc-modal__body {
            margin-bottom: 0;
            padding: 0;

            @media (max-width: $msv-breakpoint-m) {
                padding: 20px 0 !important;
            }
        }

        .msc-modal__footer {
            justify-content: center;

            @media (max-width: $msv-breakpoint-m) {
                padding: 20px 0 0 !important;
            }

            button {
                &:last-child {
                    @media (max-width: $msv-breakpoint-m) {
                        margin-bottom: 0;
                    }

                }
            }
        }

        &-heading {
            margin-bottom: 40px;
        }

        &-body {
            @include font-content-l();
            display: flex;
            flex-wrap: wrap;
            padding-left: $msv-business-organization-list-modal-padding;
            border: 0;
        }

        .msc-modal {
            &__content {
                background-color: $msv-white;
                padding: 40px;
            }

            &__dialog {
                align-items: center;
                display: flex;
                height: 100%;
            }

            &__body {
                @media (max-width: $msv-breakpoint-m) {
                    max-height: calc(100vh - 180px);
                }
            }
        }

        ul.ms-business-organization-list__form-list {
            list-style: none;

            .confirmation-message {
                font-size: 16px;
                font-weight: 600;
                color: $msv-green;
                margin-bottom: 20px;
                display: block;
            }
        }

        &.type-remove {
            .ms-business-organization-list__form-description {
                display: none;
            }
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-all-required-msg {
            @include font-content-m(var(--msv-font-weight-light));
            flex-basis: 100%;
            margin-bottom: 32px;
        }

        &-description {
            @include font-content-l();
            margin-top: 20px;
        }

        &-list-item {
            width: 50%;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
            color: $msv-black;
            display: inline-flex;
            flex-direction: column;
            margin: 0 0 30px;

            @media (max-width: $msv-breakpoint-l) {
                margin: 0 0 16px;
                width: 100%;
            }

            .detete-label {
                @media (max-width: $msv-breakpoint-l) {
                    font-weight: 500;
                }
            }


            &-name {
                width: 300px !important;
                white-space: nowrap !important;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                color: $msv-black;
                display: inline-flex;
                flex-direction: column;
                margin: 0 0 30px;
            }

            span:nth-child(2) {
                margin-left: $msv-business-organization-list-list-item-spacing;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            margin: 0 0 25px;

            &.width-50 {
                width: calc(50% - 12.5px);
            }

            &.width-100 {
                width: 100%;
            }

            &-label {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.2px;
                margin-bottom: 3px;
                color: $stone;
            }

            &-input {
                @include font-content-l();
                @include vfi();
                background-color: $msv-gray-100;
                border: $msv-business-organization-list-input-border;
                box-sizing: border-box;
                display: block;
                height: $msv-business-organization-list-input-size;
                padding: $msv-business-organization-list-input-padding;
                border-radius: 0px;
                border: 1px solid $msv-supernova;
                font-size: 14px;
                padding-left: 15px;

                &:focus,
                &:active {
                    border: 1px solid $msv-supernova;
                }
            }
        }

        &-save,
        &-remove-user-button {
            @include primary-btn();
            min-width: 150px;
        }

        &-cancel {
            @include secondary-btn();
            margin: 0 20px;
            min-width: 150px;
        }

        @media only screen and (max-width: $msv-breakpoint-l) {
            .msc-modal__content {
                padding: $msv-business-organization-list-modal-padding;
            }

            &__form-item {
                margin-right: $msv-business-organization-list-form-item-margin-right;

                &.width-50,
                &.width-100 {
                    width: 100%;
                }
            }

            &__save-button,
            &__cancel-button {
                width: calc(50% - #{$msv-business-organization-list-input-spacing});
            }
        }
    }

    .ms-table-business-org__pagination {
        padding-top: 20px;
        width: 70%;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }

        .msc-pagination {
            @include font-content(var(--msv-font-weight-normal),
                var(--msv-business-organization-list-pagination-text-font-size),
                $msv-line-height-m );
            display: flex;
            list-style: none;
            padding-left: $msv-business-organization-list-pagination-padding-left;
            width: 100%;
        }

        ul.msc-pagination {
            display: flex;
            justify-content: center;
        }

        .previous {
            margin-right: auto;

            .msc-pagination__prev {
                padding-left: 4px;
                display: inline-block;
            }

            .msc-page-link {
                color: var(--msv-business-organization-list-text-font-color);
                font-size: 14px;
                display: block;
                padding: $msv-business-organization-list-page-item-page-link-padding;
                line-height: $msv-business-organization-list-page-item-page-link-line-height;
                background-color: $gallery;
                border-radius: 2px;
                min-width: 90px;
                display: inline-block;
                text-align: center;

                &::before {
                    @include msv-icon();
                    content: '';
                }

                .ms-table-business-org__pagination-left {
                    @include add-icon($msv-ChevronLeft, before);
                }
            }
        }

        .msc-page-item {
            padding-right: $msv-business-organization-list-page-item-padding-right;
        }

        .msc-page-item.disabled {
            .msc-page-link {
                color: $msv-secondary;
                pointer-events: none;
                cursor: auto;
            }
        }

        .next {
            margin-left: auto;
            padding-right: 0px;

            .msc-pagination__next {
                padding-right: 4px;
                display: inline-block;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-page-link {
                color: var(--msv-business-organization-list-text-font-color);
                font-size: 14px;
                display: block;
                padding: $msv-business-organization-list-page-item-page-link-padding;
                line-height: $msv-business-organization-list-page-item-page-link-line-height;
                padding-right: 4px;
                background-color: $gallery;
                border-radius: 2px;
                min-width: 90px;
                display: inline-block;
                text-align: center;

                &::after {
                    @include msv-icon();
                    content: '';
                }

                .ms-table-business-org__pagination-right {
                    @include add-icon($msv-ChevronRight, after);
                }
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .msc-page-link {
            color: var(--msv-business-organization-list-text-font-color);
            display: block;
            line-height: $msv-business-organization-list-page-item-page-link-line-height;
            font-size: 14px;
            padding: 5px 10px;
            border-radius: 2px;
            background-color: $gallery;

            @include vfi();
        }

        .active {
            text-decoration: underline;
        }

        .prev-text,
        .next-text {
            padding: $msv-business-organization-list-page-prev-next-padding;
        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        padding: 0;

        &__add-user-button {
            width: 50%;
        }

        &__form {
            &-item {

                &.width-25,
                &.width-50,
                &.width-100 {
                    margin-right: 0;
                    width: 100%;
                }
            }

            &-cancel {
                background-color: $msv-white;
            }
        }

        &__modal {
            &.type-details {
                margin-right: 20px;
                margin-left: 20px;
            }

            &.type-view {
                .ms-business-organization-list__form-cancel {
                    width: 100%;
                }
            }
        }

        .ms-table {
            &__heading {

                &-row,
                &-row-data {
                    height: 42px;
                }

                &-sorting {
                    height: 42px;
                    display: flex;
                    align-items: center;
                }
            }

            &__row-links {
                align-items: flex-start;
                background: $msv-white;
                border-radius: 2px;
                box-shadow: $msv-business-organization-list-actions-shadow;
                flex-direction: column;
                height: auto;
                padding-left: 0;
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 10;

                &-edit,
                &-delete,
                &-view {
                    background: none;
                    color: var(--msv-business-organization-list-actions-text-color);
                    height: 40px;
                    padding: 0 8px;
                    text-align: left;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 600;

                    @media (max-width: $msv-breakpoint-m) {
                        display: flex;
                        align-items: center;
                    }

                    &::before {
                        margin-right: 12px;
                    }
                }

                &-minified {
                    text-align: right;
                }

                &-toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: auto;
                }
            }

            &__row {
                position: relative;

                &-data {
                    font-weight: 600;
                }

                &.selected {
                    background-color: $msv-selected-user-background-color;

                    .ms-table__row-links-toggle {
                        background-color: $msv-selected-user-background-color;
                    }
                }
            }
        }
    }

    &__padRight {
        padding-right: 40px;
    }

    .ms-business-organization-list__buttonBar {
        justify-content: flex-end;
        display: inline-flex;
        width: 50%;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }

        .ms-business-organization-list__add-user-button {
            margin: 0;
            min-width: 175px;
        }
    }

    &__page-error-text {
        color: $msv-shiraz;
        margin-bottom: 8px;
    }
}

.ms-business-organization-list__form-list li:nth-last-child(1) {
    display: none;
}

.ms-business-organization-list__table tbody .ms-table__row .ms-table__row-data:nth-last-child(1) .ms-table__row-links {
    justify-content: flex-end;
    display: flex;
    padding: 2px 2px 2px 20px;
}

.del-user {
    width: 100% !important;

    @media (max-width: $msv-breakpoint-l) {
        margin: 0;
    }

    .name-contianer {
        display: flex;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column;
        }
    }

    ._first-name {
        min-width: 410px;

        @media (max-width: $msv-breakpoint-l) {
            min-width: 100%;
            margin-bottom: 16px;
        }

        .detete-label {
            @media (max-width: $msv-breakpoint-l) {
                font-weight: 500;
            }
        }
    }

    ._last-name {
        @media (max-width: $msv-breakpoint-l) {
            min-width: 100%;
            margin-bottom: 16px;
        }

        .detete-label {
            @media (max-width: $msv-breakpoint-l) {
                font-weight: 500;
            }
        }
    }
}