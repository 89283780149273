.home_hero-main {
    .msc-carousel__item {
        height: 550px;

        @media (max-width: $msv-breakpoint-m) {
            height: auto;
            min-height: 550px;
        }
    }

    .texttheme__dark,
    .login-user-banner1 {
        p {
            color: $msv-black;
        }

        .msc-cta__primary {
            background-color: $msv-black;
            border: solid 1px $msv-black;
            color: $msv-white;
            border-radius: 0px;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            line-height: normal;
            padding: 10px 25px;
            margin: 3px;

            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 16px;
            }
        }

        .msc-cta__secondary {
            background-color: transparent;
            border: solid 1px $msv-black;
            color: $msv-black;
            border-radius: 0px;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            line-height: normal;
            padding: 10px 25px;
            margin: 3px 15px;
        }
        .ms-content-block__details__additional-content-cta-links {
            color: $msv-black;
        }
    }

    .product-image-slide {
        &.texttheme__dark,
        &.login-user-banner1,
        &.texttheme__light {
            display: flex;
            height: 100%;

            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }

            p {
                width: 50%;
                margin-bottom: 8px;
                padding-top: 15px;

                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                }
            }

            img {
                display: unset;
                width: auto !important;
                position: relative;
                top: 20px;
                right: 200px;
                height: 450px !important;
                max-height: 425px;

                @media (max-width: $msv-breakpoint-ml) {
                    right: 100px;
                }

                @media (max-width: $msv-breakpoint-m) {
                    width: auto !important;
                    height: auto !important;
                    position: static;
                    max-height: 325px;
                    margin: 0 auto;
                }
            }

            .ms-content-block__cta {
                margin-top: 80px !important;

                @media (max-width: $msv-breakpoint-m) {
                    margin-top: 24px !important;
                }
            }

            .ms-content-block__image {
                text-align: right;

                @media (max-width: $msv-breakpoint-m) {
                    text-align: center;
                }
            }
            .ms-content-block__details::before {
                margin-top: 40px !important;
            }

            .ms-content-block__details {
                @media (max-width: $msv-breakpoint-m) {
                    justify-content: flex-start !important;
                }
            }

            .ms-content-block__image {
                text-align: right;

                @media (max-width: $msv-breakpoint-m) {
                    text-align: center;
                }
            }
        }

        .ms-content-block__details {
            @media (max-width: $msv-breakpoint-m) {
                position: static !important;
            }
        }
    }

    img {
        height: 550px !important;
    }

    h3 {
        padding-left: 70px;
        max-width: 700px;
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        color: $msv-black;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 0;
            font-size: 19px;
            width: 100%;
            font-size: 28px;
        }
    }

    .bg-div-for-auth-users {
        background-color: transparent;
    }

    .msc-carousel__item {
        background-color: $msv-white;
        border-bottom: solid 10px $msv-soft-peach;
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-content-block__details__additional-content__text {
        width: 100%;
        font-size: 0px;
    }

    .ms-content-block__details__additional-content {
        width: 100%;
    }

    .ms-content-block__details__additional-content-cta {
        flex-grow: 1;
    }

    .ms-content-block__details__additional-content-cta-links {
        color: $msv-white;
        font-size: 18px;
        text-decoration: underline;
        margin-left: 4px;
        display: inline-block;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        @media (max-width: $msv-breakpoint-m) {
            margin: 8px 0 0;
        }
    }

    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        border-radius: 0px;
        background-color: rgba(0, 0, 0, 0.3);
        color: $msv-white;
        border: none;
        margin: 0px;

        &:hover,
        &:focus {
            opacity: 1;
        }

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }

    .ms-content-block__details {
        padding-left: 60px !important;
    }

    .ms-content-block[data-m-layout='full-width'] {
        .ms-content-block__details {
            @media (max-width: 1200px) {
                left: 0;
                right: 0;
                max-width: 100%;
            }

            @media (max-width: $msv-breakpoint-m) {
                padding: 24px 16px !important;
                position: absolute;
            }
        }
    }

    .ms-content-block__details {
        width: 100% !important;
        display: flex;
        justify-content: space-between !important;
        min-height: 409px;

        &::before {
            border-top: solid 4px $msv-supernova;
            border-left: solid 4px $msv-supernova;
            border-bottom: solid 4px $msv-supernova;
            width: 60px;
            content: '';
            position: absolute;
            height: 265px;
            margin-right: 30px;
            margin-top: 30px;

            @media (max-width: $msv-breakpoint-m) {
                display: none;
            }
        }

        .ms-content-block__details__additional-content__container {
            display: flex;
            align-items: center;
            margin-left: 70px;
            margin-top: 50px;

            @media (max-width: $msv-breakpoint-m) {
                margin-left: 0;
                flex-direction: column;
                align-items: flex-start;
            }

            p {
                width: unset;
            }
        }
    }

    .ms-content-block__cta {
        display: flex;
        align-items: center;
        margin-left: 70px;

        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
            margin-left: 0;
            width: 100%;
            margin-top: 100px !important;
        }
    }

    .msc-cta__primary {
        @include hero-primary-btn();

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin-bottom: 16px;
        }
    }

    .msc-cta__secondary {
        @include hero-secondary-btn();

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            text-align: center;
            font-size: 14px;
        }
    }

    .ms-content-block__details {
        max-width: 1300px;
        margin: auto;
    }

    .ms-content-block__text {
        max-width: 100% !important;
        margin-left: 70px;
        width: 100%;

        @media (max-width: $msv-breakpoint-m) {
            margin-left: 0;
        }
    }

    h1 {
        color: $msv-white;
        font-size: 64px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
        }
    }

    h2 {
        color: $msv-supernova;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-left: 70px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 19px;
            text-align: left;
            margin-left: 0;
        }
    }

    p {
        font-size: 18px;
        color: $msv-white;
        font-weight: normal;
        line-height: normal;
        width: 70%;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .msc-carousel__indicators {
        li {
            border-radius: 0;
            width: 45px;
            height: 7px;
            border: none;
            background-color: $msv-light-grey;
        }

        .active {
            background-color: $msv-shiraz;
            border: none;
        }

        .ms-content-block__details {
            margin-top: 100px !important;
            margin: auto !important;
            text-align: center !important;
        }
    }

    a {
        display: block;
    }
}

.main-home-area {
    h1 {
        margin-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
        font-size: 36px;
        font-weight: normal;
        padding-top: 25px;
        color: $msv-supernova;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 32px;
        }
    }

    h4 {
        text-align: center;
        font-size: 28px;
        font-weight: 200;
        max-width: 800px;
        margin: auto;
        border-bottom: solid 1px $msv-light-grey;
        padding-bottom: 25px;
    }

    p {
        font-size: 16px;
    }

    h4 {
        @media (max-width: $msv-breakpoint-m) {
            font-size: 28px;
        }
    }
}

.home_video-container a {
    @include primary-btn();
    background-color: $msv-white;
    border: solid 1px $msv-white;
    padding: 10px 50px;
}

.home-product-carousel {
    &.container {
        padding-left: 0;
        padding-right: 0;
        margin-top: 80px;

        @media (max-width: $msv-breakpoint-m) {
            margin-top: 40px;
        }
    }

    .ms-product-collection {
        margin-bottom: 0px;
    }

    .ms-content-block {
        @media (max-width: $msv-breakpoint-m) {
            min-height: auto;
        }
    }

    .msc-ss-carousel__flipper--next {
        top: 50%;
        right: -50px;
        left: 1320px !important;

        @media (max-width: 1370px) {
            left: unset !important;
            right: 0;
        }

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }

    .msc-ss-carousel__flipper--prev {
        top: 50%;
        left: -80px;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }

    .msc-flipper {
        border-radius: 0px;
        border: solid 1px $alto;
        background-color: $alto;
        top: 50%;
        left: -80px;

        @media (max-width: 1370px) {
            left: 0;
        }

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }

    ._image {
        width: 150px;
        height: 150px;
    }

    h4 {
        text-transform: uppercase;
    }

    ._link-container {
        a {
            display: block;
            position: absolute;
            right: 0px;
            background: $msv-black;
            color: $msv-white;
            width: 40px;
            height: 40px;
            bottom: 0px;

            @media (max-width: $msv-breakpoint-m) {
                right: 0px;
            }

            &::before {
                @include msv-icon();
                content: '\f061';
                padding: 14px;
            }

            &:hover,
            &:focus {
                background-color: $msv-supernova;

                &::before {
                    color: $msv-black;
                }
            }
        }
    }

    ._info-container {
        white-space: normal;

        ._title {
            a {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 44px;
            }
        }
    }

    .bg-div-for-auth-users {
        background-color: transparent;
    }

    .product-tiles {
        padding: 0px;

        ._logo-image {
            display: block;
        }

        .ms-content-block__details {
            min-height: 155px;
        }

        .msc-product__text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        ul {
            li {
                // width: 293px;
                padding: 0;
                flex-direction: column;

                .request-price-top-rated-parent {
                    width: 100%;
                }

                .product-card-container {
                    position: relative;
                    border: solid 1px $msv-soft-peach;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                    margin: 15px;
                    padding: 15px;
                    min-height: 385px;
                    background-color: $msv-white;
                    width: 100%;

                    &:hover,
                    &:focus {
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                        transition: width 0.1s;
                    }

                    .badge {
                        margin-left: -15px;
                    }
                }

                a {
                    text-decoration: none;
                }
            }

            ._image-contianer {
                margin: 0 auto;

                img {
                    max-height: 100%;
                    margin: 0 auto;
                }
            }
        }

        .ms-content-block {
            border: solid 1px $msv-soft-peach;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            margin: 15px;
            padding: 15px;
            min-height: 385px;
            display: flex;
            flex-direction: column;
            background-color: $msv-white;

            &:hover,
            &:focus {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                transition: width 0.1s;
            }

            h5 {
                padding: 0px;
                text-align: left;
            }

            .ms-content-block__image {
                width: 125px;
                margin: 10px 0px;
                min-height: 165px;
                display: flex;
                align-items: center;

                @media (max-width: $msv-breakpoint-m) {
                    width: 200px;
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .msc-ss-carousel {
        .msc-flipper {
            i {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .msi-chevron-left:before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='17' viewBox='0 0 26 17' fill='none'%3E%3Cpath d='M2 8.58462L1.29289 7.87751L0.585786 8.58462L1.29289 9.29172L2 8.58462ZM24.7538 9.58462C25.3061 9.58462 25.7538 9.1369 25.7538 8.58462C25.7538 8.03233 25.3061 7.58462 24.7538 7.58462V9.58462ZM8.87751 0.292893L1.29289 7.87751L2.70711 9.29172L10.2917 1.70711L8.87751 0.292893ZM1.29289 9.29172L8.87751 16.8763L10.2917 15.4621L2.70711 7.87751L1.29289 9.29172ZM2 9.58462H24.7538V7.58462H2V9.58462Z' fill='%23323232'/%3E%3C/svg%3E");
                height: 15px;
                width: 22px;
                background-size: contain;
            }

            .msi-chevron-right:before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='18' viewBox='0 0 28 18' fill='none'%3E%3Cpath d='M25.7363 8.99982L26.4434 8.29272L27.1505 8.99982L26.4434 9.70693L25.7363 8.99982ZM1.67973 9.99982C1.12744 9.99982 0.679726 9.55211 0.679726 8.99982C0.679726 8.44754 1.12744 7.99982 1.67973 7.99982V9.99982ZM18.4246 0.27385L26.4434 8.29272L25.0292 9.70693L17.0104 1.68806L18.4246 0.27385ZM26.4434 9.70693L18.4246 17.7258L17.0104 16.3116L25.0292 8.29272L26.4434 9.70693ZM25.7363 9.99982H1.67973V7.99982H25.7363V9.99982Z' fill='%23323232'/%3E%3C/svg%3E");
                height: 15px;
                width: 22px;
                background-size: contain;
            }
        }
    }

    h3 {
        width: 100%;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        color: $msv-storm-dust;
    }

    h2 {
        width: 100%;
        font-size: 38px;
        font-weight: 700;
        color: $msv-black;
        padding-right: 50px;

        @media (max-width: $msv-breakpoint-m) {
            padding-right: 0;
            font-size: 24px;
        }
    }

    .ms-content-block__cta {
        a {
            display: block;
            position: absolute;
            right: 16px;
            background: $msv-black;
            width: 40px;
            height: 40px;
            bottom: 15px;

            @media (max-width: $msv-breakpoint-m) {
                right: 0px;
            }

            ::before {
                @include msv-icon();
                content: '\f061';
                color: $msv-white;
                padding: 12px;
            }

            &:hover,
            &:focus {
                background-color: $msv-supernova;
                color: $msv-supernova;

                ::before {
                    color: $msv-black;
                }
            }
        }
    }

    .ms-content-block {
        @media (max-width: $msv-breakpoint-m) {
        }
    }

    h5 {
        text-align: left;
        font-size: 18px !important;
        margin: 0px;
        padding: 0px 15px;
        margin-bottom: 10px !important;
        text-transform: uppercase;
        color: $msv-dark-junglel;
    }

    p {
        text-align: left;
        font-size: 14px;
    }

    .latest-product-main-txt {
        p {
            font-size: 20px;
            color: $msv-storm-dust;
            line-height: normal;
            padding-top: 15px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 18px;
            }
        }
    }
}

.product-text-area {
    max-width: 1280px;
    margin: auto;

    @media (max-width: $msv-breakpoint-l) {
        padding: 0 16px;
    }
}

.home_contact-section {
    margin-top: 100px;
    max-width: 1265px !important;

    @media (max-width: $msv-breakpoint-m) {
        margin-bottom: 0px;
    }

    a {
        color: $msv-black;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .msc-main_image {
        height: auto !important;
    }

    .msc-active-image {
        @media (max-width: $msv-breakpoint-m) {
            max-height: 350px !important;
        }
    }

    .msc-main_image {
        @media (max-width: $msv-breakpoint-m) {
            width: 580px;
            height: 315px;
        }
    }
}

.ms-video-player {
    margin: 40px 0px;

    @media (max-width: $msv-breakpoint-m) {
        margin: 0px;
    }
}

.main-icon-area {
    margin-top: 20px;
    margin-bottom: 50px;

    .ms-content-block {
        width: 220px;
        max-width: 220px;

        @media (max-width: $msv-breakpoint-m) {
            padding: 0 16px;
            margin: 0 auto;
        }
    }

    .ms-content-block__details {
        padding: 20px;

        @media (max-width: $msv-breakpoint-m) {
            padding: 8px 0;
        }
    }

    .default-container-3-slots {
        padding: 0px;
    }

    img {
        background-color: $msv-white;
        padding: 40px;
        border: solid 1px $msv-soft-peach;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
    }

    h2 {
        font-size: 16px;
        font-weight: 700;
    }

    p {
        color: $msv-storm-dust;
        line-height: 19px;
        font-size: 14px;
    }

    .main-icons {
        padding-left: 0;
        padding-right: 0;

        & > .row {
            .col-12 {
                max-width: 220px;
            }

            @media (max-width: $msv-breakpoint-m) {
                flex-wrap: nowrap;
                overflow: auto;
            }
        }

        &.no-border {
            img {
                border: 0;
                box-shadow: none;
            }
        }

        .row {
            .col-12 {
                margin: 25px;
                width: 220px;
                max-width: 220px;

                @media (max-width: $msv-breakpoint-l) {
                    margin: 0;
                }

                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                    max-width: 100%;
                }

                &:nth-child(1) {
                    margin-left: 0px;
                }

                &:nth-last-child(1) {
                    margin-right: 0px;
                }
            }
        }
    }

    .msc-cta__primary,
    .msc-cta__secondary {
        @include primary-btn();
        width: 100%;
        margin-bottom: 16px;
        min-width: 100%;
        height: 35px;

        &::after {
            @include msv-icon();
            content: $msv-ChevronDown;
            transform: rotate(-90deg);
            margin-left: 4px;
            font-size: 12px;
        }
    }
}

.focus-products-area {
    position: relative;
    top: -91px;
    background-color: $msv-light-grey;
    padding: 10px !important;

    @media (max-width: $msv-breakpoint-l) {
        top: 0;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    @media (max-width: $msv-breakpoint-m) {
        position: unset;
    }

    .msc-flipper {
        display: none;
    }

    .msc-ss-carousel {
        padding-top: 0px;
    }

    .ms-product-collection {
        margin-bottom: 0px;
    }

    ul {
        li {
            width: 33.3%;
            padding: 5px 20px 5px 0px;

            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
            }

            a {
                background-color: $msv-white;
                text-decoration: none;
                margin: 10px;
                min-height: 230px;
                width: 100%;
                display: block;

                .msc-empty_image {
                    height: 150px;
                    width: 100%;
                }

                h4 {
                    padding-left: 15px;
                    font-size: 16px;
                }

                ._info {
                    width: 60%;
                    color: $msv-storm-dust;
                    font-size: 16px;
                    font-weight: 700;

                    .badge-wrapper {
                        text-align: right;
                        margin-right: -15px;
                        .badge {
                            max-width: 100%;
                            font-size: 14px;
                            font-weight: 600;
                            padding: 10px;
                        }
                    }
                }

                ._image {
                    width: 40%;
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: auto;
                        max-width: 100%;
                        max-height: 150px;
                    }
                }

                ._request-price,
                ._link {
                    background-color: $msv-black;
                    color: $msv-white;
                    padding: 8px 15px;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    position: absolute;
                    left: 0px;
                    bottom: 5px;
                    min-height: auto;
                    width: 42%;

                    @media (max-width: $msv-breakpoint-ml) {
                        width: calc(100% - 20px);
                        position: static;
                    }

                    &::before {
                        @include msv-icon();
                        content: '\f061';
                        padding-right: 10px;
                    }

                    &:hover,
                    &:focus {
                        background-color: $msv-supernova;
                        color: $msv-black;
                        text-decoration: none;
                    }
                }

                ._image-contianer {
                    width: 40%;
                }

                .msc-product__text {
                    padding-left: 15px;
                    color: $msv-black;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: normal;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    /* number of lines to show */
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                }

                ._info-container {
                    display: flex;
                    padding: 15px;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                ._info-container {
                    a {
                        min-height: auto;
                        background-color: transparent;
                        margin: auto;
                        padding: auto;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

.yellow-bar-area {
    background-color: $msv-supernova;
    padding: 25px;
    margin: 100px 0px;

    @media (max-width: $msv-breakpoint-l) {
        margin: 40px 0px;
    }

    .bg-div-for-auth-users {
        background-color: transparent;
    }

    img {
        display: unset;
        min-width: 40px;
    }

    h6 {
        font-size: 18px;
        font-weight: 500;
        padding-right: 25px;
        line-height: normal;
    }

    .ms-content-block {
        display: flex;
        min-height: auto;
        align-items: center;

        @media (max-width: $msv-breakpoint-l) {
            margin-bottom: 16px;
        }

        &__image {
            width: 30%;
            text-align: right;
            padding-right: 15px;

            @media (max-width: $msv-breakpoint-m) {
                text-align: center;
            }
        }

        &__details {
            width: 100%;
        }
    }

    &-content {
        .row {
            @media (max-width: $msv-breakpoint-l) {
                flex-direction: column;
            }

            .col-md-3 {
                @media (max-width: $msv-breakpoint-l) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

.tagline {
    text-align: center;
    padding: 0px 0px 30px 0px;

    @media (max-width: $msv-breakpoint-m) {
        padding: 0;
    }

    h1 {
        color: $msv-smalt-blue;
        font-weight: 700;
        width: 100%;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
            padding: 0 16px;
        }
    }
}

.home_contact-section {
    border-top: solid 5px $msv-supernova;
    background-color: $msv-soft-peach;
    padding: 0px !important;

    h2 {
        font-size: 36px;
        font-weight: 700;
        color: $msv-black;
        line-height: 36px;
        text-transform: capitalize;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 20px;
            margin: 0;
            line-height: 1.5;
        }
    }

    .home_contact-section-txt {
        padding: 50px;
        color: $msv-smalt-blue;
        margin: 0px;
        background-color: $msv-soft-peach;

        @media (max-width: $msv-breakpoint-m) {
            min-height: auto;
            padding: 16px;
        }

        img {
            display: none;
        }

        p {
            line-height: 32px;
            font-size: 18px;
            color: $msv-black;

            @media (max-width: $msv-breakpoint-m) {
                line-height: 16px;
                font-size: 24px;
            }
        }
    }

    .home_contact-time {
        padding-top: 15px;
        font-size: 22px;
        color: $msv-black;
        font-weight: normal;

        @media (max-width: $msv-breakpoint-m) {
            line-height: 24px;
            font-size: 16px;
            padding-top: 0;
        }

        p {
            line-height: 32px;
            font-size: 22px;
            padding-top: 10px;

            @media (max-width: $msv-breakpoint-m) {
                line-height: 24px;
                font-size: 16px;
            }
        }
    }
}

.watch-now {
    background-color: $msv-soft-peach;
    padding: 75px 0px;
    margin: 100px 0 0 0;

    @media (max-width: $msv-breakpoint-m) {
        margin-top: 40px;
    }

    .bg-div-for-auth-users {
        background-color: transparent;
    }

    img {
        height: auto;
    }

    h4 {
        color: $msv-storm-dust;
        font-weight: 600;
        padding: 15px 0;
    }

    h1 {
        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
        }
    }

    h3 {
        color: $msv-storm-dust;
        font-size: 32px;
        font-weight: 500;
        padding: 15px 0;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 18px;
        }
    }

    p {
        font-size: 16px;
        color: $msv-black;
        padding: 15px 0;
    }

    a {
        border: solid 1px $msv-black;
        padding: 5px 15px;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        text-decoration: none;
        margin-bottom: 16px;

        &:hover,
        &:focus {
            background-color: $msv-supernova;
            color: $msv-black;
            border: solid 1px $msv-supernova;
            text-decoration: none;
        }

        &::after {
            @include msv-icon();
            content: '\f0da';
            padding-left: 15px;
            font-size: 28px;
        }
    }

    .watch-now-img {
        padding-right: 25px;

        @media (max-width: $msv-breakpoint-l) {
            padding-right: 0;
        }

        .ms-content-block__details {
            background-color: $msv-supernova;
            padding: 20px;
            width: 190px;
            position: absolute;
            right: 30px;
            bottom: 100px;
            box-shadow: 0px 39px 13px 11px rgba($msv-black, 0.3);

            &::before {
                border-top: 35px solid $msv-supernova;
                border-left: 50px solid transparent;
                border-right: 0px solid transparent;
                content: '';
                height: 0;
                right: 0;
                position: absolute;
                width: 0;
                bottom: -30px;
            }

            h5 {
                font-size: 18px;
                font-weight: 700;
                line-height: normal;
                color: $msv-black;
                padding: 0px;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    .watch-now-content {
        padding-left: 25px;

        @media (max-width: $msv-breakpoint-l) {
            padding: 0 16px 0;
        }

        &.with-steps {
            h1 {
                font-size: 28px;
            }

            h3,
            ol,
            ul,
            p {
                font-size: 18px;
                color: $msv-storm-dust;
                font-weight: 500;
            }

            ol,
            ul {
                padding-left: 16px;
                color: $msv-storm-dust;

                li {
                    margin-bottom: 8px;

                    a {
                        padding: 0;
                        margin: 0;
                        border: 0;
                        color: $msv-storm-dust;

                        &::after {
                            display: none;
                        }

                        &:hover {
                            background-color: transparent;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .ms-content-block__cta {
                margin-top: 32px;
            }
        }
    }

    &.profile-watch {
        padding: 0;
        margin: 105px -15px 0;
        width: calc(100% + 30px);

        .col-auto {
            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
            }
        }
    }
}

.newsletter-area {
    background-color: $msv-pale-teal;
    padding: 50px 0px;

    .news-letter_title-contianer {
        background-image: url("data:image/svg+xml,%3Csvg width='69' height='54' viewBox='0 0 69 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M58.9916 0H9.83193C4.39965 0 0 4.4359 0 9.91293V44.0871C0 49.5641 4.39965 54 9.83193 54H58.9916C64.4239 54 68.8235 49.5641 68.8235 44.0871V9.91293C68.8235 4.4359 64.4239 0 58.9916 0ZM63.9076 44.0871C63.9076 46.8131 61.6954 49.0435 58.9916 49.0435H9.83193C7.12815 49.0435 4.91597 46.8131 4.91597 44.0871V9.91293C4.91597 7.18688 7.12815 4.95647 9.83193 4.95647H58.9916C61.6954 4.95647 63.9076 7.18688 63.9076 9.91293V44.0871ZM58.6964 15.8097C59.3602 16.999 58.9175 18.5108 57.7379 19.1803L35.616 31.4974C34.8786 31.9189 33.9689 31.9189 33.2315 31.4974L11.1096 19.2052C8.30768 17.5943 10.618 13.3315 13.4942 14.8683L34.4362 26.5409L55.3782 14.8683C56.5825 14.1989 58.0573 14.6453 58.7212 15.8347L58.6964 15.8097Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 0 0;
        padding-left: 85px;
    }

    .news-letter_contianer {
        width: 1300px;
        max-width: 100%;
        padding: 0 15px;
        margin: auto;
        display: flex;
        align-items: center;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column;
        }

        @media (max-width: $msv-breakpoint-m) {
            display: unset;
        }
    }

    h3 {
        font-size: 32px;
        margin: 0px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 18px;
            margin-bottom: 8px;
        }
    }

    h4 {
        font-size: 16px;
        font-weight: normal;
        margin: 0px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 14px;
        }
    }

    .news-letter_action-contianer {
        padding: 0 0 0 100px;
        display: flex;

        @media (max-width: 1320px) {
            padding: 0 0 0 20px;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding: 24px 0 0 0;
            flex-wrap: wrap;
        }

        input {
            width: 480px;
            padding: 11px 10px;
            border: none;
            font-size: 16px;

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        a {
            @include primary-btn();
            margin-left: 10px;

            &.submit-disable {
                pointer-events: none;
                background-color: transparent;
                border-color: $msv-black;
                color: $msv-black;
            }

            @media (max-width: $msv-breakpoint-m) {
                margin-left: 0px;
                margin-top: 10px;
                width: 100%;
            }
        }

        .error {
            position: absolute;
            bottom: -15px;
            border-bottom: 1px solid $msv-shiraz;
            font-size: 12px;
            font-weight: 500;

            @media (max-width: $msv-breakpoint-m) {
                bottom: -25px;
            }
        }
    }
}

.yt-block {
    .bg-div-for-auth-users {
        background-color: transparent;
    }

    margin: 100px 0px 10px 0px;

    iframe {
        width: 100%;
        height: 400px;
    }

    .ms-content-block__details {
        margin: 0 0 0 50px;

        @media (max-width: $msv-breakpoint-m) {
            margin: 0;
        }
    }

    .card {
        border: none;
    }

    h4 {
        font-size: 18px;
        color: $msv-storm-dust;
        font-weight: 600;
    }

    h1 {
        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
        }
    }

    h2 {
        font-size: 32px;
        color: $msv-storm-dust;
        font-weight: 500;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 20px;
        }
    }

    p {
        font-size: 16px;
        font-weight: normal;
        color: $msv-storm-dust;
        line-height: normal;
        padding: 15px 0;
    }

    a {
        border: solid 1px $msv-black;
        padding: 10px 35px;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: $msv-supernova;
            color: $msv-black;
            border: solid 1px $msv-supernova;
            text-decoration: none;
        }
    }
}

.webshop-reg {
    display: flex;

    @media (max-width: $msv-breakpoint-m) {
        flex-direction: column;
    }
}

.webshop-reg-area {
    margin: auto;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    background-color: $msv-supernova;

    .msc-carousel__indicators {
        display: none;
    }

    h2 {
        font-size: 32px;
        font-weight: 700;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
            color: $msv-black;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;
    }

    .ms-content-block__details {
        position: unset;
        height: auto;
        width: 45%;
        padding: 30px 60px 60px 60px;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            padding: 30px 25px 60px 25px;
        }

        @media (min-width: $msv-breakpoint-l) {
            min-height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        a {
            background-color: $msv-storm-dust;
            color: $msv-white;
            font-size: 16px;
            padding: 14px 35px;
            text-decoration: none;
            margin-bottom: 0;
            text-transform: uppercase;

            @media (max-width: $msv-breakpoint-m) {
                padding: 16px 35px;
            }

            &:hover,
            &:focus {
                background-color: $msv-black;
                color: $msv-white;
                text-decoration: none;
            }
        }
        .ms-content-block__text {
            color: $msv-black;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.2;
    }

    .ms-content-block__image {
        width: 55%;
        max-height: unset;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }

        img {
            height: auto;
        }
    }

    .ms-content-block[data-m-layout='full-width'] {
        .ms-content-block__image {
            img {
                @media (max-width: $msv-breakpoint-l) {
                    max-height: 100%;
                }
            }
        }
    }

    .msc-carousel__control__prev {
        border-radius: 0px;
        background-color: $msv-black;
        opacity: 0.6;
        color: $msv-white;
        border: none;
        left: unset;
        right: 45%;
        top: unset;
        bottom: 0;
        width: 100px;
        position: absolute;

        @media (max-width: $msv-breakpoint-l) {
            left: unset;
            right: 102px;
            position: absolute;
            bottom: 0;
            top: unset;
        }

        .msc-carousel__control__prev__icon {
            margin: 0px;
            padding-left: 10px;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1 23a1 1 0 0 0 0-1.41L5.5 17h23.55a1 1 0 0 0 0-2H5.53l4.57-4.57A1 1 0 0 0 8.68 9l-6.36 6.37a.9.9 0 0 0 0 1.27L8.68 23a1 1 0 0 0 1.42 0Z' data-name='Layer 2' fill='%23ffffff' class='fill-000000'%3E%3C/path%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 30%;
            background-position-x: 18px;
            width: 100%;

            &::before {
                content: '';
            }
        }
    }

    .msc-carousel__control__next {
        border-radius: 0px;
        background-color: $msv-black;
        opacity: 0.6;
        color: $msv-white;
        border: none;
        right: unset;
        left: 55%;
        top: unset;
        bottom: 0;
        width: 100px;
        position: absolute;

        @media (max-width: $msv-breakpoint-l) {
            left: auto;
            right: 0;
            position: absolute;
            bottom: 0;
            top: unset;
        }

        .msc-carousel__control__next__icon {
            margin: 0px;
            padding-left: 10px;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 9a1 1 0 0 0 0 1.42l4.6 4.6H3.06a1 1 0 1 0 0 2h23.52L22 21.59A1 1 0 0 0 22 23a1 1 0 0 0 1.41 0l6.36-6.36a.88.88 0 0 0 0-1.27L23.42 9A1 1 0 0 0 22 9Z' data-name='Layer 2' fill='%23ffffff' class='fill-000000'%3E%3C/path%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-repeat: no-repeat;
            background-size: 30%;
            background-position-x: 50px;
            width: 100%;

            &::before {
                content: '';
            }
        }
    }
}

._logo-image {
    text-align: right;
    min-height: 50px;

    img {
        display: unset;
    }
}

.hero-banner-box-heading {
    h5 {
        padding-top: 50px;
    }

    h3 {
        padding-left: 70px;
        max-width: 700px;
        font-size: 36px;
        font-weight: 700;
        margin: 0px;
    }
}

.mobile-icons {
    display: none;

    .msc-carousel__inner {
        height: 400px;
    }

    h2 {
        color: $msv-black;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .msc-carousel__indicators {
        li {
            background: $msv-light-grey;
            border-radius: 0px;
            width: 15px;
            height: 15px;
        }

        .active {
            background: $msv-storm-dust;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;
    }
}

.mobile-icons-services {
    display: none;

    .msc-carousel {
        padding-bottom: 40px;
    }

    h2 {
        color: $msv-black;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .msc-carousel__indicators {
        li {
            background: $msv-light-grey;
            border-radius: 0px;
            width: 15px;
            height: 15px;
        }

        .active {
            background: $msv-storm-dust;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;
    }
}

.desktop-icons {
    @media (max-width: $msv-breakpoint-m) {
        display: none;
    }
}

.mx-homepage {
    .newsletter-area {
        h3 {
            font-size: 22px;
        }
        .news-letter_title-contianer {
            @media (min-width: $msv-breakpoint-l) {
                background-position: 0;
            }
        }
    }
}
