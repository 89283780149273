$promo-banner-line-height: 20px;
$promo-banner-bottom-padding: 5px;
$promo-banner-top-padding: 5px;
$promo-banner-link-margin-left: 4px;

//style presets
:root {
    --msv-promo-banner-bg: #{$msv-gray-1000};
    --msv-promo-banner-border: #{$msv-gray-1000};
    --msv-promo-banner-font-color: var(--msv-font-secondary-color);
    --msv-promo-banner-font-size: var(--msv-body-font-size-m);
}

.ms-promo-banner {
    display: flex;
    flex-wrap: wrap;
    background: $dove-grey;
    padding-bottom: 15px;
    padding-top: 15px;
    line-height: $promo-banner-line-height;
    width: 100%;

    .msc-carousel {
        max-width: 1300px;
        margin: auto;
        padding-left: 20px;
        padding-right: 0;

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        display: none;
    }

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    .msc-carousel__indicators {
        display: none;
    }

    .ms-promo-banner__links {
        display: flex;
    }

    .ms-promo-banner__text,
    .ms-promo-banner__link {
        @media (max-width: $msv-breakpoint-m) {
            font-size: 16px;
        }

        color: $msv-white;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .ms-promo-banner__link {
        margin-left: $promo-banner-link-margin-left;
        text-decoration: underline;
    }

    .ms-promo-banner__carousel {
        width: 98%;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 95%;
        }
    }

    .ms-promo-banner__close-button {
        color: var(--msv-font-secondary-color);
        background-color: transparent;
        border: none;
        cursor: pointer;

        &::after {
            @include msv-icon();
            content: $msv-x-shape;
        }

        .ms-promo-banner__close-button__label {
            display: none;
        }
    }

    .promo-text-container {
        margin-right: 16px;
        color: $msv-white;
        font-weight: 500;
        max-width: 596px;

        @media (max-width: 1300px) {
            max-width: 500px;
        }

        @media (min-width: $msv-breakpoint-ml) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: $msv-breakpoint-ml) {
            margin-top: 24px;
            margin-right: 0;
            text-align: center;
            max-width: unset;
        }
    }

    .links-container {
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding: 2px;

        @media (max-width: $msv-breakpoint-ml) {
            width: 100%;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding-left: 0;
            display: flex;
        }

        a {
            @media (max-width: $msv-breakpoint-m) {
                font-size: 12px;
            }
        }

        .uan-container {
            @media (max-width: $msv-breakpoint-l) {
                flex-grow: 1;
            }
        }
    }

    .hide-text {
        .link-text {
            @media (max-width: $msv-breakpoint-m) {
                display: none;
            }
        }
    }

    .promo-banner-container {
        display: flex;
        align-items: center;

        @media (max-width: $msv-breakpoint-ml) {
            flex-direction: column-reverse;
        }

        a {
            margin: 0 15px 0 5px;
            color: $msv-white;
            text-decoration: underline;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 25px;
            align-items: center;
            letter-spacing: 0.3px;

            @media (max-width: $msv-breakpoint-m) {
                margin-right: 0;
                margin-left: 0;
            }

            &::before {
                margin-right: 8px;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .Tracking {
            @include add-svg-icon($icon-tracking, 25px, 25px);

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &.hide-text {
                &::before {
                    @media (max-width: $msv-breakpoint-m) {
                        margin-right: 16px;
                    }
                }
            }
        }

        .buisness-partner-link {
            @include add-svg-icon($icon-partner, 25px, 25px);

            &.hide-text {
                &::before {
                    @media (max-width: $msv-breakpoint-m) {
                        margin-right: 0;
                    }
                }
            }
        }

        .ms-promo-banner__link {
            @include add-svg-icon($icon-contact, 25px, 25px);
            margin-left: 0;
        }
    }
}
