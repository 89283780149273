@import '../00-settings/index.scss';
@import '../01-tools/index.scss';
@import '../02-generic/index.scss';

$msc-promo-code-discount-heading-margin-top: 20px;
$msc-promo-code-discount-line-container-margin-top: 4px;
$msc-promo-code-discount-line-container-padding-bottom: 10px;
$msc-promo-code-discount-line-remove-btn-padding: 0 12px;
$msc-promo-code-alert-padding: 8px;

:root {
    --msv-promo-code-heading-color: var(--msv-font-primary-color);
    --msv-promo-code-heading-size: var(--msv-body-font-size-l);
    --msv-promo-code-link-color: var(--msv-font-primary-color);
    --msv-promo-code-link-size: var(--msv-body-font-size-m);
    --msv-promo-code-applied-discount-color: var(--msv-font-primary-color);
    --msv-promo-code-applied-discount-size: var(--msv-body-font-size-m);
    --msv-promo-code-border: var(--msv-font-primary-color);
    --msv-promo-code-form-input-bg: #{$msv-white};
    --msv-promo-code-form-input-font-color: var(--msv-font-primary-color);
    --msv-promo-code-form-input-border: var(--msv-btn-input-border-color);
    --msv-promo-code-form-input-size: var(--msv-body-font-size-l);
    --msv-promo-code-alert-bg: #{$msv-pink};
    --msv-promo-code-alert-color: var(--msv-error-color);
    --msv-promo-code-alert-border: var(--msv-error-color);
    --msv-promo-code-alert-size: var(--msv-body-font-size-m);
}

.msc-promo-code {
    &-heading {
        color: var(--msv-promo-code-heading-color);
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 5px;
        text-transform: capitalize;
        font-weight: 600;
    }

    &__discount {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        &-heading {
            @include font-content(var(--msv-font-weight-normal), var(--msv-promo-code-applied-discount-size), $msv-line-height-m);
            color: var(--msv-promo-code-applied-discount-color);
            font-size: 16px;
            padding-top: 10px;
            font-weight: 600;
            text-transform: capitalize;
            padding-bottom: 5px;
        }

        &-value {
            font-weight: var(--msv-font-weight-bold);
            .msc-price__actual {
                font-weight: 500;
            }
        }
    }

    &__line {
        &-container {
            @include font-content(var(--msv-font-weight-normal), var(--msv-promo-code-applied-discount-size), $msv-line-height-m);
            color: $msv-smalt-blue;
            font-weight: 600;
            margin-top: $msc-promo-code-discount-line-container-margin-top;
            padding-bottom: $msc-promo-code-discount-line-container-padding-bottom;
            margin-top: 4px;
            padding-bottom: 0px;
            display: flex;
            justify-content: space-between;
            &:last-child {
            }
        }

        &-value {
            display: inline-block;
            font-size: 16px;
            color: $msv-smalt-blue;
            font-weight: 600;
            .msc-price__actual {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &__btn-remove {
            @include button-link(var(--msv-promo-code-link-color));
            @include font-content(var(--msv-font-weight-normal), var(--msv-promo-code-link-size), $msv-line-height-m);
            height: auto;
            padding: $msc-promo-code-discount-line-remove-btn-padding;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            text-decoration: underline;
            padding-right: 0;
            border: 0;
            color: $msv-shiraz;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &__input-box {
        @include form-input-el(
            var(--msv-promo-code-form-input-bg),
            var(--msv-promo-code-form-input-font-color),
            var(--msv-promo-code-form-input-border)
        );
        @include vfi();
        border: 1px solid var(--msv-promo-code-form-input-border);
        font-size: var(--msv-promo-code-form-input-size);
    }
}

.msc-alert-danger {
    @include msc-alert-error();

    display: inline-block;
}

.promo-code-label {
    min-width: 115px;
    display: inline-block;
}
