@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

$msv-dialog-width: 446px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-modal-content-products-margin: 35px;
$msv-dialog-button-width: 100%;

.msc-modal {
    .msc-lines-added-to-cart-dialog {
        max-width: 510px;

        .msc-lines-added-to-cart-dialog__dialog__products {
            display: none;
        }

        &__dialog {
            &__header {
                .msc-modal__title {
                    @include font-content-l();
                    text-transform: uppercase;
                }
            }

            &__products {
                margin-left: $msv-dialog-modal-content-products-margin;
                margin-right: $msv-dialog-modal-content-products-margin;

                &__image-container {
                    @include order-template-image-container();
                }
            }

            &__description {
                text-align: center;
                padding: 4px;
            }

            &__order-template-name {
                font-size: 18px;
                padding: 4px;
                font-weight: 700;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            &__order-template-items {
                font-size: 16px;
                padding: 4px;
            }

            &__view-cart-button {
                @include primary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__continue-shopping {
                @include secondary-btn();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__footer {
                a {
                    margin: 20px 10px 20px 20px;

                    @media (max-width: $msv-breakpoint-m) {
                        margin: 0;
                    }
                }

                button {
                    margin: 20px 20px 20px 10px;

                    @media (max-width: $msv-breakpoint-m) {
                        margin: 16px 0;
                    }
                }
            }
        }
    }

    .msc-lines-added-to-cart-dialog__dialog__header {
        padding-left: 0;

        .msc-modal__title {
            &.error {
                color: $msv-shiraz;
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-xl) {
    .msc-modal .msc-modal__dialog.msc-lines-added-to-cart-dialog {
        .msc-modal__body.msc-lines-added-to-cart-dialog__dialog__body {
            max-height: 360px;
        }

        .msc-lines-added-to-cart-dialog__dialog__products {
            margin-left: unset;
            margin-right: unset;
        }
    }
}