// Import bootstrap css

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";

.row {
    margin: 0;
}

div[class^="col"] {
    padding: 0;
    margin: 0;
}

// Container width
body .container {
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (min-width: 1200px) {
        max-width: 1300px;
    }
}

// container mixin

@mixin add-container() {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    margin: 0 auto;
    @media screen and (min-width: 576px) {
        max-width: 540px;
    }
    @media screen and (min-width: $msv-breakpoint-m) {
        max-width: 720px;
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        max-width: 960px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 1180px;
    }
    @media screen and (min-width: $msv-breakpoint-xl) {
        max-width: 1320px;
    }
}
