.cookies-container {
    font-size: 16px;

    p {
        line-height: 1.5;
        margin-bottom: 16px;
    }

    .container-heading {
        margin: 0 0 32px;
        width: 100%;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 1px;
    }

    .ms-text-block {
        margin-top: 0;
        margin-bottom: 32px;
        overflow: auto;
        position: relative;

        h2 {
            margin-bottom: 16px;
            font-weight: 700;
            letter-spacing: 1px;
        }
    }

    a {
        color: $msv-smalt-blue;
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }
    }

    table {
        width: 100%;
        border: 1px solid $msv-soft-peach;
        box-shadow: 0px 1px 10px rgba($dusty-gray, 0.1);
        margin-bottom: 64px;

        th {
            padding: 16px;
            border-bottom: solid 5px $msv-supernova;
            font-size: 16px;
            line-height: 19px;
            background-color: $msv-soft-peach;
            font-weight: 700;
            word-wrap: break-word;
            padding-left: 5px;
            text-align: left;

            @media (max-width: $msv-breakpoint-l) {
                font-size: 14px;
            }

            &:first-child {
                padding-left: 14px;
            }

            @media (max-width: $msv-breakpoint-l) {
                min-width: 150px;
            }
        }

        td {
            padding: 16px 8px 16px 5px;
            font-size: 16px;
            word-wrap: break-word;
            color: $msv-black;
            text-align: left;
            border-bottom: 1px solid $msv-light-grey;

            @media (max-width: $msv-breakpoint-l) {
                font-size: 14px;
            }

            &:first-child {
                padding-left: 14px;
            }
        }
    }
}