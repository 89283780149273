.bundle-wrapper {
    margin-top: 10px;
    padding: 16px;
    border: 1px solid $alto;
    border-radius: 4px;
    box-shadow: 0px 1px 10px rgba(151, 151, 151, 0.1);

    .bundle-header {
        margin: 0 0 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $alto;
        display: flex;
        justify-content: space-between;

        .count {
            font-weight: 500;
            color: $stone;
        }

        h2 {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            color: $stone;
            margin: 0;
        }
    }

    .bundle-items {
        @media (min-width: $msv-breakpoint-l) {
            max-height: 350px;
            overflow: auto;
        }
    }
}

.bundle-container {
    display: flex;
    margin-bottom: 16px;
    border-bottom: 1px solid $alto;
    padding-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
        border: 0;
        padding-bottom: 0;
    }

    .bundle-image {
        height: 80px;
        width: 80px;

        img {
            height: auto;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .bundle-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 16px;

        .bundle-item-name {
            font-weight: 600;
            font-size: 16px;
            line-height: normal;
            text-transform: none;
            color: $stone;
            margin-bottom: 4px;
            margin-top: 0px;
        }

        .bundle-detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .bundle-price-container {
                display: flex;
                flex-direction: column;
                color: $stone;

                .bundle-item-price {
                    margin-bottom: 4px;
                }

                ._title {
                    font-weight: 600;
                    margin-right: 4px;
                }

                ._price {
                    font-weight: 500;
                }
            }
        }
    }
}

.bundle-price-label {
    color: $msv-smalt-blue;
    font-weight: 600;
    text-transform: uppercase !important;

    @media (min-width: $msv-breakpoint-l) {
        text-align: right;
    }
}