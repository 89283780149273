.support-sticker-banner {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 2px 3px 5px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.8);
    z-index: 999;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    align-items: center;
    overflow: hidden;

    .support-sticker-content {
        display: flex;
        max-width: 1050px;
        margin: auto;
        border-top: solid 5px $msv-supernova;
        align-items: center;
        background-color: $msv-soft-peach;
        max-height: 324px;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    h2 {
        font-size: 32px;
        font-weight: 700;
        color: $msv-black;
        line-height: 36px;
        text-transform: capitalize;
        margin: 0;

        @media (max-width: $msv-breakpoint-l) {
            font-size: 24px;
        }
    }

    .home_contact-h1 {
        & + .home_contact-h1 {
            margin-bottom: 24px;
        }
    }

    h3 {
        font-size: 32px;
        font-weight: 700;
        color: $msv-black;
        line-height: 36px;
        text-transform: capitalize;

        @media (max-width: $msv-breakpoint-l) {
            font-size: 24px;
        }
    }

    p {
        line-height: 21px;
        font-size: 18px;

        @media (max-width: $msv-breakpoint-l) {
            font-size: 16px;
        }
    }

    a {
        color: $msv-black;
        margin-top: 15px;
        text-transform: lowercase;
        text-decoration: none;
        font-size: 18px;

        @media (max-width: $msv-breakpoint-l) {
            margin-top: 8px;
            font-size: 16px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .banner-info {
        padding: 28px 16px;

        h3 {
            font-size: 32px;
            margin-top: 0;

            @media (max-width: $msv-breakpoint-l) {
                font-size: 24px;
            }
        }

        &.guest {
            .home_contact-h1 {
                font-size: 36px;
                margin-bottom: 10px;

                @media (max-width: $msv-breakpoint-l) {
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 0;
                }

                & + .home_contact-h1 {
                    margin-bottom: 24px;
                }
            }

            p,
            a {
                font-size: 22px;
                line-height: 32px;

                @media (max-width: $msv-breakpoint-l) {
                    font-size: 16px;
                }
            }
        }
    }

    .banner-close {
        font-size: 0;
        background: transparent;
        border: none;
        position: relative;
        left: 1015px;
        top: -143px;
        width: 0px;

        &::before {
            font-family: 'Font Awesome 5 Free';
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            text-rendering: auto;
            line-height: 1;
            content: '';
            font-size: 22px;
            top: 35%;
            position: absolute;
            right: 15%;
            color: $msv-black;
        }

        &:focus {
            outline: none;
            &::before {
                outline: 1px dashed;
            }
        }
    }

    .banner-image {
        position: relative;
        left: -12px;
        width: 50%;
        height: auto;

        img {
            height: auto;
        }

        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }
    }

    .banner-details {
        padding: 15px;
    }

    .sale-rep-info {
        font-size: 20px;

        @media (max-width: $msv-breakpoint-l) {
            font-size: 16px;
        }

        h2 {
            margin: 24px 0;
            font-size: 28px;

            @media (max-width: $msv-breakpoint-m) {
                margin: 0 0 16px;
                font-size: 20px;
            }
        }

        a {
            margin-top: 0;
        }

        .detail-line {
            margin-bottom: 20px;
            span {
                font-weight: 600;
                margin-right: 4px;
            }
        }
    }

    &.content-banner {
        position: static;
        background-color: transparent;
        padding-top: 0;
        box-shadow: none;
        margin-top: 100px;

        .support-sticker-content {
            max-width: 100%;
            max-height: 360px;

            @media (max-width: $msv-breakpoint-l) {
                max-height: 100%;
            }
        }

        .banner-image {
            width: 50%;
            left: 0;

            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .banner-info {
            padding-left: 50px;
            padding-right: 16px;

            @media (max-width: $msv-breakpoint-m) {
                padding-left: 16px;
            }
        }
    }
}

// support sticky sticker styles

.sticker {
    margin-right: -110px;
}

.sticker,
.sticker_sticky {
    bottom: 87px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-width: 165px;
    max-height: 145px;
    position: fixed;
    right: -2px;
    padding: 10px;
    background: linear-gradient(to right bottom, $msv-pale-teal, $msv-pale-teal);
    font-size: 14px;
    width: 9.9em;
    height: 10em;
    color: $msv-black;
    cursor: pointer;
    transition: all 0.3s;

    @media (max-width: $msv-breakpoint-l) {
        display: none;
    }

    .sticker-text {
        order: 1;
        p {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: $msv-black;
            line-height: 15px;
            padding-left: 14px;
            margin: 10px 0px;
        }
    }

    picture {
        order: 2;
    }

    .msc-main_image {
        max-width: 115px;
        max-height: 75px;
        margin: auto;
    }
}
