.msc-invoice-details {
    margin-top: 0px;
    max-width: 1000px;

    &__header {
        padding-bottom: 10px;
        border-bottom: 1px solid $msv-light-grey;

        &__invoice-id {
            font-size: 36px;
            margin: 10px 0px;
            font-weight: 600;
            color: $msv-supernova;
            line-height: normal;
        }

        &__invoice-info-container {
            @media only screen and (min-width: $msv-breakpoint-l) {
                display: flex;
                justify-content: space-between;
            }

            &__order-info-container {
                &__order-info {
                    margin-top: 22px;

                    &__main-info {
                        font-size: 18px;
                        line-height: 24px;
                        min-height: 32px;
                    }

                    &__details {
                        font-size: 16px;
                        line-height: 21px;
                        display: flex;

                        &__order-date {
                            padding-right: 10px;
                            border-right: solid 1px;
                        }

                        &__order-items-count {
                            padding: 0 10px;
                        }
                    }
                }
            }

            &__amount-summary {
                @media only screen and (min-width: $msv-breakpoint-l) {
                    text-align: right;
                }

                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-top: 30px;
                }

                &__amount-due-text {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    text-transform: uppercase;
                    text-align: right;
                }

                &__amount-due-amount {
                    line-height: normal;
                    text-align: right;
                    font-weight: 600;
                    font-size: 28px;
                    color: $msv-smalt-blue;
                }

                &__amount-due-date {
                    min-height: 32px;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: normal;
                    text-align: right;
                }
            }
        }

        &__actions {
            &__pay-invoice {
                @include primary-btn();
                display: none;

                @media only screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }

    &__products {
        padding-top: 10px;
        border-bottom: 1px solid $msv-light-grey;

        &__product-line {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            align-items: center;

            &__product-info-container {
                display: flex;

                &__product-details {
                    padding: 0px;

                    &__name {
                        font-weight: $msv-font-weight-bold;
                        font-size: $msv-font-size-l;
                        line-height: $msv-line-height-l;
                        display: none;
                    }

                    &__small-details {
                        font-size: $msv-font-size-m;
                        line-height: $msv-line-height-m;
                        display: flex;

                        &__price {
                            padding-right: 15px;
                        }
                    }
                }

                &__name {

                    font-size: $msv-font-size-l;
                    line-height: $msv-line-height-l;
                }
            }

            &__total-price {

                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;
            }
        }
    }

    &__payment-details {
        padding-top: 20px;

        &__line {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;


            &__label {
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;
                text-transform: uppercase;
            }

            &__value {
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;

            }

            &-total-amount {
                font-weight: 600;
                border-top: solid 2px $msv-light-grey;
                padding-top: 15px;

                .msc-invoice-details__payment-details__line__label {
                    font-size: 24px;
                }

            }

            &-total-amount &__value {
                font-size: $msv-font-size-xl;
                line-height: $msv-line-height-xl;
                font-weight: 600;

            }
        }
    }
}