@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

.msc-modal {
    .msc-order-template-name-dialog {
        &__dialog {
            &__content {
                display: flex;
                flex-wrap: wrap;
            }

            &__order-template-name-label,
            &__order-template-name {
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-transform: capitalize;
            }

            &__order-template-name {
                @include form-input-el();
                @include vfi();
                background-color: unset;
                border: 1px solid $msv-light-grey;
                margin-top: 10px;
                width: 100%;
                padding: 4px 20px;
                font-size: 16px;
            }

            &__description {
                width: 100%;
                font-size: 16px;
                line-height: 21px;
                font-weight: normal;
                margin-top: 20px;
                color: $msv-storm-dust;
            }

            &__footer {
                margin: 15px 25px 25px 25px;

                @media (max-width: $msv-breakpoint-l) {
                    margin: 0px;
                }
            }

            &__create-order-template-button {
                @include advisery-btn();
            }

            &__cancel-button {
                @include secondary-btn();
                margin: 0 20px;
            }
        }
    }
}