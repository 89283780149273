$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 150px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-grp-padding: 32px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 200px;
$cart-line-error-margin-left: 200px;
$cart-line-error-margin-top: 30px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-cartline-item-content-margin-left-auto: auto;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 50px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-lines-group-heading-padding-top: 12px;
$cart-lines-group-heading-padding-left: 76px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;
$cart-line-group-extra-action-margin-left-0: 0;

//style presets
:root {
    // heading
    --msv-cart-heading-font-size: var(--msv-heading-font-size-s);
    --msv-cart-heading-font-color: var(--msv-font-primary-color);
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout {
    @include advisery-btn();
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
    &.is-busy {
        @include add-spinner(before);
    }
}

.msc-promo-code__apply-btn {
    @include promo-btn();
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
    padding: 7px 20px;
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    margin: 0 auto 15px;
    width: $msv-checkout-button-width;
}

.msc-cart__btn-checkout {
    margin-bottom: 0;
}

.msc-cart__btn-guestcheckout {
    @include button-default();
    @include secondary-button(
        var(--msv-cart-secondary-btn-bg),
        var(--msv-cart-secondary-btn-font-color),
        var(--msv-cart-secondary-btn-border)
    );
}

.msc-cart__btn-backtoshopping {
    @include button-link(var(--msv-cart-btn-link-color));
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: left;
}

.ms-cart {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;

    @media (max-width: $msv-breakpoint-m) {
        padding-bottom: 0;
    }

    .clear-cart {
        text-align: right;
        padding-right: 20px;
        position: absolute;
        left: 355px;
        top: -25px;
        width: 500px;

        @media (max-width: 1326px) {
            left: unset;
            right: 407px;
        }

        @media screen and (max-width: 1200px) {
            margin-right: 0;
            position: static;
            width: 100%;
            padding-right: 0;
        }

        button {
            border: none;
            text-transform: uppercase;
            font-weight: 700;
            background-color: transparent;
            font-size: 16px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 20C4.47704 20 0 15.523 0 10C0 4.47704 4.47704 0 10 0C15.523 0 20 4.47704 20 10C20 15.523 15.523 20 10 20ZM11.6639 10L14.3614 7.30249C14.821 6.84293 14.821 6.09817 14.3614 5.63861C13.9018 5.17905 13.1571 5.17905 12.6975 5.63861L10 8.33612L7.30249 5.63861C6.84293 5.17905 6.09817 5.17905 5.63861 5.63861C5.17905 6.09817 5.17905 6.84293 5.63861 7.30249L8.33612 10L5.63861 12.6975C5.17905 13.1571 5.17905 13.9018 5.63861 14.3614C6.09817 14.821 6.84293 14.821 7.30249 14.3614L10 11.6639L12.6975 14.3614C13.1571 14.821 13.9018 14.821 14.3614 14.3614C14.821 13.9018 14.821 13.1571 14.3614 12.6975L11.6639 10Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            padding-left: 25px;
            background-position: 1px 2px;
            color: $msv-storm-dust;
            text-decoration: underline;
            background-size: 17px;

            @media screen and (max-width: 1200px) {
                padding-right: 0;
            }
        }
    }

    .msc-cart__heading {
        flex: 0 0 100%;
        margin-bottom: $cart-heading-bottom;
        margin-top: $cart-heading-top;
        color: $msv-supernova;
        display: none;
    }

    .msc-cart-line__content {
        align-items: baseline;
    }

    .msc-cart-line__product-quantity-label {
        padding-left: 20px;

        @media (max-width: $msv-breakpoint-l) {
            padding-left: 0;
        }
    }

    .msc-cart-line__product-savings {
        @media (max-width: $msv-breakpoint-l) {
            text-align: left;
            margin-left: 0;
        }
    }

    .msc-cart__promotion {
        width: 100%;
        background-color: $msv-white;
        padding: $cart-promotion-padding;
        margin-bottom: $cart-promotion-margin-bottom;

        &_link {
            margin-left: 8px;
            text-decoration: underline;
            cursor: pointer;
        }

        .msi-promotion-icon {
            margin-right: $msv-promotion-icon-right-margin;

            @include add-icon($msv-DollarSign, before);
            display: none;
        }

        &-popup {
            position: absolute;
            width: auto;
            height: auto;
            z-index: 2;
            background-color: $msv-white;
            left: 0;

            &_content {
                color: var(--msv-font-primary-color);
                background-clip: padding-box;
                background-color: var(--msv-bg-color);
                border: 1px solid $msv-gray-400;
                outline: 0;
                pointer-events: auto;
                position: relative;
                width: 400px;
                min-width: 335px;
                padding: 20px 10px;

                .msc-cart__promotion-popup_title {
                    @include font-content-xl();
                    margin: 0;
                    text-transform: none;
                }

                .msc-cart__promotion-popup_close-button {
                    @include add-icon($msv-x-shape);
                    @include vfi();
                    background-color: transparent;
                    border: 0;
                    color: var(--msv-font-primary-color);
                    font-size: 20px;
                    padding: $cart-promotion-popup-close-button-padding;
                    position: absolute;
                    right: 15px;
                    text-shadow: 0 1px 0 var(--msv-bg-color);
                    top: 22px;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    .msc-cart__promotion-popup_name {
                        display: block;
                    }
                }
            }
        }
    }

    .msc-cart__btn-addcarttotemplate.disabled {
        background-color: $msv-white;
        color: $msv-gray-300;
        text-decoration: underline;
    }

    .msc-cart__btn-addcarttotemplate-tooltip {
        opacity: 1;

        .msc-tooltip-inner {
            background-color: $msv-white;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
            color: $msv-black;
            font-size: 14px;
            line-height: 16px;
            padding: 12px;
            text-align: left;
        }

        .msc-arrow::before {
            border-top-color: $msv-white;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        .msc-cart__promotion {
            &-popup {
                &_content {
                    width: 335px;
                }
            }
        }
    }

    .msc-cartline-wraper {
        flex: 1;
        margin-right: 30px;
        padding-top: 0px;

        @media (min-width: $msv-breakpoint-l) {
            max-width: 66%;
        }

        @media (max-width: 1200px) {
            width: 100%;
            min-width: 100%;
            margin-right: 0;
        }

        @media (max-width: $msv-breakpoint-l) {
            margin-right: 0;
        }
    }

    .msc-order-summary-wrapper {
        width: 400px;
        position: sticky;
        top: 135px;
        padding-top: 25px;
        max-width: 100%;

        @media (max-width: 1200px) {
            margin-left: auto;
            margin-right: auto;
        }

        div.false {
            width: 100%;
        }
    }

    .msc-cart-line {
        position: relative;

        &__product-image {
            position: absolute;
            height: 150px;
            width: 150px;

            @media (max-width: $msv-breakpoint-l) {
                height: 80px;
                width: 80px;
            }

            .msc_image {
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
                pointer-events: none;
            }
        }

        &__invoice-image {
            position: absolute;

            @include invoice-image($cart-empty-image-width);
        }

        &__content,
        &__bopis-container {
            margin-left: $cart-content-margin-left;
        }

        &__extra-actions {
            margin-left: $cart-content-margin-left;
            margin-top: 20px;

            @media (max-width: $msv-breakpoint-l) {
                margin-bottom: 0 !important;
            }
        }

        &__error-message {
            display: flex;
            margin-left: $cart-line-error-margin-left;
            position: absolute;
            bottom: 42px;
            bottom: 38px;
            min-height: 26px;
            flex-direction: column;
        }

        .msc-cart-line__product-variant-size,
        .msc-cart-line__product-variant-color {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;

            .name {
                font-weight: 600;
                text-transform: uppercase;
                color: $msv-storm-dust;
            }
        }

        .voucher-dicount {
            font-weight: 400;
            white-space: nowrap;
            color: $msv-smalt-blue;

            .discount-status {
                margin-left: 5px;
            }
        }
    }

    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist ~ .msc-cart-line__remove-item {
        margin-left: auto;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-lines-item > .msc-cart-line__remove-item,
    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
        margin-left: $cart-content-margin-left;
    }

    .msc-promo-code {
        &__input-box {
            height: $cart-promo-code-input-height;
            padding-left: 16px;
        }
    }

    .msc-cart-lines-group {
        flex: 1;
        margin-right: $cart-cartline-wrapper-margin-right;
        padding-top: $cart-cartline-wrapper-grp-padding;
        min-width: 700px;

        @media (max-width: $msv-breakpoint-m) {
            padding-top: 0;
        }
    }

    .msc-cart-lines-group-wraper {
        background-color: $msv-white;
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;

        @media (max-width: $msv-breakpoint-m) {
            margin-top: 16px;
            margin-bottom: 0;
        }

        .msc-cart-line-group__extra-actions {
            display: flex;
            margin-left: $cart-line-group-extra-action-margin-left;
            margin-top: 24px;

            .msc-tooltip {
                opacity: 1;

                .msc-tooltip-inner {
                    background-color: $msv-white;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
                    color: $msv-black;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 12px;
                    text-align: left;
                }

                .msc-arrow::before {
                    border-top-color: $msv-white;
                }
            }
        }
    }

    .msc-cart__btn-addcarttotemplate {
        @include tertiary-btn();
        padding-right: 0px;
        padding-top: 30px;
        order: 3;

        @media screen and (max-width: $msv-breakpoint-l) {
            padding: 15px;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            padding: 15px;
            padding-left: 0;
        }
    }

    .msc-cart-line__remove-item {
        font-size: 0;

        position: absolute;
        bottom: 0px;
        right: 10px;
        padding: 10px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.35108 16.3125H8.41614C8.93678 16.2691 9.34881 15.8136 9.30554 15.293L8.69817 6.57288C8.65475 6.05224 8.1993 5.66182 7.67866 5.68348C7.15802 5.7269 6.74599 6.18235 6.78925 6.70299L7.39663 15.4231C7.4399 15.922 7.85208 16.3125 8.3511 16.3125H8.35108Z' fill='%23A60039'/%3E%3Cpath d='M12.1905 16.291H12.2556C12.7544 16.291 13.1666 15.9006 13.21 15.4016L13.8174 6.68151C13.8608 6.16086 13.4487 5.70541 12.928 5.66199C12.4074 5.64035 11.9519 6.03073 11.9085 6.55139L11.3011 15.2715C11.2579 15.8138 11.6482 16.2694 12.1905 16.291H12.1905Z' fill='%23A60039'/%3E%3Cpath d='M19.6527 1.84388H14.1213L13.1018 0.412183C12.9066 0.151928 12.6029 0 12.2993 0H8.30796C7.98264 0 7.67898 0.151916 7.5054 0.412183L6.48589 1.84388H0.954478C0.433836 1.84388 0 2.27768 0 2.79836C0 3.319 0.433797 3.75284 0.954478 3.75284H1.77881L3.42734 17.5271C3.6009 18.937 4.79397 20 6.22551 20H14.4033C15.835 20 17.0281 18.9371 17.2015 17.5271L18.85 3.75284H19.6744C20.195 3.75284 20.6288 3.31904 20.6288 2.79836C20.6072 2.27772 20.1734 1.84388 19.6527 1.84388V1.84388ZM15.2927 17.2884C15.2493 17.7441 14.8589 18.091 14.4033 18.091H6.22547C5.76986 18.091 5.37949 17.7439 5.33607 17.2884L3.70918 3.75276H16.9195L15.2927 17.2884Z' fill='%23A60039'/%3E%3C/svg%3E%0A");

        &:before {
            font-size: 18px;
            color: $msv-shiraz;
            content: '';
        }
    }

    .cart-line-item-product-discount-price {
        .msc-price__actual {
            padding-top: 0;
        }
    }

    .msc-order-summary__items {
        margin-bottom: 12px;

        .msc-alert-danger {
            margin-top: 10px;
            background-position-y: 0px;
        }
    }

    .cartline-strike-through-price {
        @media (max-width: $msv-breakpoint-l) {
            display: none;
        }
    }

    .msc-cart-lines-item {
        min-height: 246px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 0px;

        .badge {
            margin-left: -20px;
            margin-bottom: 12px;
            max-width: 200px;

            @media (max-width: $msv-breakpoint-l) {
                max-width: 115px;
            }
        }
    }

    .order-summary-custom-div {
        margin-top: 6px;
    }

    .bundle-wrapper {
        margin-top: 20px;

        & + .msc-cart-line__extra-actions {
            margin-left: 4px;
            margin-top: 8px;
        }
    }

    .bundle-container {
        &:first-child {
            border-bottom: 1px solid $alto;
        }
    }

    .msc-order-summary__line-shipping {
        padding-bottom: 0;
    }
}

@media screen and (max-width: $msv-breakpoint-l) {
    .ms-cart {
        display: block;

        .msc-cartline-wraper .msc-cart-lines-group-wraper {
            margin-right: 0;
            width: 100%;
        }

        .msc-cart-lines-group {
            margin-right: 0;
            min-width: unset;
        }

        .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
        .msc-cart-lines-item > .msc-cart-line__remove-item {
            margin-left: $cart-cartline-item-content-margin-left-m;
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                &__product-image {
                    @include image($cart-empty-image-width-m);

                    .msc_image {
                        @media (max-width: $msv-breakpoint-l) {
                            height: unset;
                            width: unset;
                        }
                    }
                }

                &__invoice-image {
                    @include invoice-image($cart-empty-image-width-m);
                }

                &__content {
                    display: flex;
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__bopis-container,
                &__error-message,
                &__extra-actions {
                    margin-left: 100px;
                    position: static;
                    margin-bottom: 10px;
                }

                &__product {
                    padding-right: 0;

                    .msc-cart-line-item-product-discount {
                        position: static;
                    }

                    &-quantity {
                        padding-bottom: $cart-cartline-line-product-padding-bottom;
                        margin: 0;
                        width: 100%;
                        min-width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    &-savings {
                        margin-left: 0;
                        text-align: left;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        flex-wrap: wrap;
                        order: 1;
                        margin-bottom: 10px;

                        .cart-item-price {
                            width: 100%;
                            text-align: left;
                            padding-top: 0;
                            justify-content: flex-start;
                        }
                    }

                    &-price {
                        margin-left: $cart-cartline-line-product-price-margin-left;
                        padding-top: $cart-cartline-line-product-price-padding-top;
                        text-align: left;
                    }
                }

                &-group {
                    &__extra-actions {
                        margin-left: $cart-cartline-item-content-margin-left-auto;
                        margin-top: $cart-line-group-extra-action-margin-top;
                    }
                }
            }
        }

        .msc-promo-code {
            &__input-box {
                width: $cart-promo-code-input-width-m;
            }

            &__apply-btn {
                min-width: auto;
            }
        }

        .msc-order-summary-wrapper {
            padding: $cart-order-summary-padding-m;
            width: 100%;
            padding: 20px 0;
        }

        .msc-order-summary__items {
            @include font-content-s();
        }
    }
}

.cart-page1 {
    h1 {
        color: $msv-black;
        font-weight: 700;
        font-size: 28px;
        align-items: center;
        text-transform: uppercase;
        margin: 0 0 30px 0;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 28px;
        }
    }

    & + .default-container__0 {
        .row {
            .col-12 {
                &:first-child {
                    min-height: 38px;
                }
            }
        }
    }
}

.ctx-disabled {
    .msc-cart__btn-checkout {
        pointer-events: none !important;
        opacity: 0.6 !important;
    }
}

.cart-Item-id {
    margin-left: 200px;
    min-height: 19px;
    margin-top: 5px;
    &.error {
        color: $msv-shiraz;
        font-size: 12px;
        min-height: unset;
        margin: 0;
    }

    .item-number {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: $msv-storm-dust;
    }

    @media (max-width: $msv-breakpoint-m) {
        padding-left: 100px;
    }

    @media (max-width: $msv-breakpoint-l) {
        margin-left: 100px;
        margin-bottom: 10px;
        padding-left: 0;
    }
}

.ot-btn-wrapper {
    min-height: 60px;
}

.item-number-title {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}

.ctx-disabled {
    width: 100%;
    pointer-events: none;
    opacity: 0.6;
}

.product-add-to-cart .msc-alert-danger {
    display: none !important;
}

.msc-order-summary-wrapper .msc-cart__btn-backtoshopping {
    text-align: center !important;
}

.requested-delivery-date label {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    .form-field {
        max-width: 100%;
        flex: auto;
        padding-bottom: 10px;
    }
}

.cart-custom-fields-container {
    border: solid 1px $msv-light-grey;
    order: 1;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    label {
        @media (min-width: $msv-breakpoint-l) {
            margin-bottom: 0;
        }
    }

    .form-field {
        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            display: flex;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.btn_cart-bottom {
    display: flex;
    width: 100%;
    margin-top: 25px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: $msv-breakpoint-m) {
        flex-wrap: wrap;
    }

    a {
        order: 2;
    }

    .msc-cart__btn-backtoshopping {
        display: none;
    }

    > a {
        width: 220px;
        margin: 0;
    }
}

.msc-cart-lines-item {
    .discount-label-custom {
        color: $msv-storm-dust;
        margin-left: 200px;
        font-weight: 500;
    }
}

.ship-date {
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 200px;
    margin-top: 5px;
    min-height: 19px;

    @media (max-width: $msv-breakpoint-l) {
        margin-left: 100px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    label {
        margin-bottom: 0px;
    }
}

.ship-date :nth-child(2) {
    color: $msv-storm-dust;
    padding-left: 5px;
}

.order-shipdate {
    width: 50%;
    text-transform: uppercase;
    color: $msv-black;
    font-weight: 500;

    :nth-child(2) {
        font-weight: normal;
        color: $msv-storm-dust;
        padding-left: 5px;

        @media (max-width: $msv-breakpoint-l) {
            padding-left: 0;
        }
    }
}

.request-date {
    display: flex;
    align-items: center;

    label {
        width: 50%;
        margin-bottom: 0px;
        white-space: nowrap;
        margin-right: 8px;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            margin-bottom: 8px;
        }
    }

    .react-datepicker__view-calendar-icon {
        input {
            margin-left: 3px;
            width: 100%;
            padding: 0 40px 0 16px;
            border: 0;
            background: transparent;

            &::placeholder {
                font-size: 14px;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .react-datepicker-wrapper {
        border: 1px solid $msv-light-grey;
        position: relative;
        @include add-svg-icon($icon-calender, 24px, 24px);

        &::before {
            position: absolute;
            z-index: 0;
            right: 4px;
            top: 6px;
        }

        .react-datepicker__calendar-icon {
            display: none;
        }
    }
}

.requested-delivery-date {
    width: 50%;

    @media (max-width: $msv-breakpoint-m) {
        width: 100%;
    }
}

.order-summary-custom-div {
    border: solid 1px $msv-soft-peach;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 40px;
    padding: 30px 30px 30px 30px;
    width: 400px;

    .msc-alert-danger {
        margin-top: 0px;
        background-position-y: 15px;
    }

    .msc-alert__line {
        padding-left: 0px;
    }

    border: solid 1px $msv-soft-peach;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 40px;

    padding: 30px 30px 30px 30px;
    width: 400px;

    @media (max-width: $msv-breakpoint-m) {
        max-width: 100%;
    }

    .msc-order-summary__heading {
        margin-top: 0px;
    }
}

.cartline-items-custom-div {
    border-top: none;
}

.empty-btn {
    @include primary-btn();
}

.msc-add-to-order-template-dialog__existing {
    .msc-add-to-order-template-dialog__existing__dialog__order-templates-line__products {
        display: none;
    }
}

.cart-unit-price {
    display: flex;

    .no-price-wrapper,
    .krannich-price {
        margin-left: 5px;
    }
    .unit-price-title {
        text-transform: uppercase;
        font-weight: 600;
    }

    .unit-price-amount {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: $msv-storm-dust;
    }

    margin-left: 200px;

    @media (max-width: $msv-breakpoint-l) {
        margin-left: 100px;
    }
}

.sticky-button {
    @media (max-width: $msv-breakpoint-m) {
        padding: 16px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $msv-white;
        z-index: 1000;
        margin: 0 !important;
        height: fit-content;
        min-height: fit-content;
        min-width: 80px;
        height: 79px;
        display: flex;
        align-items: center;
        border-top: 1px solid $msv-light-grey;
    }

    &.request-price {
        @media (max-width: $msv-breakpoint-m) {
            padding: 0;
        }
    }

    .Request-Price-btn {
        @media (max-width: $msv-breakpoint-m) {
            width: calc(100% - 32px);
            margin: 0 auto;
        }
    }
}

div {
    &[data-exp-event-id='CartPage.PageView'],
    &[data-exp-event-id='ProductDetailPage.PageView'] {
        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: 78px;
        }
    }
}
