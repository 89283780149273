$msv-primary-font-family: 'ExoSoft', sans-serif;
$msv-btn-icon-to-text-gap: 4px;
$msv-btn-border-radius: 2px;

.msc-btn {
    @include button-default();
    display: inline-block;
    border: 1px solid transparent;
    text-decoration: none;
    text-align: center;
    min-width: auto;

    &:not(:disabled) {
        cursor: pointer;
    }
}

.ms-product-comparison-button__button {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
}
