.atp-pdp-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 0;

    .lights-section {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        ._message-container {
            display: flex;
            margin-left: 12px;

            ._traffic-light-quantity {
                margin-right: 5px;
                font-size: 16px;
                font-weight: 500;
                color: $msv-storm-dust;

                .ms-buybox__minmax_label_text {
                    margin-top: 0;
                }
            }
        }
    }

    .pdp-calculate-ship-container {
        display: flex;
        padding: 12px 0px 0;

        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
        }

        .calaculate-ship-input {
            flex: 1;
            padding: 0px 16px;
            font-size: 14px;
            border: solid 1px $msv-light-grey;
            border-right: 0px;
            max-width: 350px;
            height: 40px;

            @media (max-width: $msv-breakpoint-m) {
                max-width: 100%;
                min-height: 40px;
                margin-bottom: 16px;
                border-right: solid 1px $msv-light-grey;
            }
        }

        .calculate-ship-btn {
            @include primary-btn();

            &:focus {
                outline: 1px dashed;
                box-shadow: none;
            }

            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }
    }

    .atp-response,
    .atp-response-red {
        color: $msv-storm-dust;
        font-weight: 500;
        margin-top: 12px;
        font-size: 16px;
    }

    .atp-response-red {
        color: $msv-shiraz;
    }

    .msc-waiting-circular {
        height: 24px;
        width: 24px;
        border-top-color: $msv-supernova;
        margin-top: 6px;
    }
}

.traffic-light-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;

    ._red-light {
        width: 14px;
        height: 14px;
        background: $msv-red;
        background-size: 3px 3px;
        border-radius: 50%;
    }

    ._green-light {
        width: 14px;
        height: 14px;
        background: $msv-green;
        background-size: 3px 3px;
        border-radius: 50%;
    }

    ._yellow-light {
        width: 14px;
        height: 14px;
        background: $msv-yellow;
        background-size: 3px 3px;
        border-radius: 50%;
    }
}
