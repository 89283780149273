$msv-sign-in-social-accounts-border: 1px dashed $msv-gray-300;
$msv-sign-up-border: 1px solid $msv-gray-300;
$msv-sign-in-font-size: 14px;
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: 12px;
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-heading-font-size: 24px;
$msv-sign-in-heading-font-weight: normal;
$msv-sign-in-heading-line-height: 32px;
$msv-sign-in-button-margin-bottom: 60px;
$msv-sign-in-button-margin-top: 20px;
$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 20px;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;
$msv-sign-in-section-spacing: 90px;
$msv-sign-in-section-spacing-m: 40px;
$msv-sign-in-social-accounts-padding: 40px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;
$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 32px;
$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-container-margin: 50px 100px;
$msv-sign-in-container-margin-m: 20px;

//style presets
:root {
    --msv-sign-in-font-size: var(--msv-body-font-size-m);
    --msv-sign-in-font-color: var(--msv-font-primary-color);
    --msv-sign-in-link-size: var(--msv-body-font-size-s);
    --msv-sign-in-link-color: #2e48ce;

    // heading
    --msv-sign-in-heading-font-size: var(--msv-body-font-size-xl);
    --msv-sign-in-heading-font-color: var(--msv-font-primary-color);

    //error
    --msv-sign-in-error-alert-bg: var(--msv-error-color);
    --msv-sign-in-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-sign-in-error-alert-border: var(--msv-error-color);
    --msv-sign-in-error-color: var(--msv-error-color);

    // primary button
    --msv-sign-in-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-sign-in-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-sign-in-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-sign-in {
    @include font-content(var(--msv-font-weight-normal), var(--msv-sign-in-font-size), $msv-line-height-m);
    color: var(--msv-sign-in-font-color);

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__social-account-picture {
        @include image(32px);

        display: inline-block;
        vertical-align: middle;
    }

    &__sign-up-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        margin: 0;
        font-weight: 500;
        text-align: center;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 14px;
        }
    }

    &__sign-in-heading {
        display: none;
    }

    &__sign-in-section {
        flex: 1;
    }

    &__sign-up-section {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        text-transform: uppercase;

        @media (max-width: 1360px) {
            margin-bottom: 24px;
            flex-direction: column;
        }
    }

    &__account-item {
        margin-bottom: 32px;
        position: relative;

        label {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: $stone;
            margin-bottom: 3px;
        }

        .ms-sign-in__item-error-text {
            margin: 0;
        }

        .ms-sign-in__item-error {
            position: absolute;
            bottom: -24px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: $msv-shiraz;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            padding-left: 20px;
            background-position: 0 2px;
        }

        &-input {
            @include form-input-el();
            font-size: 16px;
            margin-top: $msv-sign-in-input-margin-top;
            width: 100%;
            border: 1px solid $msv-light-grey;
            height: 43px;
            background: transparent;
            margin: 0;
        }
    }

    &__forget-password {
        display: inline-block;
        color: $msv-smalt-blue;
        @include font-content(var(--msv-font-weight-normal), var(--msv-sign-in-link-size), $msv-line-height-s);
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.2px;
        float: right;
        text-transform: uppercase;
    }

    &__sign-in-button {
        @include advisery-btn();
        margin-bottom: 32px;
        margin-top: $msv-sign-in-button-margin-top;
        width: 100%;
    }

    &__sign-up-link {
        width: 100%;
        color: $msv-smalt-blue;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        width: auto;
        text-decoration: underline;
        margin-left: 5px;
        padding: 0;
        height: auto;
        text-transform: uppercase;
        border: 0;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 14px;
        }
    }

    &__social-accounts {
        border-top: $msv-sign-in-social-accounts-border;
        padding: $msv-sign-in-social-accounts-padding;
        display: none;

        button:last-child {
            margin-bottom: 0;
        }
    }

    &__social-account {
        margin-bottom: $msv-sign-in-social-accounts-button-margin-bottom;
        width: 100%;

        &-FacebookExchange {
            background-color: $facebook-bg-color;
            color: var(--msv-font-secondary-color);
            padding: $msv-sign-in-social-button-padding;

            &:hover,
            &:focus {
                background-color: $facebook-hover-color;
            }
        }

        &-MicrosoftAccountExchange {
            background-color: $microsoft-bg-color;
            border: 1px solid $microsoft-bg-color;
            color: var(--msv-font-secondary-color);
            padding: $msv-sign-in-social-button-padding;

            &:hover,
            &:focus {
                background-color: $microsoft-hover-color;
                border: 1px solid $microsoft-hover-color;
            }
        }

        &-text {
            margin-left: $msv-sign-in-social-accounts-text-margin-left;
        }
    }

    &__sign-up-description {
        margin-top: $msv-sign-in-sign-up-description-margin-top;
        margin-bottom: $msv-sign-in-sign-up-description-margin-bottom;
        display: none;
    }

    &__page-error {
        clear: both;
        margin-top: 8px;

        margin-top: $msv-sign-in-message-margin-top;
        padding: $msv-sign-in-message-padding;

        @include msc-alert-error();
        display: none;
        padding: 0px;
        background-position: 0px 1px;
        padding-left: 14px;
        background-size: 18px;

        p {
            padding-left: 15px;
        }
    }

    &__item-error {
        color: var(--msv-sign-in-error-color);
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            display: block;
        }

        &__sign-in-section {
            margin-right: 0;
        }

        &__sign-up-section {
            border-left: none;
            border-top: $msv-sign-up-border;
            padding-left: 0;
            padding-top: 24px;
            flex-direction: column;
        }
    }
}

.signin-wrapper {
    .row {
        .col-12 {
            &:first-child {
                @media (min-width: 768.5px) {
                    width: 40%;
                    max-width: 40%;
                }
            }

            &:nth-child(2) {
                @media (min-width: 768.5px) {
                    width: 60%;
                    max-width: 60%;
                }
            }
        }
    }

    .signin-content {
        padding: 16px;

        @media (min-width: $msv-breakpoint-l) {
            padding: 10px 50px;
            height: 100vh;
            overflow: auto;
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 100%;
            }
        }

        .buisness-partner {
            min-height: auto;

            .ms-content-block__details {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                text-transform: uppercase;

                @media (max-width: 1360px) {
                    font-size: 14px;
                    flex-direction: column;
                }

                a {
                    font-size: 16px;
                    line-height: 21px;
                    text-transform: uppercase;
                    text-decoration: underline;
                    color: $msv-smalt-blue;
                    margin-left: 5px;

                    @media (max-width: $msv-breakpoint-m) {
                        font-size: 14px;
                    }

                    &:hover {
                        color: $msv-black;
                    }
                }
            }
        }

        .row {
            .col-12 {
                &:first-child {
                    width: 100%;
                    max-width: 100%;
                }

                &:nth-child(2) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .singin-logo {
            min-height: unset;
            padding-top: 32px;
            margin-bottom: 12px;

            @media (max-width: $msv-breakpoint-m) {
                padding-top: 0;
            }

            img {
                max-width: 250px;
                height: auto;
                margin-bottom: 22px;
                margin-bottom: 24px;

                @media (max-width: $msv-breakpoint-l) {
                    max-width: 150px;
                }
            }

            h2 {
                font-weight: 700;
                font-size: 28px;
                line-height: 37px;
                color: $msv-black;
                margin: 0 0 16px;
                text-transform: none;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 20px;
                }
            }
        }
    }

    .signin-image-container {
        max-height: 100vh;

        @media (max-width: $msv-breakpoint-l) {
            max-height: unset;
        }

        overflow: hidden;
        min-height: 100vh;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }

        .ms-container-background-image {
            background-size: cover;
            min-height: 100vh !important;
            background-position: bottom !important;
        }

         & > .row > .col-12 {
            width: 100%;
            max-width: 100%;
        }

        .ms-content-block__image {
            height: 100vh;
            
            img {
                width: auto;
                height: 100%;
                margin-left: auto;
            }
        }
    }

    .sign-in-heading {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: $msv-black;
        margin: 0 0 32px;
        text-transform: none;
        @media (max-width: $msv-breakpoint-m) {
            font-size: 20px;
        }
    }
}

.no-footer {
    footer {
        display: none;
    }
}