.static-main-banner {
    img {
        width: 100%;
        height: auto;
    }

    .ms-content-block {
        min-height: auto;
    }
}

.service-info {
    margin-top: 40px;
    margin-bottom: 50px;

    @media (max-width: $msv-breakpoint-m) {
        margin-top: 16px;
    }

    .ms-text-block {
        max-width: 1078px;
    }

    h2,
    h1 {
        margin: 0 0 20px;
        font-weight: 700;
        font-size: 60px;
        line-height: 80px;
        color: $msv-smalt-blue;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 32px;
            line-height: 48px;
        }
    }

    h3,
    h4 {
        font-size: 24px;
        font-weight: 700;
        @media (max-width: $msv-breakpoint-m) {
            font-size: 20px;
        }
    }

    p {
        font-weight: 500;
        font-size: 22px;
        line-height: 34px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.services-content {
    background: $alabaster-2;
    padding: 50px 0;

    .ms-content-block {
        display: flex;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 15px;

        @media (max-width: $msv-breakpoint-l) {
            flex-direction: column;
        }

        img {
            height: auto;
        }

        .ms-content-block__title {
            font-weight: 600;
            line-height: 24px;
            color: $msv-storm-dust;

            @media (max-width: $msv-breakpoint-m) {
                margin-top: 8px;
            }
        }

        h2 {
            margin: 9px 0 24px;
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        p {
            color: $msv-storm-dust;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .ms-content-block__image,
    .ms-content-block__details {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }
    }

    .ms-content-block__image {
        padding-right: 20px;

        @media (max-width: $msv-breakpoint-l) {
            padding-right: 0;
        }
    }

    .ms-content-block__details {
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: $msv-breakpoint-l) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .msc-cta__primary {
        @include primary-btn();

        @media (max-width: $msv-breakpoint-m) {
            font-size: 12px;
            margin-bottom: 16px;
        }
    }

    &.after-sales-services {
        .ms-content-block__details {
            order: -1;
        }

        .ms-content-block__text {
            h2 {
                @media (max-width: $msv-breakpoint-m) {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            p {
                @media (max-width: $msv-breakpoint-m) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .ms-content-block__image {
            img {
                margin-left: auto;
            }
        }
    }

    &.knowledge-forum {
        .ms-content-block {
            .ms-content-block__title {
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                color: $msv-black;
                position: relative;
                @include add-svg-icon($icon-bulb, 103px, 102px);

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 24px;
                    line-height: 32px;
                }

                &:before {
                    position: absolute;
                    left: -115px;
                    top: -7px;
                }
            }
        }

        .ms-content-block__details {
            padding-left: 150px;

            @media (max-width: $msv-breakpoint-m) {
                padding-left: 132px;
            }
        }

        h3 {
            margin: 0 0 36px;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            text-transform: uppercase;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &.odd {
        margin-bottom: 50px;
        .ms-content-block {
            flex-direction: row-reverse;
            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }
        }

        .ms-content-block__image {
            padding-right: 0;
        }

        .ms-content-block__details {
            padding-left: 0;
        }
    }

    &.even {
        background: $msv-white;
        margin-bottom: 50px;
    }
}

.support-section {
    margin-top: 32px;
    margin-bottom: 96px;

    @media (max-width: $msv-breakpoint-m) {
        margin-top: 32px;
        margin-bottom: 50px;
    }

    .col-12 {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;

        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        &:first-child {
            padding-right: 16px;

            @media (max-width: $msv-breakpoint-l) {
                padding-right: 0;
            }
        }

        &:last-child {
            padding-left: 16px;

            @media (max-width: $msv-breakpoint-l) {
                padding-left: 0;
            }
        }
    }

    .ms-content-block {
        background: $alabaster-2;
        padding: 42px 42px 50px;
        position: relative;
        height: 100%;

        @media (max-width: $msv-breakpoint-m) {
            padding: 24px;
        }

        h2 {
            margin: 0 0 12px;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            position: relative;
            padding-top: 120px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 24px;
                line-height: 32px;
            }

            &::before {
                position: absolute;
                top: 0;
            }
        }

        p {
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            color: $msv-storm-dust;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .ms-content-block__cta {
            a {
                @include msv-icon();
                padding: 9px;
                display: block;
                position: absolute;
                right: 0px;
                background: $msv-black;
                width: 32px;
                height: 32px;
                bottom: 0px;
                font-size: 14px;
                color: $msv-white;
                background-image: url($icon-arrow);
                background-repeat: no-repeat;
                background-position: 7px 8px;
                background-size: 16px;

                &:hover,
                &:focus {
                    background-color: $msv-supernova;
                    color: $msv-black;
                }
            }
        }
    }

    .marketing-support {
        @media (max-width: $msv-breakpoint-m) {
            margin-bottom: 24px;
            border-bottom: 50px solid $msv-white;
        }

        h2 {
            @include add-svg-icon($support-avatar, 98px, 80px);
        }
    }

    .tech-support {
        h2 {
            @include add-svg-icon($support-tech, 98px, 98px);
        }
    }
}

.service-promo {
    margin: 0;
}

.service-fragment {
    margin-bottom: 25px;

    .main-icons {
        padding: 0 15px !important;

        .row {
            @media (max-width: $msv-breakpoint-m) {
                flex-wrap: nowrap;
                overflow: auto;
            }

            .col-12 {
                margin: 16px;

                @media (max-width: $msv-breakpoint-m) {
                    margin: 0;
                    max-width: 220px;
                }
            }
        }
    }

    .ms-content-block__details {
        text-align: center;
    }
}
