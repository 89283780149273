$msv-flyout-msc-cart-line-product-image-size: 120px;
$msv-flyout-msc-cart-line-product-empty-image-size: 80px;
$msv-flyout-padding: 20px;
$msv-flyout-cart-icon-subtotal-padding: 20px;
$msv-flyout-cart-icon-subtotal-margin: 0 -20px;
$msv-flyout-cart-icon-btn-checkout-margin-right: 10px;
$mini-cart-width: 410px;
$subtotal-border: 1px solid $msv-gray-300;
$msv-flyout-empty-img-border: 1px solid $msv-flyout-empty-img-border;

//style presets
:root {
    --msv-flyout-heading-font-color: var(--msv-font-primary-color);
    --msv-flyout-heading-font-size: var(--msv-body-font-size-l);
    --msv-flyout-text-font-color: var(--msv-font-primary-color);
    --msv-flyout-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-flyout-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-flyout-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-flyout-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-flyout-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-flyout-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-flyout-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-cart-icon__flyout-container {
    background: var(--msv-flyout-bg-color);
    border: solid 1px $msv-soft-peach;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: $mini-cart-width;
    z-index: 1060;

    &.show {
        opacity: 1;
        transform: none !important;
        left: unset !important;
        right: 0;
        top: 60px !important;
    }

    .msc_image {
        margin: auto;
    }

    .ms-cart-icon__btn-checkout,
    .ms-cart-icon__btn-gotocart,
    .ms-cart-icon__btn-guestcheckout {
        width: 100%;
    }

    .msc-flyout__close-button {
        @include add-icon($msv-x-shape);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: var(--msv-font-primary-color);
        font-size: 20px;
        position: absolute;
        right: 20px;
        cursor: pointer;
        display: none;
    }

    .msc-flyout-inner {
        .ms-cart-icon__subtotal {
            @include font-content(var(-msv-font-weight-bold), var(--msv-body-font-size-l), $msv-line-height-l);
            color: var(--msv-flyout-text-font-color);
            border-top: solid 1px #ededea;
            padding: 15px 25px;
            text-align: left;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 16px;
            color: $msv-black;
            word-spacing: 18px;
        }

        .ms-cart-icon__btn-gotocart {
            @include primary-btn();
            margin: 0px 20px;
            width: calc(100% - 40px);
            margin-bottom: 20px;
        }

        .ms-cart-icon__btn-guestcheckout {
            @include secondary-btn();
            margin-bottom: 20px;
        }

        .ms-cart-icon__btn-checkout {
            @include primary-btn();
            margin: 0px 20px;
            width: 90%;
        }
    }

    .msc-cart-line__product-image {
        width: 100px;
        height: 100px;
        overflow: hidden
    }

    @include image($msv-flyout-msc-cart-line-product-empty-image-size);

    .msc_image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        pointer-events: none;
    }

    .msc-cart-line__product-savings-label,
    .msc-cart-line__product-savings-text,
    .msc-cart-line-item-product-discount {
        display: none;
    }

    .msc-cart-line__remove-item {
        @include tertiary-btn();
        font-size: 0px;
        margin: 0px;

        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.35108 16.3125H8.41614C8.93678 16.2691 9.34881 15.8136 9.30554 15.293L8.69817 6.57288C8.65475 6.05224 8.1993 5.66182 7.67866 5.68348C7.15802 5.7269 6.74599 6.18235 6.78925 6.70299L7.39663 15.4231C7.4399 15.922 7.85208 16.3125 8.3511 16.3125H8.35108Z' fill='%23A60039'/%3E%3Cpath d='M12.1905 16.291H12.2556C12.7544 16.291 13.1666 15.9006 13.21 15.4016L13.8174 6.68151C13.8608 6.16086 13.4487 5.70541 12.928 5.66199C12.4074 5.64035 11.9519 6.03073 11.9085 6.55139L11.3011 15.2715C11.2579 15.8138 11.6482 16.2694 12.1905 16.291H12.1905Z' fill='%23A60039'/%3E%3Cpath d='M19.6527 1.84388H14.1213L13.1018 0.412183C12.9066 0.151928 12.6029 0 12.2993 0H8.30796C7.98264 0 7.67898 0.151916 7.5054 0.412183L6.48589 1.84388H0.954478C0.433836 1.84388 0 2.27768 0 2.79836C0 3.319 0.433797 3.75284 0.954478 3.75284H1.77881L3.42734 17.5271C3.6009 18.937 4.79397 20 6.22551 20H14.4033C15.835 20 17.0281 18.9371 17.2015 17.5271L18.85 3.75284H19.6744C20.195 3.75284 20.6288 3.31904 20.6288 2.79836C20.6072 2.27772 20.1734 1.84388 19.6527 1.84388V1.84388ZM15.2927 17.2884C15.2493 17.7441 14.8589 18.091 14.4033 18.091H6.22547C5.76986 18.091 5.37949 17.7439 5.33607 17.2884L3.70918 3.75276H16.9195L15.2927 17.2884Z' fill='%23A60039'/%3E%3C/svg%3E%0A");

        &:before {
            font-size: 18px;
            color: $msv-shiraz;
            content: '';
        }

        &:focus {
            font-size: 0px;
        }
    }

    .msc-cart-line__bopis-method {
        margin-left: 108px;
    }

    .msc-cart-line__remove-item,
    .msc-cart-line__bopis-method {
        height: auto;
        padding-top: 6px;
        border: 0;
    }

    .msc-cart-line__content {
        width: calc(100% - 130px);
    }

    .msc-cart-line__product-savings {
        text-align: left;
        margin: 0;
    }

    .msc-cart-line__quantity .quantity-label label {
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
    }

    .msc-cart-line__product-savings,
    .msc-cart-line__quantity,
    .msc-cart-line__product-variants {
        margin-top: 0px;
    }

    .msc-cart-line__product-variants {
        &>.msc-cart-line__product-variant-item {
            display: inline;

            &::after {
                content: ',';
            }

            &:last-child::after {
                content: '';
            }
        }
    }

    .msc-cart-line__product-variant-size,
    .msc-cart-line__product-variant-style,
    .msc-cart-line__product-configuration,
    .msc-cart-line__product-variant-color {
        font-size: 0;

        .name {
            @include font-content(500, var(--msv-flyout-text-font-size), $msv-line-height-m);
            color: var(--msv-flyout-text-font-color);
        }
    }

    .msc-cart-line__product-title {
        @include font-content(var(--msv-font-weight-bold), var(--msv-flyout-heading-font-size), $msv-line-height-l);
        color: var(--msv-flyout-heading-font-color);
        width: 100%;
    }

    .ms-cart-icon__flyout-title {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        padding: 25px 20px 20px 20px;
        border-bottom: solid 1px $msv-soft-peach;
    }

    .msc-cart-line__product-title {
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 5px;
        text-transform: uppercase;
    }

    .voucher-dicount {
        text-transform: uppercase;
        font-weight: 400;
        white-space: nowrap;
        color: $msv-smalt-blue;

        .discount-status {
            margin-left: 5px;
        }
    }

    .ms-cart-icon__cart-lines {
        max-height: 330px;
        overflow: auto;
        padding-right: 10px;

        .msc-cart-lines-item {
            display: flex;
            min-height: 140px;
            margin-top: 0px;
            border: none;
            border-bottom: solid 1px $gallery;
            padding: 20px 5px 10px 20px;

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button {
            width: 8px;
        }

        &::-webkit-scrollbar-button {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #eeeeee;
            border: thin solid #dfdfdf;
            box-shadow: 0 0 3px #dfdfdf inset;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #999999;
            border: thin solid #323130;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #7d7d7d;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-line__product-variant-item,
    .msc-cart-line__quantity,
    .msc-cart-line__bopis-method {
        @include font-content(400, 14px, $msv-line-height-m);
        color: var(--msv-flyout-text-font-color);
        text-transform: uppercase;
    }

    .msc-cart-line__quantity {
        display: inline-block;
        width: 50%;

        .quantity-label {
            margin-bottom: 0;
            margin-right: 5px;
            display: block;
        }
    }

    .msc-price__actual {
        font-weight: 400;
        font-size: 14px;
        text-align: left;

        &::before {
            content: 'SumTotal';
            display: block;
            line-height: 20px;
        }
    }

    .msc-price__strikethrough {
        @media (max-width: $msv-breakpoint-l) {
            display: none;
        }
    }

    .msc-cart-line__product-variants {
        width: 100%;
        font-size: 14px;
    }

    .msc-cart-line__product-savings {
        display: inline-block;
        width: 50%;
        text-align: right;
    }

    .msc-cart-line__content .msc-cart-line__product-savings .msc-cart-line__product-savings-label {
        display: none;
    }

    .msc-cart-line {
        width: 100%;
    }
}

.ms-header .ms-cart-icon {
    position: relative;
}