$buybox-margin-top: 36px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 20px;
$buybox-box-bottom-margin: 20px;
$buybox-store-description-margin-bottom: 8px;
$buybox-add-to-cart-extra-actions-margin: 8px;
$buybox-add-to-cart-extra-actions-width: 48px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width: 66%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$buybox-custom-amount-input-width: 80px;
$buybox-custom-amount-input-padding: 5px;
$buybox-bulk-purchase-anchor-hover-color: $msv-gray-200;

//style presets
:root {
    --msv-buybox-btn-bg: #{$msv-white};
    --msv-buybox-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-btn-border-color: var(--msv-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-buybox-rating-star: var(--msv-font-primary-color);
    --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);

    // Flippers
    --msv-buybox-carousel-flipper-color: #{$msv-gray-900};
    --msv-buybox-carousel-flipper-hover-color: #{$msv-gray-800};
}

.ms-buybox {
    display: flex;
    flex-flow: row;
    justify-content: center;

    @media (max-width: $msv-breakpoint-l) {
        flex-flow: column;
    }

    .badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px;
        font-size: 16px;
        max-width: 350px;

        @media (max-width: $msv-breakpoint-l) {
            max-width: 320px;
        }

        & + .ms-buybox__media-gallery {
            padding-top: 60px;
        }
    }

    .ms-media-gallery {
        &.vertical {
            .ms-media-gallery__carousel {
                @media (min-width: $msv-breakpoint-l) {
                    margin-left: 0;
                    margin-right: 50px;
                }
            }
        }

        // hiding carousel thumbnails
        .ms-media-gallery__thumbnails-container {
            display: none;

            @media (min-width: $msv-breakpoint-l) {
                display: none;
            }
        }
    }

    &__product-quantity-label-heading {
        font-size: 14px;
        color: $msv-black;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__bulk-purchase-anchor {
        background-color: unset;
        display: block;
        margin: 10px 0;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;

        @include add-icon($msv-TH, before);

        &::before {
            color: var(--msv-accent-brand-color);
            margin-right: 8px;
        }

        &:hover {
            background-color: $buybox-bulk-purchase-anchor-hover-color;
        }
    }

    &__inventory-info {
        margin-top: $buybox-box-top-margin;
    }

    &__product-title {
        @include font-content(700, 32px, 43px);
        color: rgba($black, 0.7);
        margin-bottom: 9px;
        margin-top: 0px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(400, 14px, 26px);
        color: var(--msv-buybox-text-font-color);
        margin-bottom: 0;
        max-height: 200px;
        overflow: scroll;
        text-transform: capitalize;
        height: auto;
    }

    .msc-rating {
        &__count,
        &__star {
            color: var(--msv-buybox-rating-star);
        }

        &__half-star {
            &::after {
                color: var(--msv-buybox-rating-star);
            }
        }
    }

    .msc-price {
        margin-bottom: 2px;
        display: block;
        width: 100%;
        text-align: right;
        padding-top: 10px;
    }

    .msc-price__actual {
        font-size: 28px;
        line-height: 37px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
            line-height: 32px;
        }

        .krannich-price {
            .inc {
                font-size: 20px;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 18px;
                }
            }
        }
    }

    .price-containernull {
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .msc-price__strikethrough {
        font-weight: 500;
        color: $msv-shiraz;
        font-size: 20px;
        line-height: 27px;

        margin-right: 18px;
    }

    .msc-price__pricerange {
        @include font-content-xl(var(--msv-font-weight-light));
        color: var(--msv-accent-brand-color);

        @media (max-width: $msv-breakpoint-m) {
            font-size: 18px;
        }
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
        padding-top: 10px;
    }

    &__dropdown {
        padding: 0 0 14px;
        display: flex;
        align-items: center;
        position: relative;

        &-quantity-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: var(--msv-buybox-title-font-color);
            margin: 0px 0 0 0;
            min-width: 55px;
            display: flex;
            flex-wrap: wrap;
        }

        .msc-alert-danger {
            border: 0;
            font-size: 12px;
            padding: 0px 6px;
            border-radius: 4px;
            position: absolute;
            bottom: 0;
            margin: 0;
            line-height: 14px;
            padding-left: 21px;
            background-position: unset;
        }
    }

    &__media-gallery {
        display: flex;
        flex-basis: 50%;
        min-width: 50%;
        flex-grow: 1;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        @media (min-width: $msv-breakpoint-l) {
            padding-right: 16px;
            padding-top: 16px;
            margin-bottom: 16px;
        }
    }

    &__content {
        padding-top: 30px;
        flex-basis: 50%;
        min-width: 50%;
        display: flex;
        flex-direction: column;

        @media (max-width: $msv-breakpoint-m) {
            min-width: 95%;
        }

        ._logo-container {
            text-align: left;
            margin-bottom: 16px;
            height: auto;
            height: 25px;

            img {
                max-width: 187px;
            }
        }

        .Request-Price-btn {
            display: inline-flex;
            margin: 0;
            width: 200px;
        }

        .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 16px;
            color: $msv-storm-dust;

            .load-text {
                border: 0;
                background: transparent;
                padding: 0;
                cursor: pointer;
                font-weight: 400;
                color: $msv-black;
                display: inline-block;
                padding-bottom: 1px;
                font-size: 16px;
                line-height: 21px;
                border-bottom: 1px solid $msv-smalt-blue;
                text-decoration: none;

                &:hover {
                    border-color: transparent;
                    text-decoration: none;
                }
            }
        }
    }

    .msc-dropdown__select {
        @include form-control();
        background: $msv-soft-peach;
        border-radius: 2px;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $msv-black;
        padding: 8px 16px;
        max-width: 210px;
    }

    .quantity {
        margin-top: 8px;

        .quantity-input {
            width: 82px;
            height: 50px;
            border-radius: 0;
            border: solid 1px $msv-black;
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            -moz-appearance: textfield;
            padding: 5px;
            vertical-align: bottom;
            font-size: 14px;
            font-weight: 600;

            @media (max-width: $msv-breakpoint-m) {
                width: 150px;
            }

            @media (max-width: $msv-breakpoint-l) {
                width: 156px;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            &:disabled {
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                background-color: $msv-white !important;
                /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                border: 1px solid $msv-gray-300 !important;
            }
        }

        .quantity__controls {
            background: var(--msv-quickView-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid $msv-light-grey;
            cursor: pointer;
            height: 50px;
            margin-right: 0px;
            min-width: auto;
            padding: 3px 5px;
            padding-left: 0;
            padding-right: 0;
            width: 50px;
            border-radius: 2px;
            font-size: 12px;

            &:hover,
            &:focus {
                background-color: var(--msv-accent-secondary-btn);
            }

            &:disabled {
                background: var(--msv-quickView-btn-bg);
                border: 1px solid $msv-gray-300;
                color: $msv-gray-300;
                cursor: default;
            }
        }

        .increment {
            @include add-icon($msv-Add, after);
            color: var(--msv-quickView-incdec-btn-font-color);

            &:after {
                font-size: 14px;
            }

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-quickView-incdec-btn-font-color);
            }
        }

        .decrement {
            @include add-icon($msv-Remove, after);
            color: var(--msv-quickView-incdec-btn-font-color);

            &::after {
                font-size: 14px;
            }

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-quickView-incdec-btn-font-color);
            }
        }
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-buybox-title-font-color);
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
    }

    .ms-buybox__key_in_price {
        margin-top: 20px;

        .ms-buybox__key_in_price-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
            display: block;
            margin-bottom: $buybox-label-margin-bottom;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            @include form-control();
            -moz-appearance: textfield;
            padding: $buybox-custom-amount-input-padding;
            text-align: left;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .ms-text-block {
        margin-top: 0;
        margin-bottom: 1rem;

        @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
    }

    .product-add-to-cart {
        margin-top: 12px;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .msc-add-to-cart {
            @include primary-btn();
            line-height: $msv-line-height-l;
            flex-grow: 1;
            max-width: 170px;
            padding: 7px 8px;

            &.add-bundle_cta {
                max-width: 200px;

                @media (max-width: $msv-breakpoint-m) {
                    max-width: 100%;
                }
            }

            @media (max-width: $msv-breakpoint-m) {
                max-width: unset;
            }
        }

        .ctx-disabled {
            .msc-add-to-cart {
                pointer-events: none;
                background-color: $msv-light-grey;
                border-color: $msv-light-grey;
                color: $msv-gunsmoke;
            }
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $buybox-product-status-padding-top;
        }

        .msc-add-to-cart-extra-actions {
            @include font-glyph-standard();
            @include secondary-button(var(--msv-buybox-secondary-btn-font-color));
            background-color: transparent;

            padding: 8px 0;
            position: relative;
            border: none;
            min-width: $buybox-add-to-cart-extra-actions-width;
            width: $buybox-add-to-cart-extra-actions-width;
            flex: 0 0 $buybox-add-to-cart-extra-actions-width;
            margin-left: $buybox-add-to-cart-extra-actions-margin;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: $msv-dark-junglel;
                background-color: transparent;
            }
        }

        .msc-add-to-wishlist {
            color: var(--msv-buybox-btn-wishlist-color);
        }
    }

    &__find-in-store {
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-light));
    }

    &__find-in-store-description {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
    }

    &__find-in-store-pickup-option {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
        @include add-icon($msv-Checkbox-Circle-Checked, before);

        &::before {
            margin-right: 10px;
            color: --msv-buybox-primary-btn-font-color;
        }
    }

    &__minmax_label_text {
        margin-top: 12px;
        color: $msv-shiraz;
        align-items: center;

        .msi-InfoCircle {
            @include add-icon($msv-InfoCircle);
            margin-right: $msv-icon-margin-right;
        }
    }

    &__find-in-store-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );

        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        height: unset;
    }

    &__shopsimilarlooks {
        margin-top: $buybox-box-top-margin;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }

    &__shop-similar-descriptions-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            @include image($msv-dialog-msc-empty_image-size);

            .msc-empty_image {
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button(
                var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color),
                var(--msv-buybox-primary-btn-border)
            );
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    .item-number-wrapper {
        @media (min-width: $msv-breakpoint-l) {
            min-height: 43px;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            flex-basis: 100%;
            min-width: 100%;
            flex-grow: 1;
            flex-wrap: nowrap;
        }

        .ms-media-gallery {
            padding-bottom: 0px;

            .msc-carousel {
                &__indicators {
                    bottom: -36px;
                }

                &__control {
                    &__prev,
                    &__next {
                        bottom: -40px;
                        color: var(--msv-buybox-carousel-flipper-color);
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }

                &__control__prev {
                    @include hover-focus {
                        .msc-carousel__control__prev__icon {
                            color: var(--msv-buybox-carousel-flipper-hover-color);
                        }
                    }
                }

                &__control__next {
                    @include hover-focus {
                        .msc-carousel__control__next__icon {
                            color: var(--msv-buybox-carousel-flipper-hover-color);
                        }
                    }
                }
            }
        }
    }

    //mobile vp
    .item-number-wrapper {
        margin-bottom: 8px;
    }

    .manufacturer-title,
    .item-number-title,
    .wp-price-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: $msv-black;
        display: inline-block;
        margin-right: 5px;
    }

    .manufacturer-name,
    .item-number,
    .wp-price {
        color: $msv-storm-dust;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .wp-price-container {
        display: flex;
        justify-content: flex-end;
        color: $msv-storm-dust;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;

        ._title,
        ._base-strike-wp-price {
            margin-right: 5px;
        }
    }

    .quantity-addtobag {
        margin-top: 20px;
        padding-top: 22px;
        border-top: 1px solid rgba($black, 0.2);
        margin-bottom: 20px;

        .action-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;

            .ms-buybox__quantity,
            .price-container {
                width: 100%;

                @media (min-width: $msv-breakpoint-l) {
                    width: 50%;
                }
            }

            .price-container {
                @media (max-width: $msv-breakpoint-l) {
                    order: -1;
                    margin-bottom: 16px;
                }
            }

            .ms-buybox__quantity {
                padding-bottom: 12px;

                @media (min-width: $msv-breakpoint-l) {
                    padding-bottom: 28px;
                }
            }

            .ms-buybox__minmax_label_text {
                @media (min-width: $msv-breakpoint-l) {
                    position: absolute;
                    bottom: 0;
                    margin: 0;
                }

                & + .ms-buybox__minmax_label_text {
                    @media (min-width: $msv-breakpoint-l) {
                        position: static;
                        white-space: nowrap;
                        margin-top: 4px;
                    }
                }
            }
        }

        .wp-price-wrapper {
            .manufacturer-title,
            .manufacturer-name {
                color: $msv-storm-dust;
            }
        }
    }

    .request-price {
        margin-top: 12px;
    }

    .ms-product-comparison-button__button {
        width: 170px;
        margin-top: 16px;
        padding: 12px;
    }

    .no-price-wrapper {
        justify-content: flex-end;
        padding-top: 10px;

        .label {
            font-size: 20px;
        }
    }

    .atp-pdp-wrapper {
        @media (min-width: $msv-breakpoint-l) {
            min-height: 140px;
        }
    }

}

.ks-buybox-wraper {
    overflow-x: hidden;

    .ms-breadcrumb {
        padding-bottom: 0;

        .ms-breadcrumb_item {
            .ms-breadcrumb_link {
                color: $msv-storm-dust;
                font-size: 12px;
                line-height: 16px;
                margin-right: 8px;
            }

            &.breadcrumb_item-current {
                .ms-breadcrumb_link {
                    font-weight: 700;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.buybox-description-popup-container {
    .msc-modal__dialog {
        .msc-modal__content {
            margin: auto auto;
            padding: 0;
        }

        .msc-modal__header {
            padding: 16px;
            border-bottom: 1px solid $msv-light-grey;
            margin-bottom: 16px;

            .msc-modal__title {
                padding: 0;
                border: 0;
                margin: 0;
                line-height: 30px;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 18px;
                }
            }
        }

        .msc-modal__body {
            padding: 0 16px 16px;
        }
    }
}

.ms-buybox__minmax_label_text {
    font-size: 14px;
}

.no-price-message {
    font-weight: 500;
    text-align: left;
    display: block;
}

// to disable visual search feature on edge browser

.edge-client {
    .ms-containerZoom__container {
        img {
            pointer-events: none;
        }
    }
}

// important notice and product long description and Promotiona text PDP UI

.promotional-text,
.product-long-description {
    ul {
        padding: 0;
        margin: 0 0 16px;
        list-style-type: none;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 16px;
        }

        li {
            margin-bottom: 4px;

            &::before {
                content: '';
                height: 10px;
                width: 10px;
                background: $msv-smalt-blue;
                display: inline-block;
                margin-right: 12px;
                border-radius: 50%;
            }
        }
    }

    ol {
        padding-left: 16px;
        margin: 0 0 16px;

        li {
            margin-bottom: 4px;
        }
    }
}

.product-long-description {
    position: relative;
    margin-top: 60px;
    padding-top: 60px;
    font-size: 14px;
    font-weight: 500;

    &::before {
        content: '';
        width: 100vw;
        background: $msv-soft-peach;
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    h2 {
        margin: 0;
        padding-bottom: 16px;
        font-size: 24px;
    }

    .discription-inner-section {
        padding: 25px;
        background-color: $msv-white;

        h1,
        h2 {
            padding: 0;
            margin: 10px 0;
            font-size: 20px;
        }

        h2 {
            font-size: 18px;
        }

        h3,
        h4 {
            font-size: 16px;
        }
    }

    a {
        color: $msv-smalt-blue;
        text-decoration: underline;
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }
    }

    .important-notice {
        background-color: $msv-supernova;
        padding: 16px;
        margin-bottom: 25px;
    }

    * {
        font-family: $msv-primary-font-family !important;
    }
}

.promotional-text {
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
    line-height: 1.2;

    h1,
    h2 {
        font-size: 18px;
    }

    h3,
    h4 {
        font-size: 16px;
    }

    * {
        font-family: $msv-primary-font-family !important;
    }
}
