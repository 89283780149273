//style presets
:root {
    --msv-breadcrumb-item-font-color: var(--msv-font-primary-color);
    --msv-breadcrumb-item-font-size: var(--msv-body-font-size-s);
}

.ms-breadcrumb {
    padding: 5px 0px;

    .ms-breadcrumb_list {
        color: var(--msv-breadcrumb-item-font-color);
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;

        .ms-breadcrumb-back-button {
            text-decoration: underline;
            cursor: pointer;
            float: left;
            margin-right: 10px;

            @include add-icon($msv-ChevronLeft, before);

            &::before {
                padding-right: 5px;
            }
        }
    }

    .ms-breadcrumb_item {
        color: var(--msv-breadcrumb-item-font-color);
        display: inline-flex;
        align-items: center;
        font-size: var(--msv-breadcrumb-item-font-size);
        position: relative;
        margin: 0;
        @include font-content-s();
        text-transform: uppercase;
        span {
            padding-right: 4px;
        }

        a[data-no-click='true'] {
            cursor: default;
            pointer-events: none;
        }

        .ms-breadcrumb_link.ms-breadcrumb-no-link-text {
            display: inline-block;
            vertical-align: middle;
        }

        .ms-breadcrumb_link {
            color: var(--msv-breadcrumb-item-font-color);
            font-size: 12px;
        }

        &.breadcrumb_item-current {
            color: $msv-storm-dust;
            font-weight: 400;
            .ms-breadcrumb_link {
                text-decoration: none;
                color: $msv-storm-dust;
            }
        }
    }
}

.mobile-breadcrum,
.account-breadcrumb {
    @media (max-width: $msv-breakpoint-l) {
        padding-left: 15px;
    }
    border-bottom: 1px solid $msv-light-grey;
    margin-bottom: 30px;
    &.container {
        max-width: 100%;
    }
    .ms-breadcrumb {
        max-width: 1270px;
        margin: 0 auto;
    }
}

.account-breadcrumb {
    margin-bottom: 50px;
    @media (max-width: $msv-breakpoint-l) {
        margin-bottom: 32px;
    }
}
.checkout-breadcrum-area {
    border-bottom: 1px solid $msv-light-grey;
    margin-bottom: 30px;
    position: fixed;
    top: 80px;
    width: 100%;
    background: $msv-white;
    z-index: 100;
    @media (max-width: 1200px) {
        padding-left: 15px;
    }
    @media (max-width: $msv-breakpoint-l) {
        position: static;
    }
}

.checkout-breadcrum {
    max-width: 1270px;
    margin: auto;
    // .ms-breadcrumb-no-link-text,
    // span {
    //     font-size: 12px !important;
    // }
}
