.faq {
    margin-top: 50px;

    .ms-accordion-header-section {
        margin: 0;
    }

    .ms-accordion-header-section__heading {
        font-size: 28px;
        margin: 0 0 32px;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
        }
    }

    .ms-accordion-item__drawer {
        margin-top: 0;
        border-color: $alto;

        .drawer__button {
            margin-top: 0;
            height: auto;
            padding: 16px 32px 16px 0;

            @media (max-width: $msv-breakpoint-m) {
                padding-right: 0;
            }
        }
    }

    .ms-accordion-item-content {
        border-top: 1px solid $alto;
        padding: 16px;

        .ms-text-block {
            margin-top: 0;
            line-height: 1.5;
            font-weight: 400;

            ul {
                padding: 0;
                margin: 0 0 16px;
                list-style-type: none;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 16px;
                }

                li {
                    margin-bottom: 4px;

                    &::before {
                        content: '';
                        height: 10px;
                        width: 10px;
                        background: $msv-smalt-blue;
                        display: inline-block;
                        margin-right: 12px;
                        border-radius: 50%;
                    }
                }
            }

            ol {
                padding-left: 16px;
                margin: 0 0 16px;

                li {
                    margin-bottom: 4px;
                }
            }

            h1,
            h2 {
                font-size: 20px;
            }

            h2 {
                font-size: 18px;
            }

            h3,
            h4 {
                font-size: 16px;
            }

            p {
                margin-bottom: 16px;
            }

            a {
                color: $msv-smalt-blue;
                text-decoration: underline;
                font-weight: 600;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .ms-accordion-header-section-toggle-button-container {
        display: none;
    }

    .ms-accordion-item-section {
        .ms-accordion-item {
            &:last-child {
                border-bottom: 1px solid $alto;
            }
        }
    }
}