$msv-sign-up-width: 40%;
$msv-sign-up-width-m: 80%;
$msv-sign-up-input-margin-top: 4px;
$msv-sign-up-account-item-margin-top: 20px;
$msv-sign-up-message-margin-top: 20px;
$msv-sign-up-message-padding: 20px;
$msv-sign-up-email-verification-buttons-margin-top: 12px;
$msv-sign-up-button-group-spacing: 32px;
$msv-sign-up-button-margin-top: 20px;

//style presets
:root {
    --msv-sign-up-font-size: var(--msv-body-font-size-m);
    --msv-sign-up-font-color: var(--msv-font-primary-color);

    // heading
    --msv-sign-up-heading-font-size: var(--msv-body-font-size-xl);
    --msv-sign-up-heading-font-color: var(--msv-font-primary-color);

    // alert
    --msv-sign-up-success-alert-bg: #{$msv-gray-300};
    --msv-sign-up-success-alert-font-color: var(--msv-font-primary-color);
    --msv-sign-up-success-alert-border: #{$msv-gray-900};
    --msv-sign-up-error-alert-bg: var(--msv-error-color);
    --msv-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-sign-up-error-alert-border: var(--msv-error-color);
    --msv-sign-up-error-color: var(--msv-error-color);

    // primary button
    --msv-sign-up-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-sign-up-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-sign-up-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-sign-up-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-sign-up-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-sign-up-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-sign-up {
    @include font-content(var(--msv-font-weight-normal), var(--msv-sign-up-font-size), $msv-line-height-m);
    color: var(--msv-sign-up-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: 100%;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-sign-up-heading-font-size), $msv-line-height-xl);
        color: var(--msv-sign-up-heading-font-color);
    }

    &__account-item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        margin-bottom: 5px;
        label {
            margin-bottom: 0px;
            text-transform: capitalize;
            color: $stone;
        }

        &-givenName {
            margin-top: 0px;
            display: none;
        }

        &-input {
            @include form-input-el();
            font-size: 14px;
            margin-top: $msv-sign-in-input-margin-top;
            width: 100%;
            border: 1px solid $msv-light-grey;
            height: 40px;
            background: transparent;
            border-radius: 0px;

            &:focus {
                outline: none;
            }
        }
    }
    /* Override inline style inject by AAD so we need important */
    &__account-item-emailVerificationCode[aria-hidden='false'],
    &__page-success[aria-hidden='false'],
    &__page-error[aria-hidden='false'] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    &__page-success {
        font-size: 14px;
        display: none;
        border-radius: 0px;
        background-color: $down;
        border: solid 1px $geyser;
        font-weight: normal;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='black'/%3E%3Cpath d='M8.40594 5.20586L6.10293 7.5159L5.19986 6.60592C5.02231 6.42838 4.76344 6.359 4.52098 6.42405C4.2784 6.48897 4.08891 6.67846 4.02399 6.92103C3.95895 7.1635 4.02833 7.42237 4.20587 7.59991L5.60593 8.99997C5.7373 9.13251 5.91625 9.20705 6.10291 9.20705C6.28948 9.20705 6.46844 9.13251 6.5999 8.99997L9.3999 6.19997C9.57744 6.02243 9.64682 5.76356 9.58178 5.52098C9.51685 5.2784 9.32736 5.08903 9.08479 5.02399C8.84233 4.95895 8.58348 5.02832 8.40594 5.20586Z' fill='white'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 15px 7px;
        padding-left: 45px;
        color: $msv-black;
        margin-top: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-size: 17px;
        margin-bottom: 10px;
    }

    &__page-error {
        @include msc-alert-error();
        display: none;
        background-position: 0px 1px;
        padding-left: 24px;
    }

    &__item-error {
        color: var(--msv-sign-up-error-color);
        font-size: 14px;
    }

    &__email-verification-button {
        @include advisery-btn();
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
    }
    &__create-button {
        @include advisery-btn();
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
    }

    &__cancel-button {
        @include primary-btn();
        color: $msv-black;
        margin-top: 5px;
        background: $msv-soft-peach;
        border-color: $msv-soft-peach;
        width: 100%;
    }
}

.ms-sign-up__item-error-text {
    margin: 0px;
    font-size: 12px;
}

.signup {
    margin-top: 15px;
}

.ms-sign-up__email-verification-buttons {
    .editButton {
        @include primary-btn();
    }
}

.ms-sign-up__item-error {
    @include msc-alert-error();
    order: 3;
    background-position-y: 2px;
}

.ms-sign-up__account-item-surname {
    display: none;
}
