$msv-breakpoint-xs: 0;
$msv-breakpoint-s: 0;
$msv-breakpoint-mobile: 575px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-ml: 1200px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

$msv-container-min-width-m: 769px;
