$msv-checkout-description-margin-left: 20px;
$msv-checkout-option-margin-bottom: 20px;
$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0;

//style presets
:root {
    --msv-checkout-delivery-options-font-size: var(--msv-body-font-size-m);
    --msv-checkout-delivery-options-font-color: #{msv-gray-900};
}

.ms-checkout-delivery-options {
    &__price {
        @include font-content(var(--msv-font-weight-normal), var(--msv-checkout-delivery-options-font-size), $msv-line-height-m);
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: auto;
        text-align: right;

        @media (max-width: $msv-breakpoint-m) {
            font-size: 14px;
            display: block;
            padding-left: 28px;
            text-align: left;
        }

        .msc-price__actual {
            @media (max-width: $msv-breakpoint-m) {
                font-size: 14px;
            }
        }
    }

    &__description {
        @include font-content(400, 14px, $msv-line-height-m);
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: 10px;
        text-transform: uppercase;
        min-width: 200px;

        @media (max-width: $msv-breakpoint-m) {
            margin-right: 20px;
        }
    }

    &__option {
        padding: 8px 0;
        display: flex;
        align-items: center;

        @media (max-width: $msv-breakpoint-m) {
            display: block;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &-selected {
            .ms-checkout-delivery-options__price {
                margin-left: 5px;
                text-align: right;

                @media (max-width: $msv-breakpoint-m) {
                    text-align: left;
                    padding-left: 0;
                    margin-left: 0;
                }

                .msc-price__actual {
                    font-size: 16px;
                }
            }

            .ms-checkout-delivery-options__description {
                margin-left: $msv-checkout-delivery-options-description-margin-left;
                font-weight: 400;
                font-size: 16px;
                min-width: 250px;
                display: inline-block;
            }
        }
    }

    &__input-radio {
        @include form-input-radio();
        height: 18px;
        width: 18px;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        &__description {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        &__price {
            flex: 0 0 16.66667%;
            flex-grow: 1;
            margin-left: 0;
        }
    }
}