$msv-checkout-margin-bottom: 28px;
$msv-checkout-margin-top: 28px;
$msv-checkout-margin-left-right: auto;
$msv-checkout-plain-container-padding-left: 0;
$msv-checkout-main-max-width: calc(100% - 400px);
$msv-checkout-side-width: 400px;
$msv-checkout-standard-margin-padding: 32px;
$msv-checkout-main-panel-padding-right: 40px;
$msv-checkout-guided-form-margin-bottom: 20px;
$msv-checkout-guided-card-padding-top: 20px;
$msv-checkout-guided-card-padding-bottom: 20px;
$msv-checkout-guided-card-padding-left: 32px;
$msv-checkout-guided-card-padding-right: 32px;
$msv-checkout-guided-card-border-top-width: 1px;
$msv-checkout-guided-card-body-margin-top: 12px;
$msv-checkout-guided-card-footer-margin-top: 20px;
$msv-checkout-place-order-button-margin: 10px;
$msv-checkout-side-control-margin-top: 20px;
$msv-checkout-side-control-margin-bottom: 20px;
$msv-checkout-side-control-first-margin-top: 0;
$msv-checkout-side-control-first-margin-auto: 15px auto;
$msv-checkout-side-control-place-order-button-width: 100%;
$msv-checkout-side-control-first-padding: 0 20px 20px 20px;
$msv-checkout-side-control-margin-side: 20px;
$msv-checkout-save-button-margin-left: 10px;
$msv-checkout-shipping-image-width: 80px;
$msv-checkout-shipping-margin-right: 12px;

// checkout__lines styling
$msv-checkout-line-items-padding: 32px;
$msv-checkout-line-image-size: 108px;
$msv-checkout-empty-image-width: $msv-checkout-line-image-size;
$msv-checkout-line-margin-top: 20px;
$msv-checkout-line-margin-left: 20px;
$msv-checkout-line-content-width: calc(100% - 128px);
$msv-checkout-line-header-margin-bottom: 20px;
$msv-checkout-line-heading-font-weight: 700;
$msv-checkout-line-edit-cart-font-weight: 400;
$msv-checkout-payment-instrument-error-max-width: 512px;
$msv-checkout-payment-instrument-error-padding: 8px;
$msv-checkout-payment-instrument-error-title-margin-bottom: 10px;
$msv-checkout-payment-instrument-error-margin-top: 2px;
$msv-checkout-payment-instrument-message-margin-buttom: 0;
$msv-checkout-guided-card-title-step-width: 20px;
$msv-checkout-guided-card-title-text-padding-left: 12px;
$msv-checkout-guided-card-title-font-weight: 700;
$msv-checkout-edit-btn-padding: 0;
$msv-checkout-edit-btn-margin-left: auto;
$msv-checkout-cart-line-quantity-margin-left: 0;
$msv-checkout-cart-line-quantity-label-margin-right: 5px;
$msv-checkout-cart-line-price-strikethrough-margin-right: 10px;
$msv-checkout-pick-up-at-store-margin-left: 128px;
$msv-checkout-pick-up-at-store-margin-top: 10px;
$msv-checkout-pick-up-at-store-margin-bottom: 20px;
$msv-checkout-email-delivery-margin-left: 128px;
$msv-checkout-email-delivery-margin-top: 10px;
$msv-checkout-email-delivery-margin-bottom: 20px;

// terms-and-conditions
$msv-checkout-terms-and-conditions-margin-bottom: 10px;
$msv-checkout-terms-and-conditions-padding-right: 32px;
$msv-checkout-terms-and-conditions-padding-left: 32px;

// Mobile styles
$msv-mobile-checkout-min-width: 320px;
$msv-mobile-checkout-max-width: 100%;
$msv-mobile-checkout-padding-zero: 0;
$msv-mobile-checkout-margin: 0;
$msv-mobile-checkout-max-button-width: 100%;
$msv-mobile-checkout-place-order-margin-left: 0;
$msv-mobile-checkout-place-order-margin-top: 10px;
$msv-mobile-checkout-keep-shopping-margin-top: 20px;

// Alert
$msv-checkout-error-message-padding: 18px;
$msv-checkout-error-message-margin-bottom: 20px;
$msv-checkout-error-message-width: 100%;
$msv-checkout-icon-margin-right: 8px;

:root {
    --msv-checkout-border: #{$msv-black};
    --msv-checkout-font-size: var(--msv-body-font-size-l);
    --msv-checkout-shoppingbag-border: #{$msv-gray-300};

    // background
    --msv-checkout-side-bg: var(--msv-bg-color);
    --msv-checkout-side-multiple-pickup-bg: #{$msv-white};

    //heading
    --msv-checkout-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-heading-font-size: var(--msv-body-font-size-xl);
    --msv-checkout-address-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-address-heading-font-size: var(--msv-body-font-size-s);

    // link
    --msv-checkout-btn-link-color: var(--msv-font-primary-color);

    // error
    --msv-checkout-error-message-bg: var(--msv-error-color);
    --msv-checkout-error-message-border: var(--msv-error-color);
    --msv-checkout-error-message-color: var(--msv-font-secondary-color);

    // primary button
    --msv-checkout-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-checkout-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-checkout-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-checkout-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-checkout-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-checkout-secondary-btn-border: var(--msv-accent-brand-color);

    // checkout line items
    --msv-checkout-line-heading-font-color: var(--msv-font-primary-color);
    --msv-checkout-line-heading-font-size: #{$msv-heading-text-size};
    --msv-checkout-line-title-font-color: var(--msv-font-primary-color);
    --msv-checkout-line-title-font-size: var(--msv-body-font-size-l);
    --msv-checkout-line-text-font-color: var(--msv-font-primary-color);
    --msv-checkout-line-text-font-size: var(--msv-body-font-size-m);

    // checkout shopping bag line item heading
    --msv-checkout-shopping-bag-line-heading-font-size: var(--msv-body-font-size-m);
    --msv-checkout-shopping-bag-line-subheading-font-size: var(--msv-body-font-size-s);
}

.ms-checkout {
    min-height: calc(100vh - 200px);
    padding-bottom: $msv-checkout-margin-bottom;
    margin-left: $msv-checkout-margin-left-right;
    margin-right: $msv-checkout-margin-left-right;
    font-size: var(--msv-checkout-font-size);
    line-height: $msv-line-height-l;
    padding-top: 60px;
    position: relative;
    z-index: 1;

    @media (max-width: $msv-breakpoint-m) {
        padding-top: 24px;
    }

    .msc-address-detail__item-address-detail_Phone {
        &::after {
            content: "" !important;
        }
    }

    .msc-address-detail__item-phonenumber {
        padding-right: 0px;

        &::after {
            content: "";
        }
    }

    .msc-address-form {
        padding-top: 15px;
    }

    .msc-address-form__error {
        .msc-address-form__error-message {
            color: $msv-shiraz;
            font-size: 14px;
            font-weight: normal;
            background-color: transparent;
            margin: 10px 0px;
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 2.77626e-05C5.14344 2.77626e-05 3.36304 0.737558 2.05026 2.05029C0.737531 3.36301 0 5.14363 0 7.00003C0 8.85642 0.737531 10.637 2.05026 11.9498C3.36299 13.2625 5.1436 14 7 14C8.8564 14 10.637 13.2625 11.9497 11.9498C13.2625 10.637 14 8.85642 14 7.00003C14 5.7713 13.6765 4.56416 13.0622 3.50001C12.4478 2.43587 11.5641 1.5522 10.5 0.937837C9.43584 0.323473 8.2287 0 6.99997 0L7 2.77626e-05ZM7 2.33337C7.20627 2.33337 7.40408 2.41529 7.55003 2.56113C7.69586 2.70707 7.77779 2.90488 7.77779 3.11116C7.77779 3.31744 7.69586 3.51524 7.55003 3.66119C7.40409 3.80702 7.20628 3.88894 7 3.88894C6.79372 3.88894 6.59591 3.80702 6.44997 3.66119C6.30414 3.51524 6.22221 3.31744 6.22221 3.11116C6.22221 2.90488 6.30414 2.70707 6.44997 2.56113C6.59591 2.41529 6.79372 2.33337 7 2.33337ZM9.02215 11.2001H4.97769L4.97779 9.64451H6.22227V6.53339H4.97779V4.97782H7.77781V9.64451H9.02217L9.02215 11.2001Z' fill='%23A60039'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            padding-left: 20px;
            background-position-y: 4px;
            text-align: left;
        }
    }

    &-customer-account__form-edit {
        padding-left: 20px;
        font-size: 16px;
    }

    .msc-order-summary__heading {
        font-size: 20px;
        margin: 0;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
    }

    .ms-checkout-shipping-address {
        .msc-address-detail__item-phone-label {
            position: unset !important;
        }

        .msc-address-detail__item-phone::before {
            content: "" !important;
        }

        .msc-address-detail__item {
            padding: 0px 5px 0px 0px;
            margin: 0px;

            span {
                &::after {
                    content: "";
                }
            }

            &::after {
                content: ",";
            }
        }

        .msc-address-detail {
            padding-bottom: 0;
            font-size: 16px;
            font-weight: normal;
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            padding-left: 30px;
            line-height: 24px;
        }
    }

    &__btn-place-order {
        &.is-busy {
            &::before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: $msv-checkout-icon-margin-right;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }

            cursor: progress;
        }

        @include advisery-btn();
        margin-left: $msv-checkout-place-order-button-margin;

        @media (min-width: $msv-breakpoint-l) {
            float: right;
        }
    }

    &__btn-keep-shopping {
        @include secondary-btn();
        font-size: 13px;
        display: none;
    }

    &__error-message {
        width: $msv-checkout-error-message-width;
        background-color: var(--msv-checkout-error-message-bg);
        border: 1px solid var(--msv-checkout-error-message-border);
        color: var(--msv-checkout-error-message-color);
        padding: $msv-checkout-error-message-padding;
        margin-bottom: $msv-checkout-error-message-margin-bottom;

        &::before {
            @include msv-icon();
            content: $msv-IncidentTriangle;
            margin-right: $msv-checkout-icon-margin-right;
        }
    }

    &-section-container {
        padding-left: $msv-checkout-plain-container-padding-left;

        &__item {
            .ms-text-block {
                margin: 0;

                p {
                    margin: 0;
                }
            }
        }

        &__item.hidden {
            display: none;
        }
    }

    &__title {
        color: $msv-black;
        font-weight: 700;
        font-size: 28px;
        align-items: center;
        text-transform: uppercase;
        // padding-bottom: $msv-checkout-guided-card-padding-bottom;
        margin: 20px 0 40px 0;

        @media (max-width: $msv-breakpoint-l) {
            margin-bottom: 20px;
        }
    }

    &__main {
        @media (max-width: $msv-breakpoint-l) {
            max-width: 100%;
            padding-right: 0px;
        }

        flex: auto;
        max-width: $msv-checkout-main-max-width;
        padding-right: $msv-checkout-main-panel-padding-right;

        .ms-checkout__terms-and-conditions {
            padding-right: $msv-checkout-terms-and-conditions-padding-right;
            padding-left: $msv-checkout-terms-and-conditions-padding-left;
            // margin-bottom: $msv-checkout-terms-and-conditions-margin-bottom;
        }
    }

    &__main-control {
        .form-field {
            padding-left: 0px;
            margin-left: 0px;
            padding-right: 0px;
            margin-right: 0px;
        }

        label {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            padding-right: 10px;
        }

        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;

        .ms-checkout__btn-place-order {
            margin-right: 0;
        }

        .msc-btn {
            width: 250px;
            margin: 20px 0 0 10px;
        }
    }

    &__side-control-first {
        padding: 0 30px;

        @media (max-width: $msv-breakpoint-m) {
            padding: 0px 20px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: $msv-white;
            z-index: 1000;
            margin: 0 !important;
            height: fit-content;
            min-height: fit-content;
            min-width: 80px;
            height: 79px;
            display: flex;
            align-items: center;
            border-top: 1px solid $msv-light-grey;
        }

        .ms-checkout__btn-place-order {
            width: $msv-checkout-side-control-place-order-button-width;

            @media (max-width: $msv-breakpoint-m) {
                margin-left: 0px;
            }
        }

        .ms-checkout__btn-keep-shopping {
            display: block;
        }
    }

    &__side-control-second {
        display: none;
    }

    &__side {
        border: solid 1px $msv-gray-300;
        box-shadow: 0px 0px 10px 0px rgba(151, 151, 151, 0.1);
        padding-bottom: 20px;
        position: sticky;
        top: 125px;
        z-index: 2;

        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: 20px;
        }

        .msc-order-summary__line-total {
            margin-top: 5px;
            bottom: unset;
        }
    }

    .custom_side_control_div {
        width: $msv-checkout-side-width;

        .ms-checkout__side-control-first {
            display: none;

            @media (max-width: $msv-breakpoint-m) {
                display: flex;
            }
        }

        @media (max-width: $msv-breakpoint-l) {
            max-width: -webkit-fill-available;
            width: 100%;
        }
    }

    &-payment-instrument__error {
        background-color: var(--msv-checkout-error-message-bg);
        border: 1px solid var(--msv-checkout-error-message-border);
        color: var(--msv-checkout-error-message-color);
        max-width: $msv-checkout-payment-instrument-error-max-width;
        padding: $msv-checkout-payment-instrument-error-padding;

        &-title {
            margin-bottom: $msv-checkout-payment-instrument-error-title-margin-bottom;
            margin-top: $msv-checkout-payment-instrument-error-margin-top;
            display: block;
        }

        &-message {
            display: block;
            margin-bottom: $msv-checkout-payment-instrument-message-margin-buttom;
            color: var(--msv-font-secondary-color);
        }
    }

    &__guided-card {
        border: solid 1px $msv-light-grey;
        padding: 15px 25px 15px 25px;
        color: $msv-black;

        &-header {
            display: flex;
        }

        &.hidden {
            display: none;
        }

        &.visted,
        &.active {
            border-top: $msv-checkout-guided-card-border-top-width solid $msv-gray-300;
            color: var(--msv-checkout-address-heading-font-color);
            text-decoration: none;
        }

        .msc-address-form {
            @media (max-width: $msv-breakpoint-m) {
                margin: 0;
            }
        }

        &-title {
            @include font-content-heading($msv-checkout-guided-card-title-font-weight);
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            display: flex;
            margin: 0;
        }

        &-title-step {
            width: $msv-checkout-guided-card-title-step-width;
        }

        &-body {
            // margin-top: $msv-checkout-guided-card-body-margin-top;

            justify-content: space-between;
            align-items: end;

            &.hidden {
                display: none;
            }
        }

        &-btn-cancel {
            @include secondary-btn();
            margin-left: 15px;
            min-width: 184px;

            @media (max-width: $msv-breakpoint-m) {
                margin-left: 0px;
            }
        }

        &-btn-save {
            @include advisery-btn();

            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 15px;
            }
        }

        &-btn-cancel,
        &-btn-save {
            &.is-submitting {
                &::before {
                    @include msv-icon();
                    content: $msv-Spinner;
                    margin-right: $msv-checkout-icon-margin-right;
                    -webkit-animation: spin 1s steps(8) infinite;
                    animation: spin 1s steps(8) infinite;
                }

                cursor: progress;
            }
        }

        &-btn-edit {
            @include font-content-m-underline(var(--msv-font-weight-heavy));
            @include button-link(var(--msv-checkout-btn-link-color));
            padding: $msv-checkout-edit-btn-padding;
            margin-left: $msv-checkout-edit-btn-margin-left;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            height: auto;
            color: $msv-shiraz;
            font-weight: 500;
        }

        &-footer {
            margin-top: 16px;
            display: flex;

            @media (max-width: $msv-breakpoint-m) {
                flex-wrap: wrap;
            }
        }

        // hide contact ootb info
        &:nth-child(4) {
            display: none;
        }
    }

    &__side-control {
        &-first,
        &-second {
            margin-bottom: $msv-checkout-side-control-margin-bottom;
        }
    }

    &__line-items {
        border: 1px solid $msv-gray-300;
        border-radius: 8px;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.05);
        border-bottom: solid 5px $msv-smalt-blue;
        line-height: $msv-line-height-s;
        padding: $msv-checkout-line-items-padding;
        font-size: 14px;
        margin-top: 20px;

        .ms-checkout__line-item {
            border-top: 1px solid $msv-gray-300;
        }

        &-edit-cart-link {
            padding: $msv-checkout-edit-btn-padding;
            margin-left: auto;
            font-size: 14px;
        }

        &-header {
            margin-bottom: $msv-checkout-line-header-margin-bottom;

            .ms-checkout__line-items-heading {
                color: var(--msv-checkout-line-heading-font-color);
                display: inline;
                font-size: var(--msv-checkout-line-heading-font-size);
                font-weight: 600;
                text-transform: uppercase;
                line-height: $msv-heading-line-height;
            }

            .ms-checkout__line-items-edit-cart-link {
                color: var(--msv-checkout-btn-link-color);
                font-weight: $msv-checkout-line-edit-cart-font-weight;
                float: right;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                text-decoration: underline;
            }
        }

        &-delivery-group {
            .ms-checkout__line-items-group-title {
                font-weight: $msv-checkout-line-heading-font-weight;
                color: var(--msv-checkout-line-text-font-color);
                padding-bottom: 8px;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-pickupicon {
                @include add-icon($msv-cube, before);
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 10px;
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-shipicon {
                @include add-icon($msv-DeliveryTruck, before);
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 10px;
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-emailicon {
                @include add-icon($msv-DeliveryTruck, before);
                margin-left: 20px;
                margin-right: 20px;
                margin-top: 10px;
                padding-top: 10px;
                position: absolute;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-heading {
                @include font-content(
                    var(--msv-font-weight-bold),
                    var(--msv-checkout-shopping-bag-line-heading-font-size),
                    $msv-line-height-m
                );
                color: var(--msv-checkout-line-text-font-color);
                margin-left: 50px;
                padding-top: 10px;
            }

            .ms-checkout__line-items-group-title-multiple-pickup-subheading {
                @include font-content(
                    var(--msv-font-weight-light),
                    var(--msv-checkout-shopping-bag-line-subheading-font-size),
                    $msv-line-height-s
                );
                color: var(--msv-checkout-line-text-font-color);
                margin-left: 50px;
            }

            .ms-checkout__pick-up-at-store {
                margin-left: $msv-checkout-pick-up-at-store-margin-left;
                margin-top: $msv-checkout-pick-up-at-store-margin-top;
                margin-bottom: $msv-checkout-pick-up-at-store-margin-bottom;

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .ms-checkout__email-delivery {
                margin-left: $msv-checkout-email-delivery-margin-left;
                margin-top: $msv-checkout-email-delivery-margin-top;
                margin-bottom: $msv-checkout-email-delivery-margin-bottom;

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }

            .msc-cart-line {
                margin-top: $msv-checkout-line-margin-top;

                &__product-image {
                    position: relative;
                    width: $msv-checkout-line-image-size;
                    height: $msv-checkout-line-image-size;
                    margin-right: unset;
                }

                &__quantity,
                &__product-variants {
                    font-weight: var(--msv-font-weight-normal);
                    color: var(--msv-checkout-line-text-font-color);
                    font-size: var(--msv-checkout-line-text-font-size);
                    line-height: $msv-line-height-m;
                }

                &__content {
                    display: block;
                    margin-left: $msv-checkout-line-margin-left;
                    width: $msv-checkout-line-content-width;

                    .msc-cart-line__product-title {
                        color: var(--msv-checkout-line-title-font-color);
                        font-weight: var(--msv-font-weight-bold);
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .msc-cart-line-item-product-discount {
                        color: var(--msv-success-color);

                        .msc-price__actual {
                            padding: 0;
                            font-size: 14px;
                        }
                    }

                    .msc-cart-line__quantity {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;

                        .quantity-label {
                            line-height: $msv-text-line-height;
                            margin-right: $msv-checkout-cart-line-quantity-label-margin-right;
                            font-size: 14px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }

                        .quantity-value {
                            display: inline;
                            text-align: left;
                            font-size: 14px;
                        }
                    }

                    .msc-cart-line__product-price {
                        margin-left: $msv-checkout-cart-line-quantity-margin-left;
                        text-align: left;
                        display: block;
                        font-size: 14px;

                        .msc-price__strikethrough {
                            color: var(--msv-checkout-line-text-font-color);
                            line-height: $msv-text-line-height;
                            display: inline;
                            margin-right: $msv-checkout-cart-line-price-strikethrough-margin-right;
                            font-size: 14px;
                        }

                        .msc-price__actual {
                            color: $msv-smalt-blue;
                            font-size: 14px;
                            line-height: $msv-text-line-height;
                            padding-top: 0;
                        }
                    }

                    .msc-cart-line__product-savings {
                        margin-left: 0;
                        text-align: left;
                        // hide extra price component
                        display: none;
                    }
                }
            }
        }
    }

    @include image($msv-checkout-empty-image-width);

    .multiple-pickup {
        border-top: 1px solid var(--msv-checkout-shoppingbag-border);
        margin-top: 10px;
        position: relative;
    }

    .multiple-pickup-enabled {
        background-color: var(--msv-checkout-side-multiple-pickup-bg);
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__main {
            min-width: $msv-mobile-checkout-min-width;
            max-width: $msv-mobile-checkout-max-width;
            padding-right: $msv-mobile-checkout-padding-zero;

            .ms-checkout__terms-and-conditions {
                display: none;
            }
        }

        &__side {
            width: $msv-mobile-checkout-max-width;
        }

        &__guided-card {
            &-btn-save {
                width: $msv-mobile-checkout-max-button-width;
            }

            &-btn-cancel {
                width: $msv-mobile-checkout-max-button-width;
            }
        }

        &__body {
            width: $msv-mobile-checkout-max-button-width;
        }

        margin-right: $msv-mobile-checkout-margin;
        margin-left: $msv-mobile-checkout-margin;

        &__main-control {
            .ms-checkout__btn-keep-shopping,
            .ms-checkout__btn-place-order {
                display: none;
            }
        }

        &__side-control-second {
            background: var(--msv-checkout-side-bg);
            display: none;
            justify-content: center;
            margin-top: 20px;
            padding: 20px 20px 30px 20px;
            border: 1px solid $msv-gray-300;
            border-radius: 8px;
            box-shadow: 0 10px 15px 0 rgba($msv-black, 0.05);
            border-bottom: solid 5px $msv-smalt-blue;

            .ms-checkout__btn-keep-shopping,
            .ms-checkout__btn-place-order {
                width: $msv-mobile-checkout-max-button-width;
            }

            .ms-checkout__btn-place-order {
                margin-left: $msv-mobile-checkout-place-order-margin-left;

                @media (max-width: $msv-breakpoint-m) {
                    margin-left: 0px;
                }
            }

            .ms-checkout__btn-keep-shopping {
                margin-top: $msv-mobile-checkout-keep-shopping-margin-top;
            }
        }
    }

    .msc-cart-line__product {
        width: unset;
        min-width: unset;
    }

    .msc-cart-line__other-charges {
        display: flex;
        flex-wrap: wrap;
    }

    .msc-cart-line__other-charges-label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .other-charge-value {
        .msc-price__actual {
            padding: 0;
            font-size: 14px;
        }
    }

    .msc-order-summary-wrapper {
        @media (max-width: $msv-breakpoint-l) {
            margin-bottom: 16px;
            padding: 20px 26px 0 26px;
        }
    }

    .msc-order-summary__line-net-price,
    .msc-order-summary__line-tax-amount,
    .msc-order-summary__line-shipping,
    .msc-order-summary__line-loyalty,
    .msc-order-summary__line-gift-card,
    .msc-order-summary__line-other-charges,
    .msc-order-summary__line-total-discounts {
        margin: 0;
    }

    .ms-checkout__main {
        .total_before_tax,
        .msc-order-summary__line-shipping {
            font-size: 16px;
        }

        .ms-checkout-payment-instrument__error {
            border: none;
            @include msc-alert-error();
            padding-top: 0;
            margin-top: 0;
            background-position: 0 5px;

            p {
                color: $msv-shiraz;
                font-size: 12px;
            }
        }

        .date-section {
            label {
                margin-bottom: 5px;

                @media (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
            }

            .form-field {
                @media (max-width: $msv-breakpoint-l) {
                    flex-direction: column;
                }
                label {
                    &:nth-child(2) {
                        color: $msv-storm-dust;
                        font-weight: normal;
                        margin-left: 8px;
                        text-transform: uppercase;
                        font-size: 14px;

                        @media (max-width: $msv-breakpoint-l) {
                            margin-left: 0px;
                        }
                    }
                }

                &:first-child {
                    @media (max-width: $msv-breakpoint-l) {
                        max-width: 40%;
                    }
                }
            }

            .dd {
                justify-content: right;

                input {
                    border-radius: 0px;
                }
            }

            .form-field {
                display: flex;
                align-items: center;

                @media (max-width: $msv-breakpoint-l) {
                    display: block;
                }

                label {
                    @media (min-width: $msv-breakpoint-l) {
                        margin-bottom: 0;
                    }
                }

                input {
                    margin-left: 3px;
                    width: 154px;
                    padding: 0 40px 0 16px;
                    border: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .react-datepicker-wrapper {
                border: 1px solid $msv-light-grey;
                position: relative;
                @include add-svg-icon($icon-calender, 24px, 24px);
                margin-left: 8px;

                &::before {
                    position: absolute;
                    z-index: 1;
                    right: 4px;
                    top: 6px;
                }

                .react-datepicker__calendar-icon {
                    display: none;
                }
            }

            .form-control {
                width: auto;
            }

            display: flex;
            width: auto;
            font-size: 14px;
            text-transform: uppercase;
        }

        .msc-address-select :last-child {
            border: none;
        }

        .active .ms-checkout__guided-card-body {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 1200px) {
                display: unset;
            }
        }

        .msc-price__actual {
            font-weight: 400;
        }
    }

    .ms-checkout__main-control {
        border: 1px solid $msv-gray-300;
        justify-content: left;
        padding: 15px 25px 15px 25px;
        display: block;

        div {
            width: 100%;
        }

        .row {
            margin: 0 -15px;
        }

        .form-control {
            border-radius: 0px;
        }

        .commission-customer-ref {
            padding-top: 16px;
            padding-bottom: 16px;
            margin-bottom: 0px;
            margin-left: 0;
            margin-right: 0;

            input {
                border-radius: 0px;
            }

            #cutomer-comment-id {
                border-radius: 0px;
                height: 100px;
            }

            .form-field {
                padding: 0;

                @media (max-width: $msv-breakpoint-m) {
                    margin-bottom: 16px;
                }

                @media (min-width: $msv-breakpoint-l) {
                    padding: 0;
                }

                &:first-child {
                    @media (min-width: $msv-breakpoint-l) {
                        padding-right: 20px;
                    }
                }

                label {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                input {
                    border-radius: 0px;
                    border-color: $msv-light-grey;
                    font-size: 14px;
                }
            }
        }
    }

    .date-section-container {
        border: solid 1px $msv-gray-300;
        padding: 15px 10px 15px 15px;
        margin-top: 20px;
        font-size: 16px;

        @media (max-width: $msv-breakpoint-m) {
            padding: 15px 5px 15px 5px;
            margin-bottom: 20px;
        }
    }

    .terms-and-conditions {
        margin-top: 20px;
    }

    .checkout-btn-desktop {
        margin-top: 20px;
        width: 100%;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }

        .ms-checkout__btn-place-order {
            width: 330px;
            margin-left: 0;

            @media (min-width: $msv-breakpoint-l) {
                float: unset;
            }
        }
    }

    .msc-address-select {
        position: relative;
    }

    .react-datepicker-popper {
        z-index: 3;
    }

    .form-control {
        &:focus {
            box-shadow: none;
            outline: 1px dashed;
            border-color: $msv-light-grey;
        }
    }
}

.extra-charges-message {
    font-size: 14px;
    color: $msv-black;
    background-color: $msv-soft-peach;
    line-height: normal;
    text-align: left;
    font-weight: normal;
    display: block;
    padding: 20px;
    margin: 20px 0 0 0;
}

.ms-checkout__side-control-first {
    .commission-customer-ref {
        display: none;
    }
}

.checkout-footer {
    @media (max-width: $msv-breakpoint-m) {
        padding-bottom: 80px;
    }

    .footer-social-icons {
        @media (max-width: $msv-breakpoint-m) {
            justify-content: center;
        }
    }

    .row {
        @media (max-width: $msv-breakpoint-m) {
            display: block;
            width: 100%;
        }

        .ms-content-block__image {
            img {
                @media (max-width: $msv-breakpoint-m) {
                    margin: auto;
                }
            }
        }
    }

    background-color: $msv-storm-dust;
    color: $msv-white;
    padding: 10px 50px;

    .ms-content-block {
        min-height: auto;
    }

    p {
        padding-top: 15px;
        text-transform: uppercase;
        font-size: 18px;
    }

    a {
        color: $msv-white;
    }

    h2 {
        font-weight: 700;
    }
}

.checkout-header {
    height: 80px;

    @media (max-width: $msv-breakpoint-l) {
        border-bottom: 0 !important;
    }

    .ms-header__container {
        position: fixed;
        top: 0;
        width: 100%;
        background: $msv-white;

        @media (max-width: $msv-breakpoint-l) {
            border-bottom: 1px solid $msv-light-grey;
            left: 0;
            padding: 0 16px;
        }

        .ms-header__logo {
            width: auto;

            @media screen and (max-width: $msv-breakpoint-l) {
                margin-left: 0;
            }

            img {
                width: 150px;

                @media screen and (max-width: $msv-breakpoint-l) {
                    margin-left: 0px !important;
                    width: 40px;
                }
            }
        }
    }

    .ms-header__nav-icon {
        display: none !important;
    }

    .ms-header__account-info,
    .tooltip-text,
    .register-now {
        display: none !important;
    }

    .msc-cart-icon::before {
        font-size: 24px !important;
    }

    .city-placeholder-wrapper {
        .city-placeholder {
            @media (max-width: $msv-breakpoint-l) {
                margin-bottom: 0;
            }
        }
    }
}

.msc-checkout-order-summary-wrapper {
    .msc-promo-code__apply-btn {
        display: inline-block;
    }

    .msc-promo-code-heading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
}

.commision-customer-container {
    display: flex;
    padding-bottom: 15px;
    width: 100%;

    @media (max-width: $msv-breakpoint-m) {
        display: block;
    }
}

.ms-checkout-delivery-options__list {
    min-width: 415px;

    @media (max-width: $msv-breakpoint-m) {
        min-width: auto;
    }
}

.ms-checkout-delivery-options__option-selected {
    padding: 0px 0 0 20px;
}

.info-field-lable {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 5px 0 5px 0;
}

.commission-customer-ref {
    .col-lg-6 {
        padding-right: 0px;
    }

    .col-lg-12 {
        padding-right: 0px;

        input {
            height: 100px;
        }
    }

    input {
        border-radius: 0px;
    }
}

.form-control {
    border-radius: 0px !important;
}

textarea.form-control {
    min-height: 100px;
}

.hide-editpayment-btn {
    .ms-checkout__guided-form {
        .ms-checkout__guided-card {
            &:nth-child(3) {
                .ms-checkout__guided-card-btn-edit {
                    display: none;
                }
            }
        }
    }
}
