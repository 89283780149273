@import '../../../00-settings/index.scss';
@import '../../../01-tools/index.scss';
@import '../../../02-generic/index.scss';

.msc-add-to-order-template-icon {
    &::after {
        content: '\f022';
        color: $msv-black;
    }
    @include add-icon($msv-file, after, $msv-outline-icon-weight);
    @include vfi();

    &__added {
        @include add-icon($msv-file, after);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
