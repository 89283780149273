$msv-aad-generic-input-margin-top: 4px;
$msv-aad-generic-link-margin-top: 8px;
$msv-aad-generic-message-padding: 20px;
$msv-aad-generic-message-margin-top: 20px;
$msv-aad-generic-container-width: 100%;
$msv-aad-generic-standard-button-width: 100%;
$msv-aad-generic-standard-input-width: 100%;
$msv-aad-generic-error-page-marging-bottom: 10px;
$msv-aad-generic-standard-marging-top: 20px;
$msv-aad-generic-standard-marging-bottom: 20px;
$msv-aad-generic-social-button-marging-bottom: 10px;
$msv-aad-generic-create-account-marging-left: 5px;
$msv-aad-generic-success-message-padding: 20px;
$msv-aad-generic-error-message-padding: 20px;

//style presets
:root {
    --msv-aad-generic-font-size: var(--msv-body-font-size-m);
    --msv-aad-generic-font-color: var(--msv-font-primary-color);
    --msv-aad-generic-link-size: var(--msv-body-font-size-s);
    --msv-aad-generic-link-color: #{$msv-gray-500};

    // heading
    --msv-aad-generic-heading-font-size: var(--msv-body-font-size-xl);
    --msv-aad-generic-heading-font-color: var(--msv-font-primary-color);

    //error
    --msv-aad-generic-error-alert-bg: var(--msv-error-color);
    --msv-aad-generic-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-aad-generic-error-alert-border: var(--msv-error-color);
    --msv-aad-generic-error-color: var(--msv-error-color);

    // primary button
    --msv-aad-generic-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-aad-generic-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-aad-generic-primary-btn-border: var(--msv-accent-brand-color);

    // alert
    --msv-aad-generic-success-alert-bg: #{$msv-gray-300};
    --msv-aad-generic-success-alert-font-color: var(--msv-font-primary-color);
    --msv-aad-generic-success-alert-border: #{$msv-gray-900};
}

.ms-aad-generic {
    @include font-content(var(--msv-font-weight-normal), var(--msv-aad-generic-font-size), $msv-line-height-m);
    color: var(--msv-aad-generic-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;

        #email_ver_input_label {
            margin-top: 10px;
        }
    }

    &__container {
        width: $msv-aad-generic-container-width;

        .heading,
        .intro {
            display: none;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0px;
    }

    .verify {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        #email_ver_but_edit {
            display: none !important;
        }
    }

    .divider {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .intro h2,
    .divider h2,
    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-aad-generic-heading-font-size), $msv-line-height-xl);
        color: var(--msv-aad-generic-heading-font-color);
        margin-bottom: $msv-aad-generic-standard-marging-bottom;
    }

    .attrEntry {
        margin-bottom: $msv-aad-generic-standard-marging-bottom;
        display: flex;
        flex-wrap: wrap;

        .error {
            color: var(--msv-aad-generic-error-color);
            order: 2;
            width: 100%;
            @include msc-alert-error();
            padding: 0px;
            background-position: 0px 1px;
            padding-left: 25px;
            background-size: 16px;
            margin: 0;
        }

        #email_info,
        #emailVerificationControl_success_message {
            color: $msv-black;
            font-size: 14px;
            border: none;
            background-color: transparent;
            font-weight: normal;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='black'/%3E%3Cpath d='M8.40594 5.20586L6.10293 7.5159L5.19986 6.60592C5.02231 6.42838 4.76344 6.359 4.52098 6.42405C4.2784 6.48897 4.08891 6.67846 4.02399 6.92103C3.95895 7.1635 4.02833 7.42237 4.20587 7.59991L5.60593 8.99997C5.7373 9.13251 5.91625 9.20705 6.10291 9.20705C6.28948 9.20705 6.46844 9.13251 6.5999 8.99997L9.3999 6.19997C9.57744 6.02243 9.64682 5.76356 9.58178 5.52098C9.51685 5.2784 9.32736 5.08903 9.08479 5.02399C8.84233 4.95895 8.58348 5.02832 8.40594 5.20586Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 0px 12px;
            padding: 10px 10px 5px 25px;
            line-height: normal;
            background-size: 16px;
        }

        label {
            width: 100%;
        }

        input {
            @include form-input-el();
            margin-top: $msv-aad-generic-input-margin-top;
            width: $msv-aad-generic-standard-input-width;
            font-size: 16px;
            order: 1;
            width: 100%;
            border: 1px solid $stone;
            height: 43px;
            background: transparent;
            margin: 0;

            &:disabled {
                border: 1px solid $msv-light-grey;
            }
        }

        #emailVerificationControl {
            width: 100%;
        }
    }

    .entry-item {
        margin-bottom: $msv-aad-generic-standard-marging-bottom;
        display: flex;
        flex-wrap: wrap;

        .verificationInfoText {
            color: $msv-black;
            font-size: 14px;
            border-radius: 0px;
            background-color: $down;
            border: solid 1px $geyser;
            font-weight: normal;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='black'/%3E%3Cpath d='M8.40594 5.20586L6.10293 7.5159L5.19986 6.60592C5.02231 6.42838 4.76344 6.359 4.52098 6.42405C4.2784 6.48897 4.08891 6.67846 4.02399 6.92103C3.95895 7.1635 4.02833 7.42237 4.20587 7.59991L5.60593 8.99997C5.7373 9.13251 5.91625 9.20705 6.10291 9.20705C6.28948 9.20705 6.46844 9.13251 6.5999 8.99997L9.3999 6.19997C9.57744 6.02243 9.64682 5.76356 9.58178 5.52098C9.51685 5.2784 9.32736 5.08903 9.08479 5.02399C8.84233 4.95895 8.58348 5.02832 8.40594 5.20586Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 15px 14px;
            padding: 0.75rem 1.25rem;
            padding-left: 45px;
            margin-bottom: 15px;
        }

        input {
            @include form-input-el();
            margin-top: $msv-aad-generic-input-margin-top;
            width: $msv-aad-generic-standard-input-width;
            border: 1px solid $msv-gray-500;
            font-size: 16px;
            order: 1;
            width: 100%;
            border: 1px solid $msv-light-grey;
            height: 43px;
            background: transparent;
            margin: 0;
        }
    }

    .password-label label {
        &::after {
            content: '\A';
            white-space: pre;
        }
    }

    #forgotPassword {
        display: inline-block;
        color: var(--msv-aad-generic-link-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-aad-generic-link-size), $msv-line-height-s);
        margin-top: $msv-aad-generic-link-margin-top;
        text-decoration: underline;
    }

    #createAccount {
        display: inline-block;
        color: var(--msv-aad-generic-link-color);

        @include font-content(var(--msv-font-weight-normal), var($msv-font-size-m), $msv-line-height-m);
        margin-top: $msv-aad-generic-link-margin-top;
        text-decoration: underline;
        margin-left: $msv-aad-generic-create-account-marging-left;
    }

    .accountButton {
        @include primary-button($social-bg-color, $msv-white, $social-hover-color);
        @include vfi();
        width: $msv-aad-generic-standard-button-width;
        margin-bottom: $msv-aad-generic-social-button-marging-bottom;

        &:not(:disabled) {
            cursor: pointer;
        }

        &:hover,
        &:focus {
            background-color: $social-hover-color;
            border-color: $social-hover-color;
        }
    }

    .sendButton {
        @include advisery-btn();
        order: 1;
        width: 100%;
        margin-top: 15px;
    }

    #continue {
        @include advisery-btn();

        width: 100%;
    }

    .verifyButton,
    .verifyCode {
        @include advisery-btn();
        width: 100%;
        margin: 15px 0px 0px 0px;
        order: -1;
    }

    .verifyButton {
        order: 2
    }

    .editButton {
        @include primary-btn();
        background-color: $msv-black;
        color: $msv-white;
        width: 100%;
    }

    .defaultButton,
    .sendCode,
    .verifyCode,
    .sendNewCode,
    .changeClaims,
    #next {
        @include advisery-btn();
        width: 100%;
        margin: 15px 0px 0px 0px;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .sendNewCode {
        order: -2;
        margin-top: 0;
        padding: 0;
        height: auto;
    }

    #cancel {
        @include vfi();
        margin-bottom: $msv-aad-generic-standard-marging-bottom;
        width: $msv-aad-generic-standard-button-width;
        @include secondary-btn();
        margin-top: 15px;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .error {
        &.itemLevel {
            color: var(--msv-aad-generic-error-color);
            order: 2;
            width: 100%;
            @include msc-alert-error();
            padding: 0px;
            background-position: 0px 4px;
            padding-left: 25px;
            background-size: 16px;
            margin: 0;

            &.show {
                margin-top: 5px
            }

            &[aria-hidden="true"] {
                margin: 0;
            }
        }

        &.pageLevel {
            @include validation-error(
                var(--msv-aad-generic-error-alert-bg),
                var(--msv-aad-generic-error-alert-border),
                var(--msv-aad-generic-error-alert-font-color)
            );
            display: none;
            margin-top: $msv-aad-generic-message-margin-top;
            margin-bottom: $msv-aad-generic-error-page-marging-bottom;
            padding: $msv-aad-generic-message-padding;

            @include msc-alert-error();
            padding: 0px;
            background-position: 0px 1px;
            padding-left: 25px;
            background-size: 16px;
            border: none;

            margin-left: 0px;
        }
    }

    .intro p,
    .helpLink {
        display: none;
    }

    /* Override inline style inject by AAD so we need important */
    #emailVerificationControl_success_message[aria-hidden='false'],
    #emailVerificationControl_error_message[aria-hidden='false'] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    #emailVerificationControl_error_message {
        color: $msv-shiraz;
        margin-bottom: $msv-aad-generic-standard-marging-bottom;
    }
}

#email_ver_but_resend,
#emailVerificationControl_but_send_new_code {
    background-color: transparent;
    border: none;
    text-align: right;
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
    color: $msv-smalt-blue;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;

    &:hover,
    &:focus {
        color: $msv-black;
        outline: none;
        text-decoration: none;
    }
}
